<template>
    <div>
      <b-overlay :show="showOverlayUserProfile" rounded="sm">
        <form class="form">
          <div class="form-group">
            <a href="javascript:void(0);" v-b-modal.modal-upload-picture>
              <img v-bind:src="user.picture" alt="" class="img-thumbnail" style="max-width: 150px;max-height: 150px" />
            </a>
            <upload-picture :picture="user.picture" :target="'user'" :targetId="user.id" :on-success="loadUser"></upload-picture>
          </div>
          <div class="form-group">
            <label for="InputUserFirstName">{{ $t('first_name') }}</label>
            <input type="text" class="form-control" v-model="user.first_name" id="InputUserFirstName"/>
          </div>
          <div class="form-group">
            <label for="InputUserLastName">{{ $t('last_name') }}</label>
            <input type="text" class="form-control" v-model="user.last_name" id="InputUserLastName"/>
          </div>
          <div class="form-group">
            <label for="InputUserDob">{{ $t('birthday_date') }}</label>
            <input type="date" class="form-control" v-model="user.dob" id="InputUserDob"/>
          </div>
          <div class="form-group">
            <label for="InputUserMobile">{{ $t('mobile') }}</label>
            <input type="text" class="form-control" v-model="user.mobile" id="InputUserMobile"/>
          </div>
          <div class="form-group">
            <label for="InputUserEmail">{{ $t('email') }}</label>
            <input type="text" class="form-control" v-model="user.email" id="InputUserEmail"/>
          </div>
          <div class="form-group">
            <label for="InputUserColor">{{ $t('color') }}</label>
            <input type="color" v-model="user.color" class="form-control" id="InputUserColor"/>
          </div>
          <div class="card-footer">
            <button type="button" v-on:click="updateUser" v-bind:class="updateButtonClass">
              <i class="fa fa-spinner fa-pulse fa-fw" v-if="updateSpinner"></i>
              <i class="fa fa-save" v-if="!updateSpinner"></i>
              {{ $t('save') }}
            </button>
          </div>
        </form>
      </b-overlay>
    </div>
</template>

<script>
  import UploadPicture from '../../Upload/UploadPicture';
  import {VBModal, BModal, BOverlay} from 'bootstrap-vue'
  export default {
    name: "user-profile",
    components: {
        UploadPicture,
        'b-overlay': BOverlay,
        'b-modal': BModal
    },
    directives: {
      'b-modal': VBModal
    },
    props: {
        userId: {
            type: String,
            required: false,
        },
    },
    data () {
      return {
        updateSpinner : false,
        password: {},
        user: {},
        modules: [],
        showOverlayUserProfile: false
      }
    },
    mounted (){
        this.interval = setInterval(function() {
            if (this.userId>0) {
                this.loadUser();
                clearInterval(this.interval);
            }
        }.bind(this), 1000);
    },
    methods: {
      loadUser : function (userId) {
          this.showOverlayUserProfile = true;
          this.$http.get('user/' + userId).then((response) => {
            this.showOverlayUserProfile = false;
            if (response.ok === true) {
                this.user = response.data;
            }
          }).catch((response)=> {
              this.showOverlayUserProfile = false;
          });
      },
      getUser: function () {
          return this.user;
      },
      updateUser : function () {
        this.updateSpinner = true;
        this.showOverlayUserProfile = true;
        this.$http.put('user/'+this.user.id, this.user).then((response) => {
          this.showOverlayUserProfile = false;
          if (response.ok === true) {

          }
          this.updateSpinner = false;
        }).catch((response)=> {
            this.showOverlayUserProfile = false;
        });
      },
    },
    computed: {
      updateButtonClass : function() {
        return 'btn btn-default pull-right';
      },
    }
  }
</script>

