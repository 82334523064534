<template>
  <div>
    <b-overlay :show="showOverlayCustomerContact" rounded="sm">
      <form method="post" v-on:submit.prevent="save">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label>Identifiant:</label>
              <input type="text" class="form-control" v-model="customer.login" placeholder="Identifiant" style="border-color:orange;box-shadow: 0 0 5px rgba(207, 220, 0, 0.4);">
            </div>
            <div class="form-group">
              <label>{{$t('email')}}:</label>
              <input type="text" class="form-control" v-model="customer.email" v-bind:placeholder="$t('email')">
            </div>
            <div class="form-group">
              <label>{{$t('email')}} 2:</label>
              <input type="text" class="form-control" v-model="customer.email_second" v-bind:placeholder="$t('email')">
            </div>
            <div class="form-group">
              <label>{{$t('mobile')}}:</label>
              <input type="text" class="form-control" v-model="customer.mobile" v-bind:placeholder="$t('mobile')">
            </div>
            <div class="form-group">
              <label>{{$t('phone')}}:</label>
              <input type="text" class="form-control" v-model="customer.phone" v-bind:placeholder="$t('phone')">
            </div>
            <div class="form-group">
              <label>{{$t('fax')}}:</label>
              <input type="text" class="form-control" v-model="customer.fax" v-bind:placeholder="$t('fax')">
            </div>
            <div class="form-group">
              <b-form-checkbox switch :checked="customer.alert.birthday_sms" v-model="customer.alert.birthday_sms" :value="true">
                SMS d'anniversaire
                <b-form-invalid-feedback :state="validBirthdaySms">Mobile phone is missing</b-form-invalid-feedback>
              </b-form-checkbox>
            </div>
            <div class="form-group">
              <b-form-checkbox switch :checked="customer.alert.birthday_email" v-model="customer.alert.birthday_email" :value="true">
                E-mail d'anniversaire
                <b-form-invalid-feedback :state="validBirthdayEmail">E-mail is missing</b-form-invalid-feedback>
              </b-form-checkbox>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-success">
              <i class="fa fa-save"></i>
              {{$t('save')}}
            </button>
          </div>
        </div>
      </form>
    </b-overlay>
  </div>
</template>

<script>
    import {BOverlay, BFormCheckbox, BFormInvalidFeedback} from 'bootstrap-vue'
    import store from '../../../store'

    export default {
        name: "customer-table-contact",
        components: {
            BOverlay,
            BFormCheckbox,
            BFormInvalidFeedback
        },
        data() {
            return {
                showOverlayCustomerContact: false,
            }
        },
        props: {
            customer: {
                type: Object,
                required: false,
            },
        },
        computed: {
            validBirthdaySms() {
                if (typeof this.customer.mobile === "undefined" || null === this.customer.mobile) {
                    return false;
                }
                return this.customer.mobile.length > 0
            },
            validBirthdayEmail() {
                if (typeof this.customer.email === "undefined" || null === this.customer.email) {
                    return false;
                }
                return this.customer.email.length > 0
            }
        },
        methods: {
            save: function () {
                var data = {
                    login: this.customer.login,
                    email: this.customer.email,
                    email_second: this.customer.email_second,
                    mobile: this.customer.mobile,
                    phone: this.customer.phone,
                    fax: this.customer.fax,
                    alert: this.customer.alert,
                };
                this.showOverlayCustomerContact = true;
                this.$http.put('customer/'+this.customer.id, data).then((response) => {
                    this.showOverlayCustomerContact = false;
                    if (response.ok === true) {
                        this.$parent.customer = response.data;
                    }
                }).catch((response) => {
                    this.showOverlayCustomerContact = false;
                });
            },
            loadStatus : function() {
                this.$http.get('factory/'+store.state.factoryConnected.id+'/status?target=customer').then((response) => {
                    if (response.ok === true) {
                        this.status = response.data;
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>
