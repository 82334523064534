<template>
  <div class="card">
    <table class="table table-condensed table-hover table-bordered">
      <thead class="align-center">
        <tr style="font-weight: bold;text-align: center;">
          <th>#</th>
          <th>{{$t('owner')}}</th>
          <th>{{$t('title')}}</th>
          <th>{{$t('start')}}</th>
          <th>{{$t('end')}}</th>
          <th>{{$t('create')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="this.events.length===0"><td colspan="10">{{$t('no_event')}}</td></tr>
        <tr v-for="event in events">
          <td>
            <router-link :to="{name: 'event', params: {'eventId': event.id}}" class="btn btn-xs btn-info">
              <i class="fa fa-edit"></i>
            </router-link>
          </td>
          <td>{{event.owner ? event.owner.name : ''}}</td>
          <td>{{event.name}}</td>
          <td>{{event.start}}</td>
          <td>{{event.end}}</td>
          <td><timeago :datetime="event.dates.create">{{event.dates.create}}</timeago></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
    import timeago from 'timeago.js';
    export default {
      name: "customer-table-event",
      data () {
        return {
          events: [],
          showOverlayEvents: false
        }
      },
      computed: {
        currentIdCustomer : function() {
          return this.$route.params.customerId;
        },
      },
      mounted () {
        this.loadEvents();
      },
      methods: {
        loadEvents : function () {
          this.showOverlayEvents = true;
          this.$http.get('customer/'+this.currentIdCustomer+'/events').then((response) => {
            this.events = response.data;
            this.showOverlayEvents = false;
          });
        },
      }
    }
</script>

<style scoped>

</style>
