<template>
    <div>
      <div class="row">
        <div class="col-md-12">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link :to="{name: 'root'}"><i class="fa fa-home"></i></router-link></li>
            <li v-if="!user.id" class="breadcrumb-item active">{{ $t('users') }}</li>
            <li v-if="user.id" class="breadcrumb-item"><router-link :to="{name: 'users'}">{{ $t('users') }}</router-link></li>
            <li v-if="user.id" class="breadcrumb-item active">{{user.name}}</li>
          </ol>
        </div>
        <div class="col-md-3">
          <div class="list-group">
            <li class="list-group-item text-muted"><b>{{ $t('users_list') }}</b></li>
            <a v-if="users.length===0" href="javascript:void(0)" class="list-group-item disabled">{{$t('no_user')}}</a>
            <router-link v-for="user in users" v-bind:key="user.id" :to="{name: 'user', params: {userId: user.user.id}}" :class="itemClass(user.id)">
              {{user.user.name}}
            </router-link>
          </div>
        </div>
        <div class="col-md-9">
          <div>
            <b-tabs pills>
              <b-tab :title="$t('profile')" active>
                <br />
                <b-card><user-profile :userId="userId" ref="profile"></user-profile></b-card>
              </b-tab>
              <b-tab :title="$t('factory')" active>
                <br />
                <b-card><user-factory :userId="userId" :factoryId="factoryId" ref="factory"></user-factory></b-card>
              </b-tab>
              <b-tab :title="$t('module')">
                <br />
                <b-card no-body><user-module :userId="userId" ref="module"></user-module></b-card>
              </b-tab>
              <b-tab :title="$t('photo')">
                <br />
                <b-card><user-photo :userId="userId" ref="photo"></user-photo></b-card>
              </b-tab>
              <b-tab v-if="user.its_me === true" :title="$t('password')">
                <br />
                <b-card><user-password :userId="userId" ref="password"></user-password></b-card>
              </b-tab>
              <b-tab v-if="user.its_me === true" :title="$t('connections')">
                <br />
                <b-card no-body><user-session :load="loadSession" :userId="userId" ref="session"></user-session></b-card>
              </b-tab>
              <b-tab v-if="user.its_me === true" :title="$t('two_factor_authentication')">
                <br />
                <b-card><user-two-factor-authentication :user="userId" :twoFactor="user.two_factor" ref="two_factor_authentication"></user-two-factor-authentication></b-card>
              </b-tab>
              <b-tab :title="$t('events')">
                <br />
                <b-card no-body><user-event :userId="userId" ref="event"></user-event></b-card>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import {BTabs, BTab, BCard} from 'bootstrap-vue'
  import UserProfile from './Tab/UserProfile'
  import UserModule from './Tab/UserModule'
  import UserPhoto from './Tab/UserPhoto'
  import UserPassword from './Tab/UserPassword'
  import UserSession from './Tab/UserSession'
  import UserEvent from './Tab/UserEvent'
  import UserFactory from "./Tab/UserFactory";
  import UserTwoFactorAuthentication from "./Tab/UserTwoFactorAuthentication";
  import store from '../../store'
  export default {
    components: {
        'b-tabs' : BTabs,
        'b-tab' : BTab,
        'b-card' : BCard,
        UserProfile,
        UserModule,
        UserPhoto,
        UserPassword,
        UserSession,
        UserEvent,
        UserFactory,
        UserTwoFactorAuthentication,
    },
    watch: {
        $route() {
            this.loadUser(this.$route.params.userId, true);
        }
    },
    name: "user",
    data () {
      return {
        users: [],
        me: {
            id: null,
            its_me: false,
            factory: {
                id: null
            },
            two_factor: null
        },
        user: {
            id: null,
            its_me: false,
            factory: {
                id: null
            },
            two_factor: null
        },
        loadSession: false,
      }
    },
    mounted (){
      this.loadUsers();
      this.loadUser(this.$route.params.userId, true);
    },
    methods: {
      itemClass: function (userId) {
          if (String(this.$route.params.userId) === String(userId)) {
              return 'list-group-item active';
          }
          return 'list-group-item';
      },
      loadUsers : function () {
          this.$http.get('factory/'+store.state.factoryConnected.id+'/users?page=1').then((response) => {
          if (response.ok === true) {
            this.users = response.data.items;
          }
        });
      },
      loadUser : function (userId, cascade) {
          this.$http.get('user/' + userId).then((response) => {
              if (response.ok === true) {
                  this.user = response.data;
                  if (cascade === true) {
                      this.loadUserCascade(userId);
                  }
              }
          }).catch((response)=> {
          });
      },
      loadUserCascade : function (userId) {
          let ref;
          let keys = Object.keys(this.$refs);
          for (let i = 0; i < keys.length; ++i) {
              ref = this.$refs[keys[i]];
              if (typeof ref.loadUser === 'function') {
                  this.user.id = userId;
                  ref.loadUser(userId);
              }
          }
      },
      getUser: function () {
          return this.$refs['profile'].getUser();
      }
    },
    computed: {
      userId: function () {
          try {
              return this.user.id;
          } catch (e) {
              return null;
          }
      },
      factoryId: function () {
          try {
              return this.me.factory.id;
          } catch (e) {
              return null;
          }
      }
    }
  }
</script>

