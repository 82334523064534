<template>
  <b-overlay :show="showOverlayEvents" rounded="sm">
    <div class="table-responsive">
      <table class="table table-condensed table-hover table-bordered">
        <thead class="align-center">
          <tr style="font-weight: bold;text-align: center;">
            <th>#</th>
            <th>{{$t('customer')}}</th>
            <th>{{$t('title')}}</th>
            <th>{{$t('start')}}</th>
            <th>{{$t('end')}}</th>
            <th>{{$t('create')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="this.events.length===0"><td colspan="10">{{$t('no_event')}}</td></tr>
          <tr v-for="event in events">
            <td>
              <router-link :to="{name: 'event', params: {'eventId': event.id}}" class="btn btn-xs btn-info">
                <i class="fa fa-edit"></i>
              </router-link>
              <button class="btn btn-danger btn-xs" v-on:click="deleteEvent(event.id)"><i class="fa fa-trash"></i></button>
            </td>
            <td>{{event.customer ? event.customer.name : ''}}</td>
            <td>{{event.name}}</td>
            <td>{{event.start}}</td>
            <td>{{event.end}}</td>
            <td><timeago :datetime="event.dates.create">{{event.dates.create}}</timeago></td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-overlay>
</template>

<script>
    import timeago from 'timeago.js';
    import {BOverlay} from 'bootstrap-vue'
    export default {
      name: "user-event",
      data () {
        return {
          events: [],
          interval: null,
          showOverlayEvents: false
        }
      },
      props: {
          userId: {
              type: String,
              required: false,
          },
      },
      components: {
          'b-overlay': BOverlay
      },
      computed: {
        currentIdCustomer : function() {
          return this.$route.params.customerId;
        },
      },
      mounted () {
        this.interval = setInterval(function() {
            if (this.userId>0) {
                this.loadUser();
                clearInterval(this.interval);
            }
        }.bind(this), 1000);
      },
      methods: {
        loadUser : function (userId) {
            this.loadEvents(userId);
        },
        loadEvents : function (userId) {
            if (userId !== null) {
                this.showOverlayEvents = true;
                this.$http.get('user/' + userId + '/events').then((response) => {
                    this.events = response.data;
                    this.showOverlayEvents = false;
                });
            }
        },
        deleteEvent(eventId) {
            if (eventId) {
                this.showOverlayEvents = true;
                this.$http.delete('event/' + eventId).then((response) => {
                    this.showOverlayEvents = false;
                    this.loadEvents(this.$attrs.user);
                }).catch((response) => {
                    this.showOverlayEvents = false;
                });
            }
        },
      }
    }
</script>

<style scoped>

</style>
