<template>
    <div class="row">
      <div class="col-md-3">
        <div class="card card-stats card-warning">
          <div class="card-body ">
            <div class="row">
              <div class="col-5">
                <div class="icon-big text-center">
                  <i class="la la-users"></i>
                </div>
              </div>
              <div class="col-7 d-flex align-items-center">
                <div class="numbers">
                  <p class="card-category">{{$t('customers')}}</p>
                  <h4 class="card-title">{{ counts.customer }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-stats card-danger">
          <div class="card-body">
            <div class="row">
              <div class="col-5">
                <div class="icon-big text-center">
                  <i class="la la-newspaper-o"></i>
                </div>
              </div>
              <div class="col-7 d-flex align-items-center">
                <div class="numbers">
                  <p class="card-category">{{$t('invoices')}}</p>
                  <h4 class="card-title">{{ counts.invoice }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-stats card-success">
          <div class="card-body ">
            <div class="row">
              <div class="col-5">
                <div class="icon-big text-center">
                  <i class="la la-bar-chart"></i>
                </div>
              </div>
              <div class="col-7 d-flex align-items-center">
                <div class="numbers">
                  <p class="card-category">{{$t('sales')}}</p>
                  <h4 class="card-title">{{ counts.sale }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-stats card-primary">
          <div class="card-body ">
            <div class="row">
              <div class="col-5">
                <div class="icon-big text-center">
                  <i class="la la-check-circle"></i>
                </div>
              </div>
              <div class="col-7 d-flex align-items-center">
                <div class="numbers">
                  <p class="card-category">{{$t('documents')}}</p>
                  <h4 class="card-title">{{ counts.document }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-stats">
          <div class="card-body ">
            <div class="row">
              <div class="col-5">
                <div class="icon-big text-center icon-warning">
                  <i class="la la-bar-chart text-warning"></i>
                </div>
              </div>
              <div class="col-7 d-flex align-items-center">
                <div class="numbers">
                  <p class="card-category">{{$t('fees')}}</p>
                  <h4 class="card-title">{{ counts.fee }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-stats">
          <div class="card-body ">
            <div class="row">
              <div class="col-5">
                <div class="icon-big text-center">
                  <i class="la la-bar-chart text-warning"></i>
                </div>
              </div>
              <div class="col-7 d-flex align-items-center">
                <div class="numbers">
                  <p class="card-category">{{$t('charge')}}</p>
                  <h4 class="card-title">{{ counts.turnover }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col-5">
                <div class="icon-big text-center">
                  <i class="fa fa-ticket"></i>
                </div>
              </div>
              <div class="col-7 d-flex align-items-center">
                <div class="numbers">
                  <p class="card-category">Tickets</p>
                  <h4 class="card-title">{{ counts.ticket }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: "home-toolbar",
        data () {
            return {
                factoryId: null,
                interval: null,
                showOverlayStats: true,
                counts: {
                    customer: 0,
                    invoice: 0,
                    sale: 0,
                    document: 0,
                    fee: 0,
                    turnover: 0,
                    ticket: 0
                },
            }
        },
        mounted() {
            this.loadStats();
        },
        methods: {
            loadStats: function () {
                this.showOverlayStats = true;
                this.$http.get('factory/'+this.$parent.getFactoryId()+'/stats').then((response) => {
                    this.showOverlayStats = false;
                    this.counts = response.data;
                }).catch((response) => {
                    this.showOverlayStats = false;
                });
            }
        }
    }
</script>
