<template>
    <div>
      <div class="row">
        <div class="col-md-12">
          <h1>{{ $t('company_information') }} <small><i class="fa fa-cog"></i> {{ $t('invoice') }}</small></h1>
          <hr />
        </div>

        <div class="col-md-3">
          <settings-menu></settings-menu>
        </div>

        <div class="col-md-9">
          <b-overlay :show="showOverlayConfig" rounded="sm">
            <form class="form form-horizontal" method="post"  v-on:submit.prevent="updateConfig">
              <div class="card">
                <div class="card-header"><h4 class="heading">Numérotation</h4></div>
                <div class="card-body">
                    <div class="form-group">
                      <label class="control-label col-xs-4">Première facture:</label>
                      <div class="col-xs-6">
                        <input class="form-control" type="number" v-model="config.invoice_start" />
                      </div>
                    </div>
                    <div class="form-group">
                      <b-form-checkbox v-model="config.invoice_annual" name="check-button" switch>
                        Facture numéro par année
                      </b-form-checkbox>
                    </div>
                    <div class="form-group">
                      <label class="control-label col-xs-4">Nombre de facture par an:</label>
                      <div class="col-xs-6">
                        <select class="form-control" v-model="config.invoice_annual_nb">
                          <option value=""></option>
                          <option value="99" v-bind:selected="config.invoice_annual_nb===99">0 - 99</option>
                          <option value="999" v-bind:selected="config.invoice_annual_nb===999">100 - 999</option>
                          <option value="9999" v-bind:selected="config.invoice_annual_nb===9999">1000 - 9999</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header"><h4 class="heading">Echeance</h4></div>
                  <div class="card-body">
                    <div class="form-group">
                      <label class="control-label col-xs-4">Jours d'échéance par défaut:</label>
                      <input class="form-control" type="number" v-model="config.invoice_deadline" />
                    </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <div class="form-group">
                    <label class="control-label col-xs-4"></label>
                    <div class="col-xs-6">
                      <button type="submit" class="btn btn-primary">Modifier</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </b-overlay>
        </div>
      </div>
    </div>
</template>

<script>
    import SettingsMenu from "./SettingsMenu";
    import {BOverlay, BFormCheckbox} from 'bootstrap-vue'
    import store from '../../store'

    export default {
      components: {SettingsMenu, BOverlay, BFormCheckbox},
      name: "settings",
        data () {
          return {
            config: {},
            showOverlayConfig: true,
          }
        },
        mounted () {
          this.loadConfig();
          this.loadData();
        },
        methods: {
          updateConfig: function () {
              this.showOverlayConfig = true;
              this.$http.put('factory/'+store.state.factoryConnected.id+'/config', this.config).then((response) => {
                  this.showOverlayConfig = false;
                  if (response.ok === true) {
                      this.config = response.data;
                  }
              }).catch((response)=>{
                  this.showOverlayConfig = false;
              });
          },
          loadConfig: function () {
              this.showOverlayConfig = true;
              this.$http.get('factory/'+store.state.factoryConnected.id+'/config?section=invoice').then((response) => {
                  this.showOverlayConfig = false;
                  if (response.ok === true) {
                      this.config = response.data;
                  }
              }).catch((response)=>{
                  this.showOverlayConfig = false;
              });
          },
          loadData : function () {
            this.$http.get('data/country').then((response) => {
              if (response.ok === true) {
                this.countries = response.data;
              }
            });
            this.$http.get('data/category/factory').then((response) => {
              if (response.ok === true) {
                this.categories = response.data;
              }
            });
            this.$http.get('data/timezone').then((response) => {
              if (response.ok === true) {
                this.timezones = response.data;
              }
            });
            this.$http.get('data/currency').then((response) => {
              if (response.ok === true) {
                this.currencies = response.data;
              }
            });
          },
        }
    }
</script>
