<template>
    <div>
      <b-overlay :show="showOverlayUserModule" rounded="sm">
        <table class="table table-bordered table-hover">
          <tbody>
            <tr v-if="modules.length===0"><td colspan="10">{{$t('no_module')}}</td></tr>
            <tr v-for="module in modules" :class="classTrModule(module.access)">
              <th>{{module.name}}</th>
              <th>
                <b-button v-if="module.access===true" variant="warning" v-on:click="setModule(module, 0)">{{ $t('click_to_deactivate') }}</b-button>
                <b-button v-if="module.access===false" variant="success" v-on:click="setModule(module, 1)">{{ $t('click_to_activate') }}</b-button>
              </th>
            </tr>
          </tbody>
        </table>
      </b-overlay>
    </div>
</template>

<script>
  import {BButton, BOverlay} from 'bootstrap-vue'
  export default {
    components: {
        'b-button': BButton,
        'b-overlay': BOverlay
    },
    name: "user-module",
    props: {
        userId: {
            type: String,
            required: false,
        },
    },
    data () {
      return {
        modules: [],
        interval: null,
        showOverlayUserModule: false
      }
    },
    mounted () {
        this.interval = setInterval(function() {
            if (this.userId>0) {
                this.loadModules();
                clearInterval(this.interval);
            }
        }.bind(this), 1000);
    },
    methods: {
      loadUser : function (userId) {
          this.loadModules(userId);
      },
      loadModules: function (userId) {
            this.showOverlayUserModule = true;
            this.$http.get('user/' + userId + '/modules').then((response) => {
                this.showOverlayUserModule = false;
                if (response.ok === true) {
                    this.modules = response.data;
                }
            }).catch((response)=> {
                this.showOverlayUserModule = false;
            });
      },
      classTrModule: function (access) {
          if (access === true) {
              return 'table-success';
          }
          return null;
      },
      setModule : function (module, action) {
          this.showOverlayUserModule = true;
          this.$http.put('user/' + this.userId + '/module/' + module.id + '/enabled/' + action).then((response) => {
              this.showOverlayUserModule = false;
              if (response.ok === true) {
                  module.access = response.data.access;
              }
          }).catch((response)=> {
              this.showOverlayUserModule = false;
          });
      },
    }
  }
</script>

