<template>
    <div>
        <b-form-group
          id="fieldset-time-start"
          description="Start of period"
          label="Enter start date"
          label-for="time-start"
          :invalid-feedback="invalidFeedback"
          :valid-feedback="validFeedback"
          :state="state(timeStart)">
          <b-form-input id="time-start" v-model="timeStart" type="date" class="mb-2"></b-form-input>
        </b-form-group>

        <b-form-group
          id="fieldset-time-end"
          description="End of period"
          label="Enter end date"
          label-for="time-end"
          :invalid-feedback="invalidFeedback"
          :valid-feedback="validFeedback"
          :state="state(timeEnd)">
          <b-form-input id="time-end" v-model="timeEnd" type="date"></b-form-input>
        </b-form-group>

        <b-form-group
          id="fieldset-submit">
          <b-button v-on:click="submit" variant="primary" :disabled="timeStart === null || timeEnd === null || timeStart === '' || timeEnd === ''">Change period</b-button>
        </b-form-group>
    </div>
</template>

<script>
    import {BFormGroup, BFormInput, BButton} from 'bootstrap-vue'
    export default {
        components: {
            BFormGroup,
            BFormInput,
            BButton,
        },
        props: {
            submitParent: {
                type: Function,
                required: true,
            }
        },
        computed: {
            invalidFeedback() {
                return this.state === false ? 'Please enter something' : ''
            },
            validFeedback() {
                return this.state === true ? 'Thank you' : ''
            }
        },
        methods: {
            submit : function () {
                if (this.timeStart && this.timeEnd && (this.$route.query.start !== this.timeStart || this.$route.query.end !== this.timeEnd)) {
                    this.$router.push({
                        name: 'rapport',
                        query: {
                            start: this.timeStart,
                            end: this.timeEnd,
                        }
                    });
                    this.submitParent(this.timeStart, this.timeEnd);
                }
            },
            state(value) {
                return value !== null;
            }
        },
        mounted () {
          this.timeStart = this.$route.query.start ? this.$route.query.start : null;
          this.timeEnd = this.$route.query.end ? this.$route.query.end : null;
        },
        data () {
            return {
                timeStart: null,
                timeEnd: null,
            }
        },
        name: "rapport-form",
    }
</script>
