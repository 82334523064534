<template>
  <div id="app">
    <div class="main-header">
      <div class="logo-header">
        <router-link :to="{name: 'root'}" class="logo">
          {{ apps }} &nbsp; <i :class="flagClass"></i>
        </router-link>
        <button class="navbar-toggler sidenav-toggler ml-auto" type="button" data-toggle="collapse" data-target="collapse" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <button class="topbar-toggler more"><i class="la la-ellipsis-v"></i></button>
      </div>
      <nav class="navbar navbar-header navbar-expand-lg">
        <div class="container-fluid">
          <form class="navbar-left navbar-form nav-search mr-md-3" v-on:submit.prevent="searchSubmit">
            <div class="input-group">
              <input type="text" :placeholder="$t('keywords')" id="searchInputPages" v-model="searchQuery" value="" class="form-control">
              <div class="input-group-append">
                  <span class="input-group-text">
                      <i class="la la-search search-icon"></i>
                  </span>
              </div>
            </div>
          </form>
          <ul class="navbar-nav topbar-nav ml-md-auto align-items-center">
            <li class="nav-item dropdown hidden-caret" v-if="factoryUsers.length > 1">
              <router-link :to="{name:'index'}" class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-industry"></i> {{$t('switch_factory')}}
              </router-link>
            </li>
            <li class="nav-item dropdown hidden-caret">
              <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="quickMenuDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-star"></i> {{$t('quick_menu')}}
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                   <router-link :to="{name: 'rapport'}" class="dropdown-item">
                    <img :src="'/assets/img/intervat.jpg'" style="max-width: 20px; max-height: 20px;"/> Déclaration Tva
                   </router-link>
                  <div class="dropdown-divider"></div>
                  <router-link :to="{name: 'create-customer'}" class="dropdown-item">
                    <i class="fa fa-user"></i> {{$t('add_customer')}}
                  </router-link>
                  <router-link :to="{name: 'create-invoice'}" class="dropdown-item">
                    <i class="fa fa-file"></i> {{$t('create_invoice')}}
                  </router-link>
                  <router-link :to="{name: 'create-charge'}" class="dropdown-item">
                    <i class="fa fa-briefcase"></i> {{$t('create_charge')}}
                  </router-link>
              </div>
            </li>
            <li class="nav-item dropdown hidden-caret">
              <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="la la-bell"></i>
              </a>
              <ul class="dropdown-menu notif-box" aria-labelledby="navbarDropdown">
                <li>
                  <div class="dropdown-title">You have 4 new notification</div>
                </li>
                <li>
                  <div class="notif-center">
                    <a href="javascript:void(0);">
                      <div class="notif-icon notif-primary"> <i class="la la-user-plus"></i> </div>
                      <div class="notif-content">
                                                          <span class="block">
                                                              New user registered
                                                          </span>
                        <span class="time">5 minutes ago</span>
                      </div>
                    </a>
                  </div>
                </li>
                <li>
                  <a class="see-all" href="javascript:void(0);"> <strong>See all notifications</strong> <i class="la la-angle-right"></i> </a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="dropdown-toggle profile-pic" data-toggle="dropdown" href="javascript:void(0);" aria-expanded="false">
                <img :src="user.picture" alt="user-img" width="36" class="img-circle">
                <span >{{ user.name }}</span>
              </a>
              <ul class="dropdown-menu dropdown-user">
                <li>
                  <div class="user-box">
                    <div class="u-img"><img :src="user.picture" alt="user"></div>
                    <div class="u-text">
                      <h4>{{ user.name }}</h4>
                      <p class="text-muted">{{ user.email }}</p>
                      <router-link :to="{name: 'user', params: {userId: user.id}}" class="btn btn-rounded btn-danger btn-sm">{{$t('my_account')}}</router-link>
                    </div>
                  </div>
                </li>
                <div class="dropdown-divider"></div>
                <router-link :to="{name: 'users'}" class="dropdown-item"><i class="fa fa-user"></i> {{$t('users')}}</router-link>
                <div class="dropdown-divider"></div>
                <router-link :to="{name: 'subscription'}" class="dropdown-item"><i class="fa fa-euro"></i> {{$t('subscription')}}</router-link>
                <div class="dropdown-divider"></div>
                <router-link :to="{name: 'settings'}" class="dropdown-item"><i class="fa fa-cogs"></i> {{$t('settings')}}</router-link>
                <div class="dropdown-divider"></div>
                <a href="javascript:void(0);" v-on:click="logout" class="dropdown-item"><i class="fa fa-power-off"></i> {{$t('logout')}}</a>
              </ul>
              <!-- /.dropdown-user -->
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="sidebar">
      <div class="scrollbar-inner sidebar-wrapper">
        <div class="user">
          <div class="photo">
            <img :src="user.picture">
          </div>
          <div class="info">
            <a class="" data-toggle="collapse" href="#collapseExample" aria-expanded="true">
                                  <span>
                                      {{ user.first_name }}
                                      <span class="user-level">{{ user.title }}</span>
                                      <span class="caret"></span>
                                  </span>
            </a>
            <div class="clearfix"></div>

            <div class="collapse in" id="collapseExample" aria-expanded="true" style="">
              <ul class="nav">
                <li>
                  <router-link :to="{name: 'user', params: {userId: user.id}}">
                    <span class="link-collapse">Mon compte</span>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name: 'settings'}">
                    <span class="link-collapse">{{ $t('settings') }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ul class="nav">
          <li class="nav-item">
            <router-link :to="{name: 'root'}">
              <i class="fa fa-home"></i>
              <span>Accueil</span>
            </router-link>
          </li>
          <li class="nav-item" v-for="menu in allowedMenus" v-bind:key="menu.id">
            <span v-if="menu.children.length === 0">
              <router-link :to="{name: menu.name}">
                <i v-bind:class="menu.icon"></i>
                <span>{{ $t(menu.name) }}</span>
              </router-link>
            </span>
            <span v-else>
              <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                <i v-bind:class="menu.icon"></i> {{ $t(menu.name) }}
              </a>
              <div class="dropdown-menu">
                <router-link v-for="child in menu.children" v-bind:key="child.name" :to="{name: menu.name, query: {'type': child.name}}" class="dropdown-item">
                  {{ $t(child.name) }}
                </router-link>
              </div>
            </span>
          </li>
          <li class="nav-item update-pro">
            <router-link :to="{name: 'subscription'}">
              <i class="la la-hand-pointer-o"></i>
              <p>Update To Pro</p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main-panel">
      <div class="content" >
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </div>
      <b-modal
        id="modal-1"
        :title="$t('choose_a_language')"
        @show="loadLanguages">
        <ul>
          <li v-for="language in languages" v-bind:key="language.value" v-if="language.value!==user.lang">
            <b-link v-on:click="changeLanguage(language.value)">{{language.label}}</b-link>
          </li>
        </ul>
      </b-modal>
      <footer class="footer">
        <div class="container-fluid">
          <nav class="pull-left">
            <ul class="nav">
              <li class="nav-item">
                <router-link :to="{name: 'root'}" class="nav-link">
                  {{ apps }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{name: 'root'}" class="nav-link">
                  {{$t('help')}}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{name: 'root'}" class="nav-link">
                  {{$t('nums')}}
                </router-link>
              </li>
              <li class="nav-item">
                <b-link v-b-modal.modal-1 class="nav-link">{{$t('choose_a_language')}}</b-link>
              </li>
            </ul>
          </nav>
          <div class="copyright ml-auto">
            {{ yearCopy }}, made with <i class="la la-heart heart text-danger"></i> by <strong>{{ apps }}</strong>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
  import {BModal, BLink, VBModal} from 'bootstrap-vue'
  import store from '../store';
  export default {
    name: 'application',
    components: {
        'b-modal':BModal,
        'b-link':BLink
    },
    directives: {
        'b-modal': VBModal
    },
    data () {
      return {
        info: {},
        menus: [],
        factoryUsers: [],
        languages: [],
        querySearchValue: null,
        searchQuery: null
      };
    },
    mounted () {
      //this.loadMe();
      this.menus = store.state.menus;
      this.loadFactoryUsers(this.user.id);
      this.initNavBar();
    },
    computed: {
        yearCopy: function () {
            var date = new Date();
            return date.getFullYear();
        },
        apps: function () {
            return store.state.factoryConnected ? store.state.factoryConnected.name : null;
        },
        factory: function () {
            return store.state.factoryConnected;
        },
        user: function () {
            return store.state.userConnected;
        },
        flagClass : function() {
            if (typeof store.state.factoryConnected.country.code !== 'string') {
                return null;
            }
            return 'flag-icon flag-icon-'+store.state.factoryConnected.country.code.toLowerCase();
        },
        allowedMenus : function () {
            let menu = [];
            for (let i = 0; i <= this.menus.length; ++i) {
                if (typeof this.menus[i] !== 'undefined' && typeof this.menus[i].name === 'string') {
                    let l = this.$router.resolve({name: this.menus[i].name});
                    if (l.resolved.matched.length > 0) {
                        menu.push(this.menus[i]);
                    }
                }
            }
            return menu;
        }
    },
    methods: {
      loadFactoryUsers: function (userId) {
          this.$http.get('user/'+store.state.userConnected.id+'/factories').then((response) => {
              this.factoryUsers = response.data;
          });
      },
      switchFactory: function (factory) {
          this.$http.put('user/'+store.state.userConnected.id+'/factory/'+ factory.id + '/select').then((response) => {
              if (response.ok === true) {
                  this.$router.push({name: 'root', params: {'factoryRef': factory.reference}});
              }
          });
      },
      loadLanguages: function () {
          this.$http.get('languages').then((response) => {
              this.languages = response.data;
          }).catch((response) => {
          });
      },
      changeLanguage : function (language) {
          this.$http.put('user/'+store.state.userConnected.id+'/language/'+language).then((response) => {
              if (response.ok === true) {
                  this.loadMe();
                  this.loadLanguages();
              }
          }).catch((response) => {
          });
      },
      initNavBar: function () {
        var toggle_sidebar = false,
          toggle_topbar = false,
          nav_open = 0,
          topbar_open = 0;

        if(!toggle_sidebar) {
            $toggle = $('.sidenav-toggler');

            $toggle.click(function() {
                if (nav_open === 1){
                    $('html').removeClass('nav_open');
                    $toggle.removeClass('toggled');
                    nav_open = 0;
                }  else {
                    $('html').addClass('nav_open');
                    $toggle.addClass('toggled');
                    nav_open = 1;
                }
            });
            toggle_sidebar = true;
        }

        if(!toggle_topbar) {
            $topbar = $('.topbar-toggler');

            $topbar.click(function(){
                if (topbar_open === 1) {
                    $('html').removeClass('topbar_open');
                    $topbar.removeClass('toggled');
                    topbar_open = 0;
                } else {
                    $('html').addClass('topbar_open');
                    $topbar.addClass('toggled');
                    topbar_open = 1;
                }
            });
            toggle_topbar = true;
        }
      },
      logout : function () {
        this.$http.get('logout').then((response) => {
          if (response.ok === true) {
            localStorage.removeItem('x-user-token');
          }
          this.$parent.logged = false;
        }).catch((response)=>{

        })
      },
      setApplicationSpinner : function (value) {
        this.applicationSpinner = value;
      },
      searchSubmit : function () {
          let query = this.searchQuery;
          if (query !== null) {
              this.searchQuery = null;
              this.$router.push({name: 'customerSearch', params: {searchCustomer: query}});
          }
      }
    }
  }
</script>
