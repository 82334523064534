<template>
    <div>
      <div class="row">
        <div class="col-md-12">
          <h1>{{ $t('company_information') }} <small><i class="fa fa-cog"></i> {{ $t('paymodes') }}</small></h1>
          <hr />
        </div>

        <div class="col-md-3">
          <settings-menu></settings-menu>
        </div>

        <div class="col-md-9">
          <b-overlay :show="showOverlayTaxes" rounded="sm">
            <div class="card">
              <div class="card-header"><h3 class="panel-title">{{ $t('vat_rate') }}</h3></div>
              <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>{{ $t('vat_rate') }}</th>
                      <th></th>
                    </tr>
                  </thead>
                <tfoot>
                  <tr>
                    <td>
                      <input type="number" v-model="new_rate" class="form-control">
                    </td>
                    <td><button type="button" class="btn btn-primary" v-on:click="addRate()">{{ $t('add') }}</button></td>
                  </tr>
                </tfoot>
                <tbody>
                  <tr>
                    <td colspan="2" v-if="taxes.length === 0">{{$t('no_taxes')}}</td>
                  </tr>
                  <tr v-for="tax in taxes">
                    <td>{{ tax.rate }}</td>
                    <td>
                      <button class="btn btn-xs btn-danger">
                        <i class="fa fa-trash"></i> {{ $t('delete') }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
</template>

<script>
    import SettingsMenu from "./SettingsMenu"
    import {BOverlay} from 'bootstrap-vue'
    import store from '../../store'

    export default {
      components: {
          SettingsMenu,
          'b-overlay':BOverlay
      },
      name: "settings-taxes",
        data () {
          return {
            taxes : [],
            factory: {},
            new_rate: null,
            showOverlayTaxes: false
          }
        },
        mounted () {
          this.loadFactoryTaxes();
        },
        methods: {
          loadFactoryTaxes: function () {
            this.showOverlayTaxes = true;
            this.$http.get('factory/'+store.state.factoryConnected.id+'/settings/tax/vat_rate').then((response) => {
                this.showOverlayTaxes = false;
                if (response.ok === true) {
                    this.taxes = response.data;
                }
            }).catch((response)=>{
                this.showOverlayTaxes = false;
            });
          },
          addRate: function () {
              if (this.new_rate && this.new_paymode_id !== null) {
                  this.$http.post('factory/'+store.state.factoryConnected.id+'/settings/tax/vat/'+this.new_rate).then((response) => {
                      if (response.ok === true) {
                          this.loadFactoryTaxes();
                          this.new_rate = null;
                      }
                  });
              }
          }
        }
    }
</script>
