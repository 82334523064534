<template>
  <div>
    <div id="bg"></div>
    <div class="top-content">
      <div class="inner-bg">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-8 col-md-offset-2 form-box">
              <div class="form-top">
                <div class="form-top-left">
                  <h3>{{ login.factory.name }}</h3>
                  <p>{{ $t('two_factor_authentication') }}</p>
                </div>
                <div class="form-top-right">
                  <i class="fa fa-key"></i> <span class="badge">{{ login.factory.amounts.daily }}</span>
                </div>
              </div>
              <div class="form-bottom">
                <div class="row">
                  <div class="col-md-12" v-if="resultLogin">
                    <div v-bind:class="resultLoginLevel">{{ resultLogin }}</div>
                  </div>
                  <div class="col-md-7">
                    <form role="form" action="#" class="login-form" v-on:submit.prevent="checkCode">
                      <div class="form-group">
                        <label class="sr-only" for="form-login-code">{{ $t('code') }}</label>
                        <input type="text" v-model="code" v-bind:placeholder="$t('code')" class="form-username form-control" id="form-login-code" autofocus="autofocus" required>
                      </div>
                      <div class="form-group">
                        <button type="submit" :class="btnLoginClass">
                          <i class="fa fa-spinner fa-pulse fa-fw" v-if="loginSpinner"></i>
                          {{ $t('verif_code') }}
                        </button>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-5">
                    <b>{{ login.factory.name }}</b>
                    <br />{{ login.factory.owner }}
                    <br />
                    <br />
                    {{ login.factory.address.country }} {{ login.factory.address.zipcode }}
                    <br />{{ login.factory.address.city }}
                    <br />{{ login.factory.address.street }}
                    <br />
                    <span v-if="login.factory.activity!=null"><br /><i class="fa fa-search"></i> {{ login.factory.activity }}</span>
                    <span v-if="login.factory.website!=null"><br /><i class="fa fa-globe"></i> <a v-bind:href="login.factory.website">{{ login.factory.website }}</a></span>
                    <br />
                    <a href="javascript:void(0);"><i class="fa fa-book"></i> {{ login.text.cgu }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "login-two-factor",
        data () {
          return {
            resetPassword: false,
            factory_ref: 'demo',
            auth: {
                email: null,
                password: null
            },
            code: null,
            resultLogin: '',
            resultLoginLevel: '',
            login: {
              apps: '',
              text: {
                input: {},
                btn: {}
              },
              factory: {
                address: {},
                amounts: {}
              },
            },
            loginSpinner: false
          }
        },
        created () {
          this.factory_ref = this.$route.params.factoryRef;
        },
        mounted () {
          this.loadLoginPage();
        },
        computed:{
            btnLoginClass: function () {
                if (this.code !== null && this.code.length > 0) {
                    return 'btn btn-success';
                }
                return 'btn btn-default btn-disabled';
            }
        },
        methods: {
          loadLoginPage : function () {
            if (typeof this.$parent.loginEmail !== 'undefined') {
                this.auth.email = this.$parent.loginEmail;
            }
            this.$http.get('factory/'+this.factory_ref+'/cards').then((response) => {
              this.login = response.data.login;
            });
          },
          checkCode : function () {
            this.loginSpinner = true;
            this.$http.post('user/2fa', {
                code: this.code
            }).then((response) => {
                if (response.ok === true) {
                    window.location.reload();
                }
                this.loginSpinner = false;
            }).catch((response) => {
                this.loginSpinner = false;
            });
          }
        }
    }
</script>

<style src="../assets/css/login.css" scoped></style>
