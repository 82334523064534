<template>
    <div>
      <div class="row">
        <div class="col-md-12">
          <h1>{{ $t('company_information') }} <small><i class="fa fa-cog"></i> {{ $t('shops') }}</small></h1>
          <hr />
        </div>

        <div class="col-md-3">
          <settings-menu></settings-menu>
        </div>

        <div class="col-md-9">
          <b-overlay :show="showOverlayShops" rounded="sm">
            <div class="card">
              <table class="table table-bordered table-hover" id="listTill">
                <thead>
                  <tr>
                    <th>{{ $t('name') }}</th>
                    <th>{{ $t('description') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="shops.length===0"><td colspan="10">{{ $t('no_shop') }}</td></tr>
                  <tr v-for="till in shops">
                      <td>{{till.name}}</td>
                      <td>{{till.description}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
</template>

<script>
import {BOverlay} from 'bootstrap-vue'
import SettingsMenu from "./SettingsMenu";
import store from '../../store';
import MoneyFormat from 'vue-money-format'

export default {
      components: {
          SettingsMenu,
          'b-overlay': BOverlay,
          'money-format': MoneyFormat,
      },
      name: "settings-shops",
        data () {
          return {
            shops: [],
            showOverlayShops: false,
          }
        },
        mounted () {
          this.loadData();
        },
        methods: {
          loadData : function () {
            this.showOverlayShops = true;
            this.$http.get('factory/'+store.state.factoryConnected.id+'/shops?page=1').then((response) => {
              this.showOverlayShops = false;
              if (response.ok === true) {
                this.shops = response.data.items;
              }
            });
          }
        }
    }
</script>
