<template>
  <div>
    <sale :sale-id="this.$route.params.saleId" :customer-id="this.$route.params.customerId" :invoice-id="this.$route.params.invoiceId"></sale>
  </div>
</template>

<script>
    import Sale from "./Sale";

    export default {
        name: "sale-customer",
        components: {
          'sale': Sale
        },
        data () {
          return {
          }
        }
    }
</script>

<style scoped>

</style>
