const fetch = require('node-fetch');
const fetchAbsolute = require('fetch-absolute');
const _fetchApi = fetchAbsolute(fetch)(process.env.API_URL);

export const get = (url) => {
  let token = localStorage.getItem('x-user-token');
  if (typeof token !== 'undefined' && null !== token && 'undefined' !== token) {
    return _fetchApi(process.env.API_URL+url, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.API_KEY,
        'authorization': 'Bearer ' + token
      }
    })
  }
  return _fetchApi(process.env.API_URL+url, {
    method: 'GET',
    headers: {
      'x-api-key': process.env.API_KEY
    }
  })
};

export const post = (url, body) => {
  let token = localStorage.getItem('x-user-token');
  if (typeof token !== 'undefined' && null !== token && 'undefined' !== token) {
    return _fetchApi(process.env.API_UR + '/' + url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'x-api-key': process.env.API_KEY,
        'authorization': 'Bearer ' + token
      }
    })
  }
  return _fetchApi(process.env.API_UR + '/' + url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'x-api-key': process.env.API_KEY,
    }
  })
};
