<template>
  <div class="container">
    <form id="formAddInvoice" class="form" method="post" v-on:submit.prevent="insertOrUpdatePayment">
      <div class="card">
        <div class="card-header">
          <h4 class="modal-title">
            <i class="fa fa-list" v-on:click="loadPayments"></i>
            <span v-if="targetName">{{$t('payments')}} {{targetName}}</span>
            <router-link v-if="target==='invoice'" :to="{name: 'invoice', params: {invoiceId: targetId}}">{{$t('payments')}} {{targetName}}</router-link>
          </h4>
        </div>
        <b-overlay :show="showOverlayPayments" rounded="sm">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th></th>
              <th>{{$t('date')}}</th>
              <th>{{$t('type')}}</th>
              <th>{{$t('amount')}}</th>
              <th>{{$t('comment')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="payment in payments">
              <td>
                <button type="button" class="btn btn-info btn-xs" v-on:click="selectPayment(payment)" v-if="payment.allow.edit === true"><i class="fa fa-edit"></i></button>
                <button type="button" class="btn btn-danger btn-xs" v-on:click="deletePayment(payment)" v-if="payment.allow.delete === true"><i class="fa fa-trash"></i></button>
              </td>
              <td>{{payment.date}}</td>
              <td>{{$t(payment.mode)}}</td>
              <td>{{payment.amount}}</td>
              <td>{{payment.description}}</td>
            </tr>
            <tr v-if="countPayments === 0">
              <td colspan="10">{{$t('no_data')}}</td>
            </tr>
          </tbody>
          <tfoot v-if="amountLeft >0">
            <tr>
              <td>
                <div class="btn-group" v-if="currentPayment.id">
                  <button type="submit" class="btn btn-primary btn-xs"><i class="fa fa-save"></i></button>
                  <button type="button" class="btn btn-primary btn-xs dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="javascript:;" v-on:click="resetCurrentPayment"><i class="fa fa-eraser"></i> {{$t('cancel')}}</a>
                  </div>
                </div>
                <button type="submit" class="btn btn-xs btn-success" v-else-if="!currentPayment.id">
                  <i class="fa fa-plus"></i>
                </button>
              </td>
              <td><input type="date" class="form-control" v-model="currentPayment.date"></td>
              <td>
                <select class="form-control" v-model="currentPayment.mapping.paymode_factory">
                  <option value="">Selectionner un type</option>
                  <option v-for="paymode in paymodes" v-bind:value="paymode.id">{{$t(paymode.name)}}</option>
                </select>
              </td>
              <td>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      {{ currencyName }}
                    </span>
                  </div>
                  <input type="text" v-model="currentPayment.amount" class="form-control" v-bind:placeholder="$t('amount')"/>
                </div>
              </td>
              <td><textarea class="form-control" v-model="currentPayment.description"></textarea></td>
            </tr>
          </tfoot>
        </table>
        </b-overlay>
      </div>
    </form>
  </div>
</template>

<script>
    import {BOverlay} from 'bootstrap-vue'
    import store from '../../store'
    export default {
        components: {
          'b-overlay':BOverlay
        },
        name: "payments",
        data () {
          return {
            amountLeft: 0,
            targetName: null,
            currencyName: null,
            currentPayment: {
              mapping: {
                  paymode_factory: null
              },
            },
            progressBarAddInvoiceModal: false,
            payments: [],
            paymodes: [],
            showOverlayPayments: false
          }
        },
        props: {
            target: {
                type: String,
                required: false,
            },
            targetId: {
                type: String,
                required: false,
            },
        },
        mounted () {
          this.loadTarget();
          this.loadPaymode();
        },
        computed: {
          countPayments : function () {
            return this.payments ? this.payments.length : 0;
          }
        },
        methods: {
          loadTarget : function() {
            if (this.$route.params.target && this.$route.params.targetId && this.targetName === null) {
              switch (this.$route.params.target) {
                case 'document':
                    this.$http.get(this.$route.params.target+'/'+this.$route.params.targetId).then((response) => {
                        if (response.ok === true) {
                            this.targetName = response.data.name;
                            this.amountLeft = response.data.amount.left;
                            this.loadPayments();
                        }
                    });
                    break;
                case 'sale':
                    this.$http.get(this.$route.params.target+'/'+this.$route.params.targetId).then((response) => {
                    if (response.ok === true) {
                      this.targetName = ' - Sale n° '+this.$route.params.targetId+(response.data.title ? ' - '+response.data.title : '');
                      this.amountLeft = response.data.amount.left;
                      this.currencyName = response.data.currency.name;
                      this.loadPayments();
                    }
                  });
                  break;
              }
            }
          },
          loadPayments : function() {
            if (this.$route.params.target && this.$route.params.targetId) {
              this.showOverlayPayments = true;
              this.$http.get(this.$route.params.target+'/'+this.$route.params.targetId+'/payments').then((response) => {
                this.showOverlayPayments = false;
                this.payments = response.data;
              });
            }
          },
          loadPaymode : function() {
            this.$http.get('factory/'+store.state.factoryConnected.id+'/settings/payment/means').then((response) => {
              this.paymodes = response.data;
            });
          },
          selectPayment : function (payment) {
            this.currentPayment = payment;
          },
          resetCurrentPayment : function () {
            this.currentPayment = {
                mapping: {
                    paymode_factory: null
                },
            };
          },
          deletePayment : function (payment) {
            this.showOverlayPayments = true;
            this.$http.delete('payment/'+payment.id).then((response) => {
              this.showOverlayPayments = false;
              if (response.ok === true) {
                this.loadPayments();
              }
            });
          },
          insertOrUpdatePayment : function () {
            if (this.currentPayment.id) {
              this.updatePayment();
            } else {
              this.createPayment();
            }
          },
          updatePayment : function () {
            this.showOverlayPayments = true;
            this.$http.put('payment/'+this.currentPayment.id, this.currentPayment).then((response) => {
              if (response.ok === true) {
                this.resetCurrentPayment();
                this.loadPayments();
              } else {
                  this.showOverlayPayments = false;
              }
            });
          },
          createPayment : function () {
            var payment = this.currentPayment;
            payment.paymode_factory = payment.mapping.paymode_factory;
            delete payment.mapping;
            this.showOverlayPayments = true;
            this.$http.post(this.$route.params.target+'/'+this.$route.params.targetId+'/payment', payment).then((response) => {
              if (response.ok === true) {
                this.resetCurrentPayment();
                this.loadPayments();
              } else {
                  this.showOverlayPayments = false;
              }
            });
          }

        }
    }
</script>

<style scoped>

</style>
