<template>
  <div>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      :title="title"
    >
      <b-table striped hover :items="stocks.rows" :fields="stocks.fields" show-empty>
        <template v-slot:cell(id)="data">
          <b-button variant="primary" v-on:click="setToTill(data.value)"><i class="fa fa-shopping-cart"></i></b-button>
        </template>
        <template v-slot:cell(serial)="data">
          {{data.value?data.value:'-'}}
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
    import {BModal, BButton, BTable} from 'bootstrap-vue';
    import store from '../../../store'
    export default {
        name: "pos-stock",
        components: {
            BModal,
            BButton,
            BTable
        },
        computed: {
            title: function () {
                return 'Stock of <b>'+ this.article.article.name+'</b>';
            }
        },
        props: {
            tillId: {
                type: String,
                required: true,
            },
            reloadCaddy: {
                type: Function,
                required: true,
            },
        },
        data () {
          return {
            stocks: {
                rows: [],
                fields: [
                    {
                        key: 'id',
                        label: null,
                    },
                    {
                        key: 'serial',
                        label: 'S/N',
                    }
                ]
            },
            article: {
                id: null,
                article: {
                    id: null,
                    name: null,
                },
            },
          };
        },
        mounted : function () {
            //this.loadPaymodes();
            //this.loadInvoiceTypes();
        },
        methods: {
          showStock: function (article) {
              this.article = article;
              this.loadStocks();
              this.showModal();
          },
          resetStock: function () {
              this.stocks.rows = [];
              this.article = {
                  id: null,
                      article: {
                      id: null,
                          name: null,
                  },
              };
          },
          showModal() {
              this.$refs['modal'].show()
          },
          hideModal() {
              this.$refs['modal'].hide()
          },
          handleSubmit: function () {

          },
          toggleModal() {
              // We pass the ID of the button that we want to return focus to
              // when the modal has hidden
              this.$refs['modal'].toggle('#toggle-btn')
          },
          empty: function () {

          },
          setToTill: function (stockId) {
              this.$http.put('pos/article/'+this.article.id+'/stock/'+stockId).then((response) => {
                  if (response.ok === true) {
                      this.resetStock();
                      if (response.status === 200) {
                          this.reloadCaddy();
                      }
                      this.hideModal();
                  }
              });
          },
          loadStocks: function () {
              this.$http.get('pos/till/'+this.tillId+'/article/'+this.article.id+'/stocks?page=1').then((response) => {
                  if (response.ok === true) {
                      this.stocks.rows = response.data.items;
                  }
              });
          },
        }
    }
</script>

<style scoped>

</style>
