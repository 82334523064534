<template>
    <div>
      <b-overlay :show="showOverlayAccount" rounded="sm">
        <div class="card">
          <div class="card-header">
            <h4 class="modal-title"><i class="fa fa-plus"></i> List</h4>
          </div>
          <div class="card-body">
            <ul>
              <li><router-link :to="{'name': 'externals-ovh'}">O.V.H.</router-link></li>
              <li><router-link :to="{'name': 'externals-stripe'}">Stripe</router-link></li>
              <li><router-link :to="{'name': 'externals-mangopay'}">MangoPay</router-link></li>
            </ul>
          </div>
        </div>
      </b-overlay>
    </div>
</template>

<script>
    export default {
        name: "externals",
        data () {
          return {
          }
        }
    }
</script>
