<template>
    <div>
      <b-overlay :show="showOverlayUserPicture" rounded="sm">
        <div class="row">
          <div class="col-md-12" v-if="pictures.length===0">{{$t('no_photo')}}</div>
          <div class="col-md-2" v-for="picture in pictures">
            <img :src="picture.file.url" class="img-thumbnail" />
          </div>
        </div>
      </b-overlay>
    </div>
</template>

<script>
  import {BOverlay} from 'bootstrap-vue'
  export default {
    name: "user-photo",
    components: {
        'b-overlay': BOverlay
    },
    data () {
      return {
        pictures: [],
        interval: null,
        showOverlayUserPicture: false,
      }
    },
    props: {
        userId: {
            type: String,
            required: false,
        },
    },
    mounted (){
        this.interval = setInterval(function() {
            if (this.userId>0) {
                this.loadUser();
                clearInterval(this.interval);
            }
        }.bind(this), 1000);
    },
    methods: {
        loadUser : function (userId) {
          this.loadPhotos(userId);
        },
        loadPhotos: function () {
            if (userId !== null) {
                this.showOverlayUserPicture = true;
                this.$http.get('user/' + userId + '/pictures').then((response) => {
                    this.showOverlayUserPicture = false;
                    if (response.ok === true) {
                        this.pictures = response.data;
                    }
                }).catch((response)=> {
                    this.showOverlayUserPicture = false;
                });
            }
        },
    },
    computed: {
      user : function() {
          return this.$parent.user;
      }
    }
  }
</script>

