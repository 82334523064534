<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-12">
          <ol class="breadcrumb">
            <li class="breadcrumb-item active"><router-link :to="{name: 'customers'}">{{$t('customers')}}</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{name: 'settings-customer'}"><i class="fa fa-cog"></i> {{$t('settings')}}</router-link></li>
          </ol>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <form class="form-horizontal" v-on:submit.prevent="submitStatus">
              <h2>{{$t('status')}}</h2>
              <hr />
              <div class="col-md-8 col-md-offset-2 col-xs-12">
                <div class="form-group">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th><label for="currentStatusName">{{$t('name')}}</label></th>
                        <th><label for="currentStatusDescription">{{$t('description')}}</label></th>
                        <th><label for="currentStatusFinal">{{$t('final_status')}}</label></th>
                        <th colspan="2">
                          <span v-if="nbArchived>0">
                            <a href="javascript:;" v-on:click="switchArchive" v-if="!archived">
                              {{nbArchived}}
                              <span v-if="nbArchived>1">{{$t('archives')}}</span>
                              <span v-else-if="nbArchived=1">{{$t('archive')}}</span>
                            </a>
                            <a href="javascript:;" v-on:click="switchArchive" v-else>
                              {{$t('hidden_archive')}}
                            </a>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="s in status">
                        <td>{{s.name}}</td>
                        <td>{{s.description}}</td>
                        <td>
                          <i v-if="s.final" class="fa fa-check"></i>
                        </td>
                        <td>
                          <a v-if="s.action.edit" href="javascript:;" v-on:click="selectStatus(s)" class="btn btn-info btn-xs">
                            <i class="fa fa-edit"></i>
                          </a>
                        </td>
                        <td>
                          <a v-if="s.action.delete" href="javascript:;" v-on:click="deleteStatus(s)" class="btn btn-danger btn-xs">
                            <i class="fa fa-trash"></i>
                          </a>
                          <a v-if="s.action.restore" href="javascript:;" v-on:click="restoreStatus(s)" class="btn btn-default btn-xs">
                            <i class="fa fa-plus"></i>
                          </a>
                        </td>
                      </tr>
                      <tr v-if="countStatus == 0">
                        <td colspan="10">No status for customer</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>
                          <input type="text" v-model="currentStatus.name" id="currentStatusName" class="form-control"/>
                        </td>
                        <td>
                          <textarea v-model="currentStatus.description" id="currentStatusDescription" class="form-control"></textarea>
                        </td>
                        <td>
                          <input type="checkbox" v-model="currentStatus.final" id="currentStatusFinal"/>
                        </td>
                        <td>
                          <button type="submit" class="btn btn-primary btn-xs" v-if="currentStatus.id">
                            <i class="fa fa-save"></i>
                          </button>
                          <button type="submit" class="btn btn-success btn-xs" v-else>
                            <i class="fa fa-plus"></i>
                          </button>
                        </td>
                        <td>
                          <a href="javascript:;" v-on:click="resetCurrentStatus" class="btn btn-default btn-xs">
                            <i class="fa fa-eraser"></i>
                          </a>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SettingsCustomers",
    data () {
      return {
        status : [],
        currentStatus: {},
        archived: false,
        nbArchived: false
      }
    },
    computed: {
      countStatus : function () {
        return this.status ? this.status.length : 0;
      }
    },
    mounted () {
      this.loadStatus();
    },
    methods: {
      loadStatus : function() {
        this.$http.get('status/customer'+(this.archived === true ? '/archived' : '')).then((response) => {
          if (response.ok === true) {
            this.status = response.data.status;
            this.nbArchived = response.data.archived;
          }
        });
      },
      selectStatus : function (status) {
        this.currentStatus = status;
      },
      restoreStatus : function (status) {
        if (status.id) {
          this.$http.patch('status/customer/' + status.id+'/restore').then((response) => {
            if (response.ok === true) {
              this.loadStatus();
            }
          }).catch((response) => {

          });
        }
      },
      deleteStatus : function (status) {
        if (status.id) {
          this.$http.delete('status/customer/' + status.id).then((response) => {
            if (response.ok === true) {
              this.loadStatus();
            }
          }).catch((response) => {

          });
        }
      },
      submitStatus : function () {
        let status = this.currentStatus;
        if (status.id) {
          this.$http.put('status/customer/' + status.id, status).then((response) => {
            if (response.ok === true) {
              this.loadStatus();
              this.currentStatus = {};
            }
          }).catch((response) => {

          });
        } else {
          this.$http.post('status/customer', status).then((response) => {
            if (response.ok === true) {
              this.loadStatus();
              this.currentStatus = {};
            }
          }).catch((response) => {

          });
        }
      },
      resetCurrentStatus: function () {
        this.currentStatus = {};
      },
      switchArchive : function() {
        if (this.archived === true) {
          this.archived = false;
        } else {
          this.archived = true;
        }
        this.loadStatus();
      }
    }
  }
</script>
