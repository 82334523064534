<template>
  <div class="container">
    <form id="formAddInvoice" class="form" method="post" v-on:submit.prevent="createEvent">
      <b-overlay :show="showOverlayEvent" rounded="sm">
        <div class="card">
          <div class="card-header">
            <h4 class="modal-title"><i class="fa fa-plus"></i> {{$t('event')}} <small>{{currentEvent.id}}</small></h4>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label>{{$t('title')}}</label>
              <input type='text' class="form-control" v-model="currentEvent.name" v-bind:placeholder="$t('title')" required/>
            </div>
            <div class="form-group">
              <label for="event-date-start">{{$t('begin')}}: </label>
              <input type="datetime-local" id="event-date-start" class="form-control" v-model="currentEvent.start" v-bind:placeholder="$t('begin')" required/>
            </div>
            <div class="form-group">
              <label for="event-date-end">{{$t('end')}}:</label>
              <input type="datetime-local" id="event-date-end" class="form-control" v-model="currentEvent.end" v-bind:placeholder="$t('end')" required/>
            </div>
            <div class="form-group">
              <label>{{$t('description')}}:</label>
              <textarea type="date" class="form-control" v-model="currentEvent.description" v-bind:placeholder="$t('description')"></textarea>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-primary">
              <i class="fa fa-plus"></i> {{ $t('add') }}
            </button>
          </div>
        </div>
      </b-overlay>
    </form>
  </div>
</template>

<script>
    import { Datetime } from 'vue-datetime'
    import 'vue-datetime/dist/vue-datetime.css'
    import {BOverlay} from 'bootstrap-vue';
    import store from '../../store';
    export default {
        name: "create-event",
        components: {
          'datetime': Datetime,
          'b-overlay': BOverlay
        },
        data () {
          return {
            defaultEvent: {
                name: null,
                start: null,
                end: null,
                description: null,
            },
            currentEvent: {
                name: null,
                start: null,
                end: null,
                description: null,
            },
            showOverlayEvent: false
          }
        },
        mounted () {
            this.currentEvent = this.defaultEvent;
        },
        methods: {
          createEvent : function() {
            this.showOverlayEvent = true;
            this.$http.post('factory/'+store.state.factoryConnected.id+'/event',this.currentEvent).then((response) => {
                this.showOverlayEvent = false;
                this.currentEvent = this.defaultEvent;
                if (response.ok === true) {
                    this.currentEvent = response.data;
                    this.$router.push({name: 'event', params: {eventId: response.data.id}});
                }
            }).catch((response) => {
                this.showOverlayEvent = false;
            });
          },
        }
    }
</script>

<style scoped>

</style>
