import Vue from 'vue'
import VueResource from 'vue-resource'
import VueI18n from 'vue-i18n'
import round from 'vue-round-filter';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import FontAwesomeIcon from '@fortawesome/vue-fontawesome'

import App from './App.vue'
import router from './router'
import store from './store'
import {ToastPlugin} from "bootstrap-vue";

import VueTimeago from 'vue-timeago'

Vue.use(ToastPlugin);
Vue.use(VueResource);
Vue.use(VueI18n);
Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'fr', // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
  locales: {
    fr: require('date-fns/locale/fr'),
    nl: require('date-fns/locale/nl'),
    en: require('date-fns/locale/en'),
    it: require('date-fns/locale/it'),
    es: require('date-fns/locale/es')
  }
})

library.add(faUserSecret)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
Vue.filter('round', function (value, decimal = 2) {
  return value.toFixed(decimal);
});


Vue.http.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Vue.http.headers.common['x-api-key'] = process.env.API_KEY;
Vue.http.options.root = process.env.API_URL;

let token = localStorage.getItem('x-user-token');
if (typeof token !== 'undefined' && null !== token && 'undefined' !== token) {
  Vue.http.headers.common['authorization'] = 'Bearer ' + token;
}

const i18nComponent = new VueI18n({
  locale: 'fr',
  messages: {}
});

new Vue({
  el: '#app',
  i18n: i18nComponent,
  store,
  router,
  render: h => h(App),
  created () {
    this.$store.dispatch('init')
  }
});


