<template>
  <div class="container">
    <form id="formAddCharge" class="form" method="post" v-on:submit.prevent="createCharge">
      <b-overlay :show="showOverlayCreateCharge" rounded="sm">
        <div class="card">
          <div class="card-header">
            <h4 class="modal-title"><i class="fa fa-plus"></i> {{$t('add_charge')}}</h4>
          </div>
          <div class="card-body">
            <div class="form-group" v-if="newCharge.supplier.id">
              <label>{{$t('suppliers')}}:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-user"></i>
                  </span>
                </div>
                <input type="text" class="form-control" v-model="newCharge.supplier.name" :readonly="true"/>
                <input type="hidden" v-model="newCharge.supplier.id"/>
              </div>
            </div>
            <div class="form-group" v-else>
              <label>{{$t('suppliers')}}:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-user"></i>
                  </span>
                </div>
                <select class="form-control" v-model="newCharge.supplier">
                  <option value="">Selectionner un fournisseur</option>
                  <option v-for="supplier in suppliers" v-bind:value="supplier">{{supplier.name}}</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <b-form-checkbox switch :checked="newCharge.intra" >{{$t('tax_intra_community')}}</b-form-checkbox>
            </div>
            <div class="form-group">
              <label>{{$t('number')}}:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-file"></i>
                  </span>
                </div>
                <input type="text" class="form-control" v-model="newCharge.number" v-bind:placeholder="$t('number')"/>
              </div>
            </div>
            <div class="form-group">
              <label>{{$t('title')}}:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-text-width"></i>
                  </span>
                </div>
                <input type="text" class="form-control" v-model="newCharge.name" v-bind:placeholder="$t('title')"/>
              </div>
            </div>
            <div class="form-group">
              <label>{{$t('description')}}:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-align-justify"></i>
                  </span>
                </div>
                <textarea class="form-control" v-model="newCharge.description" v-bind:placeholder="$t('description')"></textarea>
              </div>
            </div>
            <div class="form-group">
              <label>{{$t('barcode')}}:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-barcode"></i>
                  </span>
                </div>
                <input type="text" class="form-control" v-model="newCharge.barcode" v-bind:placeholder="$t('barcode')" />
              </div>
            </div>
            <div class="form-group">
              <label>{{$t('date')}}:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
                <input type='date' class="form-control" v-model="newCharge.date" v-bind:placeholder="$t('date')" required="true"/>
              </div>
            </div>
            <div class="form-group">
              <label>{{$t('deadline_of_invoice')}}:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
                <input type="date" class="form-control" v-model="newCharge.payment.deadline" v-bind:placeholder="$t('deadline_of_invoice')"/>
              </div>
            </div>
            <div class="form-group">
              <label>{{$t('bank_account')}}:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-bank"></i>
                  </span>
                </div>
                <input type="text" class="form-control" v-model="newCharge.payment.bank" v-bind:placeholder="$t('bank_account')"/>
              </div>
            </div>
            <div class="form-group">
              <label>{{$t('pay_reference')}}:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fa fa-link"></i>
                  </span>
                </div>
                <input type="text" class="form-control" v-model="newCharge.payment.reference" v-bind:placeholder="$t('pay_reference')"/>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-primary">
              <i v-if="showOverlayCreateCharge" class="fa fa-refresh fa-spin fa-fw"></i>
              <i v-else class="fa fa-plus"></i>
              {{ $t('add') }}
            </button>
          </div>
        </div>
      </b-overlay>
     </form>
    </div>
</template>

<script>
    import {BFormCheckbox, BOverlay} from 'bootstrap-vue'
    import store from '../../store'
    export default {
        name: "create-charge",
        components: {
          BFormCheckbox,
          BOverlay
        },
        data () {
          return {
            newCharge: {
              supplier: {
                  id: null,
                  name: null,
              },
              payment: {
                  bank: null,
                  reference: null,
                  deadline: null
              }
            },
            showOverlayCreateCharge: false,
            types: [],
            suppliers: [],
            invoiceTypes: [],
            nextNumber: null,
          }
        },
        mounted () {
            if (this.$route.params.customerId) {
                this.loadSupplier(this.$route.params.customerId);
            } else {
                this.loadSuppliers();
            }
        },
        methods: {
          createCharge : function () {
            if (this.newCharge.supplier.id) {
                this.showOverlayCreateCharge = true;
                this.$http.post('supplier/' + this.newCharge.supplier.id + '/charge', this.newCharge).then((response) => {
                    if (response.ok === true) {
                        this.newCharge = {};
                        this.$router.push({name: 'charge', params: {chargeId: response.data.id}});
                    }
                }).catch((response) => {
                    this.showOverlayCreateCharge = false;
                }).finally(() => this.showOverlayCreateCharge = false)
            }
          },
          loadSuppliers : function() {
              this.showOverlayCreateCharge = true;
              this.$http.get('factory/'+store.state.factoryConnected.id+'/suppliers').then((response) => {
                  this.suppliers = response.data;
                  this.showOverlayCreateCharge = false;
              });
          },
          loadSupplier : function(supplierId) {
              this.$http.get('customer/'+supplierId).then((response) => {
                  this.supplier = response.data;
                  this.newCharge.supplier = this.supplier;
              });
          },
        }
    }
</script>

<style scoped>

</style>
