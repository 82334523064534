<template>
    <div>
      <b-overlay :show="showOverlayUserPassword" rounded="sm">
        <form class="form" method="post" v-on:submit.prevent="updatePassword">
          <div class="form-group">
            <label for="pass1">{{$t('password')}}</label>
            <input type="password" id="pass1" class="form-control" v-model="password.password" :placeholder="$t('password')" required>
          </div>
          <div class="form-group">
            <label for="pass2">{{$t('retype-password')}}</label>
            <input type="password" id="pass2" class="form-control" v-model="password.password1" :placeholder="$t('retype-password')" required>
          </div>
          <button type="submit" class="btn btn-info pull-right">
            <i class="fa fa-save"></i> {{$t('change')}} {{$t('password').toLowerCase()}}
          </button>
        </form>
      </b-overlay>
    </div>
</template>

<script>
  import {BOverlay} from 'bootstrap-vue'
  export default {
    name: "user-password",
    components: {
        'b-overlay': BOverlay
    },
    props: {
        userId: {
            type: String,
            required: false,
        },
    },
    data () {
      return {
        password: {},
        showOverlayUserPassword: false,
      }
    },
    mounted (){

    },
    methods: {
    },
    computed: {
      userName : function() {
        if (typeof this.$parent.getUser === 'function' && this.$parent.getUser()) {
            let user = this.$parent.getUser();
            return user.first_name + ' ' + user.last_name;
        }
        return null;
      },
      updatePassword : function () {
        if (this.userId !== null) {
          this.showOverlayUserPassword = true;
          this.$http.put('user/' + this.userId + '/password', this.password).then((response) => {
              this.showOverlayUserPassword = false;
              if (response.ok === true) {
                  this.password = {};
              }
          }).catch((response)=> {
              this.showOverlayUserPassword = false;
          });
        }
      }
    }
  }
</script>

