<template>
  <div>
    <b-alert
      v-model="alertShow"
      class="position-fixed fixed-top m-0 rounded-0"
      style="z-index: 2000; padding: 20px"
      :variant="alertVariant"
      dismissible>
      {{ alertMessage }}
    </b-alert>
    <div class="limiter">
      <div class="container-login100">
        <div class="wrap-login100">
          <form class="login100-form validate-form" v-on:submit.prevent="submit">
            <span class="login100-form-title p-b-43">
              {{ $t(pageTitle) }}
            </span>

            <div class="wrap-input100 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
              <input class="input100" type="email" v-model="auth.email" required="required">
              <span class="focus-input100"></span>
              <span class="label-input100">{{ $t('email') }}</span>
            </div>

            <div class="wrap-input100 validate-input" data-validate="Password is required" v-if="section==='login'">
              <input class="input100" type="password" v-model="auth.password">
              <span class="focus-input100"></span>
              <span class="label-input100">{{ $t('password') }}</span>
            </div>

            <div class="wrap-input100 validate-input" data-validate="Password is required" v-if="section==='sign'">
              <input class="input100" type="password" v-model="auth.passwordEqual">
              <span class="focus-input100"></span>
              <span class="label-input100">{{ $t('password') }}</span>
            </div>

            <div class="flex-sb-m w-full p-t-3 p-b-32">
              <div class="contact100-form-checkbox" v-if="section==='login'">
                <input class="input-checkbox100" id="ckb1" type="checkbox" v-model="auth.remember">
                <label class="label-checkbox100" for="ckb1">
                  {{ $t('remember_me') }}
                </label>
              </div>
              <div class="contact100-form-checkbox" v-else>&nbsp;</div>

              <div>
                <a href="javascript:;" class="txt1" v-if="section==='login'" v-on:click="switchToReset">{{ $t('password_lost') }}</a>
                <a href="javascript:;" class="txt1" v-else v-on:click="switchToLogin">{{ $t('sign_in') }}</a>
              </div>
            </div>


            <div class="container-login100-form-btn">
              <button class="login100-form-btn">
                {{ $t(pageTitle) }}
              </button>
            </div>

            <div class="text-center p-t-46 p-b-20" v-if="section!=='sign_up'">
              <span class="txt2">
                {{ $t('or') }} <a href="javascript:;" class="txt1" v-on:click="switchToSignUp">{{ $t('sign_up') }}</a>
              </span>
            </div>
          </form>
          <div class="login100-more"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import {BToast, BAlert} from 'bootstrap-vue'
    export default {
        name: "login",
        components: {
            BAlert,
            BToast,
        },
        data () {
          return {
            section: 'login',
            factory_ref: 'demo',
            alertShow: true,
            alertVariant: null,
            alertMessage: null,
            auth: {
                email: null,
                password: null,
                passwordEqual: null,
                remember: false
            },
            resultLogin: '',
            resultLoginLevel: '',
            login: {
              apps: '',
              text: {
                input: {},
                btn: {}
              },
              factory: {
                address: {},
                amounts: {}
              },
            },
            loginSpinner: false
          }
        },
        created () {
          this.factory_ref = this.$route.params.factoryRef;
        },
        mounted () {
        },
        computed:{
            pageTitle: function () {
                if ('login' === this.section) {
                    return 'login_page';
                }  else if ('sign_up' === this.section) {
                    return 'register';
                }  else if ('register' === this.section) {
                    return 'register';
                } else if ('reset' === this.section) {
                    return 'reset_password';
                }
            },
            btnResetClass: function () {
                if (this.isResetAllowed()) {
                    return 'btn btn-success';
                }
                return 'btn btn-default btn-disabled';
            },
            btnLoginClass: function () {
                if (this.isLoginAllowed()) {
                    return 'btn btn-success';
                }
                return 'btn btn-default btn-disabled';
            }
        },
        methods: {
          isLoginAllowed: function() {
            return this.isEmail(this.auth.email) && this.auth.password !== null && this.auth.password !== '';
          },
          isResetAllowed: function() {
              return this.isEmail(this.auth.email);
          },
          isEmail:function (email) {
              return email !== null && email !== '';
          },
          submit: function () {
              if ('login' === this.section) {
                  this.submitLogin();
              } else if ('reset' === this.section) {
                  this.submitResetPassword();
              } else if ('sign_up' === this.section) {
                  this.submitSignUp();
              }
          },
          submitSignUp: function () {
            this.loginSpinner = true;
            this.$http.post("register", this.auth).then((response) => {

                if (response.ok === true) {
                    this.loginSpinner = false;
                    this.makeToast('success', response.data.error ? response.data.error : 'Successfully register');
                }
            }).catch((response) => {
                console.error('response', response.status % 4, 300 %4, 500 % 4, 502%5);
                if (response.status >= 400 && response.status <= 499) {
                    this.makeToast('warning', response.data.error ? response.data.error : this.auth.email + ' is maybe wrong');
                } else {
                    this.makeToast('danger', response.data.error ? response.data.error : this.auth.email + ' is maybe wrong');
                }
                this.loginSpinner = false;
            });
          },
          submitLogin : function () {
            this.loginSpinner = true;
            let auth = this.auth;
            auth.factory = this.factory_ref;
            auth.user_agent = navigator.userAgent;
            this.$http.post("login", auth).then((response) => {

              if (response.ok === true) {
                this.loginSpinner = false;
                this.makeToast('success', response.data.message ? response.data.message : 'Successfully login');
                localStorage.setItem('x-user-token', response.data.auth);
                window.location.reload();
              }
            }).catch((response) => {

              this.loginSpinner = false;
              this.makeToast('danger', response.data.error ? response.data.error : 'Bad password');
            });
          },
          submitResetPassword : function () {
            this.loginSpinner = true;
            this.$http.post("login/reset", this.auth).then((response) => {

              if (response.ok === true) {
                  this.loginSpinner = false;
                  this.makeToast('success', response.data.message ? response.data.message : 'Successfully reset');
              }
            }).catch((response) => {
                this.loginSpinner = false;
                this.makeToast('danger', response.data.error ? response.data.error : this.auth.email + ' is maybe wrong');
            });
          },
          switchToLogin : function () {
              this.section = 'login';
          },
          switchToReset : function () {
              this.section = 'reset';
          },
          switchToSignUp : function () {
              this.section = 'sign_up';
          },
          switchToRegister : function () {
              this.section = 'register';
          },
          makeToast(variant, message) {
              this.alertShow = true;
              this.alertVariant = variant;
              this.alertMessage = message;
          }
        }
    }
</script>

<style src="../assets/css/login.css" scoped></style>
