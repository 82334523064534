<template>
    <div>
      <b-card-group deck>
        <b-overlay :show="showOverlayVatCodes" rounded="sm">
          <b-card no-body>
            <b-card-header>V.A.T. Codes</b-card-header>
            <b-table striped hover :items="codes.rows" :fields="codes.fields" show-empty>
              <template v-slot:empty="scope">No code</template>
              <template v-slot:cell(value)="data">
                <money-format v-if="data.value !== null && data.value !== '' && data.value !== 0" :value="data.value"
                              locale='en'
                              :currency-code="currency.name"
                              :subunit-value=true
                              :hide-subunits=false>
                </money-format>
              </template>
            </b-table>
          </b-card>
        </b-overlay>
      </b-card-group>
    </div>
</template>

<script>
    import RapportForm from "./RapportForm";
    import store from '../../store';
    import MoneyFormat from 'vue-money-format'
    import {BCard, BCardGroup, BCardText, BContainer, BCol, BCardHeader, BRow, BCardFooter, BCardBody, BTable, BOverlay} from 'bootstrap-vue'
    export default {
        components: {
            BCard,
            BCardText,
            BRow,
            BCol,
            BContainer,
            BCardHeader,
            BCardFooter,
            BCardBody,
            BTable,
            RapportForm,
            BOverlay,
            'b-card-group': BCardGroup,
            MoneyFormat,
        },
        name: "rapport-code",
        mounted () {
            this.loadData();
        },
        computed: {
          currency: function () {
              return store.state.factoryConnected.currency;
          }
        },
        data () {
            return {
                timeStart: null,
                timeEnd: null,
                showOverlayVatCodes: false,
                codes: {
                    fields: [],
                    rows: [],
                },
            }
        },
        methods: {
            loadDataTimed: function (start, end) {
                this.timeStart = start;
                this.timeEnd = end;
                this.loadData()
            },
            loadData : function() {
                if (this.getPeriods()) {
                    this.showOverlayVatCodes = true;
                    this.$http.get('factory/'+store.state.factoryConnected.id+'/rapport/code?'+this.getPeriods()).then((response) => {
                        this.showOverlayVatCodes = false;
                        this.codes = response.data;
                    }).catch ((response)=>{
                        this.showOverlayVatCodes = false;
                    });
                }
            },
            getPeriods : function () {
                if (this.timeStart && this.timeEnd) {
                  return '&start='+this.timeStart+'&end='+this.timeEnd;
                } else if (this.$route.query.start && this.$route.query.end) {
                    return '&start='+this.$route.query.start+'&end='+this.$route.query.end;
                }
                return null;
            },
            isLoading: function () {
                return this.showOverlayVatCodes;
            }
        }
    }
</script>
