<template>
  <div v-if="!languageReady">
    <loading></loading>
  </div>
  <div v-else-if="isLogged === true && isTrusted === true && !hasFindFactory">
    <select-factory></select-factory>
  </div>
  <div v-else-if="isLogged === true && isTrusted === true && hasFindFactory">
    <div v-if="_pos">
      <pos></pos>
    </div>
      <div v-else-if="_pos_till">
      <pos-tills></pos-tills>
    </div>
    <div v-else>
      <application></application>
    </div>
  </div>
  <div v-else-if="isLogged === true && isTrusted === false">
    <login-two-factor email="email"></login-two-factor>
  </div>
  <div v-else-if="isLogged === false">
    <login email="email"></login>
  </div>
  <div v-else>
    <loading></loading>
  </div>
</template>

<script>
  import Application from './components/Application.vue';
  import Login from './components/Login.vue';
  import Loading from "./components/Loading";
  import Pos from "./components/Pos";
  import PosTills from "./components/PosTills";
  import SelectFactory from "./components/SelectFactory";
  import LoginTwoFactor from "./components/LoginTwoFactor";
  import store from './store';
  import * as fetchApi from './fetchApi'

  export default {
    components: {
      SelectFactory,
      Pos,
      PosTills,
      Loading,
      Application,
      Login,
      LoginTwoFactor
    },
    data () {
      return {
          app: null,
          findFactory: false,
          logged: null,
          apps: null,
          trusted: false,
          user: {},
          factory: {},
          menus: [],
          languageReady: false,
          loginEmail: null
      };
    },
    computed: {
      _pos : function () {
        return this.$route.name === 'pos' ;
      },
      _pos_till : function () {
        return this.$route.name === 'pos-tills';
      },
      isTrusted: function () {
          return store.state.isTrusted;
      },
      isLogged: function () {
          return store.state.isAuthenticated;
      },
      hasFindFactory: function () {
          if (typeof this.$route.params.factoryRef !== 'undefined' && this.$route.params.factoryRef !== '') {
              return true;
          }
          return false;
      }
    },
    methods: {
        loadLanguages: function () {
            fetchApi.get('languages').then(response => response.json()).then((languages)=> {
                this.loadLanguage('fr');
            });
        },
        loadLanguage: function (language) {
            fetchApi.get('language/'+language).then(response => response.json()).then((messages)=> {
                this.$i18n.setLocaleMessage(language, messages);
                this.$i18n.locale = language;
                this.languageReady = true;
            });
        }
    },
    mounted () {
      this.loadLanguages();
    }
  }
</script>
