<template>
  <div>
      <button
        v-for="category in categories"
        v-on:click="selectCategory(category)"
        class="btn btn-default btn-style"
        :disabled="category.nb.article===0">
        {{ category.name }}
      </button>
      <span v-if="categories.length===0">No category...</span>
  </div>
</template>

<script>
    import MoneyFormat from 'vue-money-format'
    export default {
      components: {
          'money-format': MoneyFormat,
      },
      name: "pos-tab-category",
      data () {
        return {
          categories: [],
        }
      },
      props: {
          tillId: {
              type: String,
              required: false,
          },
          selectCategory: {
              type: Function,
              required: true,
          },
          isCaddyItemsLoader: {
              type: Function,
              required: true,
          },
          setCaddyItemsLoader: {
              type: Function,
              required: true,
          },
      },
      computed: {
          caddyItemsLoader: function () {
              return this.isCaddyItemsLoader() === true
          }
      },
      mounted : function (){
          this.interval = setInterval(function() {
              if (this.tillId !== null) {
                  this.loadCategories();
                  clearInterval(this.interval);
              }
          }.bind(this), 1000);
      },
      methods: {
        loadCategories () {
          this.$http.get('pos/till/'+this.tillId+'/categories?target=article').then((response) => {
            if (response.ok === true) {
              this.categories = response.data;
            }
          });
        }
      }
    }
</script>

<style scoped>
  .btn-style {
    width:150px;
    height:150px;
    margin:5px;
  }
</style>
