<template>
    <div>
      <b-overlay :show="showOverlayUserFactory" rounded="sm">
        <form class="form">
          <div class="form-group">
            <label for="InputUserShop">{{ $t('shop') }}</label>
            <select class="form-control" id="InputUserShop" v-model="user.shop.id">
              <option></option>
              <option v-bind:value="shop.id" v-for="shop in shops">{{shop.name}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="InputUserTill">{{ $t('till') }}</label>
            <select class="form-control" id="InputUserTill" v-model="user.till.id">
              <option></option>
              <option v-bind:value="till.id" v-for="till in tills">{{till.name}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="InputUserTitle">{{ $t('title') }}</label>
            <input type="text" class="form-control" v-model="user.title" id="InputUserTitle"/>
          </div>
          <div class="form-group">
            <label for="InputDescription">{{ $t('description') }}</label>
            <textarea type="text" class="form-control" v-model="user.description" id="InputDescription"></textarea>
          </div>
          <div class="card-footer">
            <button type="button" v-on:click="updateUser" v-bind:class="updateButtonClass">
              <i class="fa fa-spinner fa-pulse fa-fw" v-if="updateSpinner"></i>
              <i class="fa fa-save" v-if="!updateSpinner"></i>
              {{ $t('save') }}
            </button>
          </div>
        </form>
      </b-overlay>
    </div>
</template>

<script>
  import UploadPicture from '../../Upload/UploadPicture';
  import {VBModal, BModal, BOverlay} from 'bootstrap-vue'
  export default {
    name: "user-factory",
    components: {
        UploadPicture,
        'b-overlay': BOverlay,
        'b-modal': BModal
    },
    directives: {
      'b-modal': VBModal
    },
    props: {
        userId: {
            type: String,
            required: false,
        },
        factoryId: {
            type: String,
            required: false,
        },
    },
    data () {
      return {
        updateSpinner : false,
        password: {},
        user: {
            shop: {
                id: null,
            },
            till: {
                id: null,
            }
        },
        modules: [],
        tills: [],
        shops: [],
        interval: null,
        showOverlayUserFactory: false
      }
    },
    mounted () {
        this.interval = setInterval(function() {
            if (this.userId>0 && this.factoryId>0) {
                this.loadUser();
                clearInterval(this.interval);
            }
        }.bind(this), 1000);
    },
    methods: {
      loadUser : function (userId) {
          if (userId && this.factoryId) {
              this.showOverlayUserFactory = true;
              this.$http.get('user/' + userId + '/factory/' + this.factoryId).then((response) => {
                  this.showOverlayUserFactory = false;
                  if (response.ok === true) {
                      this.user = response.data;
                      this.loadTills();
                      this.loadShops();
                  }
              }).catch((response) => {
                  this.showOverlayUserFactory = false;
              });
          }
      },
      loadTills : function () {
          this.showOverlayUserFactory = true;
          this.$http.get('pos/tills').then((response) => {
              this.showOverlayUserFactory = false;
              if (response.ok === true) {
                  this.tills = response.data;
              }
          }).catch((response)=> {
              this.showOverlayUserFactory = false;
          });
      },
      loadShops : function () {
          this.showOverlayUserFactory = true;
          this.$http.get('shops').then((response) => {
              this.showOverlayUserFactory = false;
              if (response.ok === true) {
                  this.shops = response.data;
              }
          }).catch((response)=> {
              this.showOverlayUserFactory = false;
          });
      },
      getUser: function () {
          return this.user;
      },
      updateUser : function () {
        this.updateSpinner = true;
        this.showOverlayUserFactory = true;
        this.$http.put('user/'+this.userId+'/factory/'+this.factoryId, this.user).then((response) => {
          this.showOverlayUserFactory = false;
          if (response.ok === true) {

          }
          this.updateSpinner = false;
        }).catch((response)=> {
            this.showOverlayUserFactory = false;
        });
      },
    },
    computed: {
      updateButtonClass : function() {
        return 'btn btn-default pull-right';
      },
    }
  }
</script>

