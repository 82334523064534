<template>
  <div>
    <div class="container">
      <h1>{{ $t('event') }}</h1>
      <b-overlay :show="showOverlayEvents" rounded="sm">
        <div class="card">
          <div class="card-body">
            <calendar
              @eventClick="eventClick"
              @eventResize="eventResize"
              @eventDrop="eventDrop"
              default-view="timeGridWeek"
              :business-hours="businessHours"
              :event-source-success="eventSourcesSuccess"
              :event-source-failure="eventSourcesFailure"
              :dates-render="loadEvents"
              :now-indicator="true"
              :week-numbers="true"
              :nav-links="true"
              :customButtons="customButtons"
              :locale="calendarLocale"
              :locales="calendarLocales"
              :header="header"
              :events="events"
              :plugins="calendarPlugins"
              :editable="true"
              :droppable="true"
              :selectable="true"
              />
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import bootstrapPlugin from '@fullcalendar/bootstrap'
  import interactionPlugin from '@fullcalendar/interaction'
  import allLocales from '@fullcalendar/core/locales-all';
  import {BOverlay} from 'bootstrap-vue';
  import store from '../../store';

  export default {
    components: {
        'calendar': FullCalendar, // make the <FullCalendar> tag available
        'b-overlay': BOverlay
    },
    data () {
      return {
        showOverlayEvents: false,
        calendarPlugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin ],
        calendarLocales: allLocales,
        calendarLocale: this.$i18n.locale,
        customButtons: {
            myCustomButton: {
                text: 'New event',
                click: () => this.$router.push({name: "event-new"})
            }
        },
        businessHours: [ // specify an array instead
            {
                daysOfWeek: [ 1, 2, 3 ], // Monday, Tuesday, Wednesday
                startTime: '08:00', // 8am
                endTime: '18:00' // 6pm
            },
            {
                daysOfWeek: [ 4, 5 ], // Thursday, Friday
                startTime: '10:00', // 10am
                endTime: '16:00' // 4pm
            }
        ],
        header: {
            left:   'prev,next, today, myCustomButton',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        events: [],
        selected: {},
      }
    },
    mounted() {
    },
    methods: {
        eventSourcesSuccess: function (e) {
            this.showOverlayEvents = false;
        },
        eventSourcesFailure: function (e) {
            this.showOverlayEvents = false;
        },
        loadEvents : function(e) {
            var start = this.formatDate(e.view.currentStart);
            var end = this.formatDate(e.view.currentEnd);
            this.showOverlayEvents = true;
            this.$http.get('factory/'+store.state.factoryConnected.id+'/events?start='+start+"&end="+end).then((response) => {
                  let event = null;
                  let events = response.data;
                  this.events = [];
                  for (let i in events) {
                      event = events[i];
                      this.events.push({
                        id: event.id,
                        start: event.start.datetime,
                        end: event.end.datetime,
                        color: event.color,
                        title: event.name
                      });
                  }
                this.showOverlayEvents = false;
            }).catch((response)=>{
                this.showOverlayEvents = false;
            });
        },
        eventClick: function (e) {
            this.$router.push({name: "event", params: {eventId: e.event.id}})
        },
        eventDrop: function(e) {
            this.updateEvent(e.event.id, {
                start: this.formatDateTime(e.event.start),
                end: this.formatDateTime(e.event.end)
            });
        },
        eventResize: function(e) {
            this.updateEvent(e.event.id, {
                start: this.formatDateTime(e.event.start),
                end: this.formatDateTime(e.event.end)
            });
        },
        updateEvent : function(eventId, content) {
            if (typeof eventId !== 'undefined') {
                this.showOverlayEvents = true;
                this.$http.put('event/' + eventId, content).then((response) => {
                    this.showOverlayEvents = false;
                    this.currentEvent = response.data;
                }).catch((response) => {
                    this.showOverlayEvents = false;
                });
            }
        },
        formatDate: function (dateString) {
            var m = new Date(dateString);
            return m.getUTCFullYear() + "-" +
                ("0" + (m.getUTCMonth()+1)).slice(-2) + "-" +
                ("0" + m.getUTCDate()).slice(-2);
        },
        formatDateTime: function (dateString) {
            var m = new Date(dateString);
            return m.getUTCFullYear() + "-" +
                ("0" + (m.getUTCMonth()+1)).slice(-2) + "-" +
                ("0" + m.getUTCDate()).slice(-2) + "T" +
                ("0" + m.getUTCHours()).slice(-2) + ":" +
                ("0" + m.getUTCMinutes()).slice(-2) + ":" +
                ("0" + m.getUTCSeconds()).slice(-2);
        }
    }
  }
</script>

<style lang="scss">

  @import '~@fullcalendar/core/main.css';
  @import '~@fullcalendar/daygrid/main.css';
  @import '~@fullcalendar/timegrid/main.css';
  @import '~@fullcalendar/bootstrap/main.css';

</style>
