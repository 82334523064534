<template>
  <div class="navbar-collapse collapse">
    <ul class="nav navbar-nav">
      <li><a href="#tabArticle" data-toggle="tab" class="btn btn-navbar-menu">{{ $t('article') }}</a></li>
      <li><a href="#tabCustomer" data-toggle="tab" class="btn btn-navbar-menu">{{ $t('customers') }}</a></li>
      <li><a href="#tabSales" data-toggle="tab" class="btn btn-navbar-menu">{{ $t('sales') }}</a></li>
    </ul>
    <form class="navbar-form navbar-left clear searchForm hidden_phone" id="searchFormBarcode" role="search" method="post" action="javascript:;">
      <div class="form-group bs-example clear">
        <div class="input-group">
          <input type="search" class="form-control typeahead" v-bind:placeholder="$t('keywords')" autofocus="on" autocomplete="off" />
          <button type="submit" class="btn"><i class="fa fa-search"></i></button>
        </div>
      </div>
    </form>
    <ul class="nav navbar-nav navbar-right">
      <li class="dropdown">
        <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown">
          <img v-bind:src="user.picture" class="img-circle clear" style="max-width:20px;max-height:20px;">
        </a>
        <ul class="dropdown-menu">
          <li>
            <div class="navbar-login">
              <div class="row">
                <div class="col-lg-4">
                  <p class="text-center">
                    <img v-bind:src="user.picture" class="img-circle clear" style="max-width:75px;max-height:75px;">
                  </p>
                </div>
                <div class="col-lg-8">
                  <p class="text-left"><a href="javascript:void(0);"><strong>{{ user.name }}</strong></a></p>
                  <p class="text-left small">{{ user.email }}</p>
                  <p class="text-left">
                    <a v-if="user.admin" href="javascript:void(0);" class="btn btn-primary btn-block btn-sm">
                      <span class="glyphicon glyphicon-cog"></span> {{ $t('settings') }}
                    </a>
                    <a v-else href="javascript:void(0);" class="btn btn-primary btn-block btn-sm disabled">
                      <span class="glyphicon glyphicon-cog"></span> {{ $t('settings') }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </li>
          <li class="divider"></li>
          <li>
            <div class="navbar-login navbar-login-session">
              <div class="row">
                <div class="col-lg-12">
                  <p>
                    <a href="javascript:void(0);" class="btn btn-danger btn-block">{{ $t('close') }} {{ $t('till') }} <i class="fa fa-sign-out"></i></a>
                  </p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <ul class="nav navbar-nav navbar-right">
      <li v-if="till.key">
        <a href="javascript:void(0);" class="clear" style="color:green">
          <i class="glyphicon glyphicon-globe"></i><b>{{ till.key }}</b>
        </a>
      </li>
      <li class="dropdown">
        <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-cog"></i>
          <span class="caret"></span>
        </a>
        <ul class="dropdown-menu">
          <li>
            <a href="javascript:void(0);">
              <i class="fa fa-money"></i>
              {{ $t('amount_average_of_the_cashier') }}: <b>€ {{ till.amountTillAverage}}</b>
            </a>
          </li>
          <li role="separator" class="divider" v-if="till.shop"></li>
          <li class="dropdown-header" v-if="till.shop"><i class="fa fa-home"></i> {{ till.shop.name }}</li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
    export default {
      name: "pos-top-bar",
      data () {
        return {
          items: [],
        }
      },
      computed: {
        till : function() {
          return {
            shop: {},
            customer: {}
          };
        },
        user: function () {
          return this.$parent.user;
        }
      }
    }
</script>

<style scoped>

</style>
