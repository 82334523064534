<template>
  <div>
    <b-overlay :show="showOverlayCustomerInvoices" rounded="sm">
      <div class="card">
        <div class="card-header">
          <router-link :to="{name: '\n'+
'    {\n'+
'      path: \'/:factoryRef/customer/:customerId/invoice\',\n'+
'      component: CreateInvoice,\n'+
'      name: \'create-invoice\'\n'+
'    },'}" class="btn btn-info">
            <i class="fa fa-plus"></i> {{$t('create_invoice')}}
          </router-link>
        </div>
        <table class="table table-condensed table-hover table-bordered">
          <thead class="align-center">
            <tr style="font-weight: bold;text-align: center;">
              <th>#</th>
              <th>{{$t('document')}}</th>
              <th>{{$t('number')}}</th>
              <th>{{$t('date')}}</th>
              <th>{{$t('amount')}}</th>
              <th>{{$t('payed')}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="invoices.length===0"><td colspan="10">{{$t('no_invoice')}}</td></tr>
            <tr v-for="invoice in invoices" v-bind:class="invoice.style.class">
              <td>
                <button v-if="invoice.locked === true" type="button" class="btn btn-xs btn-danger disabled"><i class="fa fa-lock"></i></button>
                <button v-if="invoice.locked === false" type="button" class="btn btn-xs btn-success disabled"><i class="fa fa-unlock"></i></button>
              </td>
              <td style="text-align:center"><b>{{$t(invoice.name)}}</b></td>
              <td style="text-align:center"><b>{{invoice.number}}</b></td>
              <td style="text-align:center">{{invoice.date}}</td>
              <td style="text-align:right">
                <money-format :value="invoice.amount.amount_incl"
                              locale='en'
                              :currency-code="invoice.currency.name"
                              :subunit-value=true
                              :hide-subunits=false>
                </money-format>
              </td>
              <td style="text-align:center">{{invoice.amount.payed_pc}} %</td>
              <td style="text-align:right">
                <router-link :to="{name: 'invoice', params: {'invoiceId': invoice.id}}" class="btn btn-xs btn-info">
                  <i class="fa fa-edit"></i>
                </router-link>
              </td>
              <td style="text-align:right">
                <a v-on:click="printInvoice(invoice.id)" target="_alt" class="btn btn-xs btn-default">
                  <i class="fa fa-print"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-overlay>
    <b-pagination
      v-model="currentPage"
      :number-of-pages="nbPages"
      :per-page="perPage"
      :total-rows="rows"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
      @change="loadPaginationCustomerInvoices"
    ></b-pagination>
  </div>
</template>

<script>
    import {BOverlay, BPagination} from 'bootstrap-vue'
    import MoneyFormat from 'vue-money-format'
    export default {
      name: "customer-table-invoice",
      data () {
        return {
          invoices: [],
          currentPage: 1,
          nbPages: 1,
          perPage: 1,
          rows: 0,
          showOverlayCustomerInvoices: false,
        }
      },
      props: {
          customerId: {
              type: String,
              required: true,
          },
      },
      components: {
          'b-overlay': BOverlay,
          'b-pagination':BPagination,
          'money-format': MoneyFormat,
      },
      computed: {
        currentIdCustomer : function() {
          return this.$route.params.customerId;
        },
        countInvoices : function () {
          return this.invoices.length;
        }
      },
      mounted () {
        this.currentPage = this.$route.query.page ? this.$route.query.page : 1;
        this.loadInvoices();
      },
      methods: {
        loadPaginationCustomerInvoices: function (pageNum) {
            this.showOverlayCustomerInvoices = true;
            this.currentPage = pageNum;
            this.$router.push({name: 'customer-invoices', params: {customerId: this.customerId}, query: {page: this.currentPage}});
            this.loadInvoices();
        },
        loadInvoices : function () {
          this.showOverlayCustomerInvoices = true;
          this.$http.get('customer/'+this.currentIdCustomer+'/invoices?page='+this.currentPage).then((response) => {
              this.showOverlayCustomerInvoices = false;
              this.rows = response.data.pagination.result;
              this.perPage = response.data.pagination.by_page;
              this.nbPages = response.data.pagination.pages;
              this.invoices = response.data.items;
          });
        },
        printInvoice : function (invoiceId) {
            this.$http.get('invoice/'+invoiceId+'/print').then((response) => {
                var data = response.data;
                if (data.length > 0 && data[0].path) {
                    window.open(data[0].path);
                }
            });
        },
      }
    }
</script>

<style scoped>

</style>
