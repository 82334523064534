<template>
    <div>
      <div class="row">
        <div class="col-md-12">
          <h1>{{ $t('company_information') }} <small><i class="fa fa-cog"></i> {{ $t('till') }}</small></h1>
          <hr />
        </div>

        <div class="col-md-3">
          <settings-menu></settings-menu>
        </div>

        <div class="col-md-9">
          <b-overlay :show="showOverlayTills" rounded="sm">
            <div class="card">
              <table class="table table-bordered table-hover" id="listTill">
                <thead>
                  <tr>
                    <th>{{ $t('number') }}</th>
                    <th>{{ $t('code') }}</th>
                    <th>{{ $t('shop') }}</th>
                    <th>{{ $t('user') }}</th>
                    <th>{{ $t('sales') }}</th>
                    <th>{{ $t('caddy') }}</th>
                    <th>{{ $t('open') }}</th>
                    <th>{{ $t('close') }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="till in tills">
                    <td>{{till.number}}</td>
                    <td>{{till.key}}</td>
                    <td>{{till.shop?till.shop.name:''}}</td>
                    <td>{{till.user?till.user.name:''}}</td>
                    <td>
                      <money-format v-if="till.amount" :value="till.amount"
                                    locale='en'
                                    :currency-code="till.currency.name"
                                    :subunit-value=true
                                    :hide-subunits=false>
                      </money-format>
                    </td>
                    <td>{{till.items.length?till.items.length:''}}</td>
                    <td>{{till.dates.open}}</td>
                    <td>{{till.dates.close}}</td>
                    <td>
                      <button v-if="till.key" class="btn btn-xs btn-primary">
                        <i class="fa fa-globe"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
</template>

<script>
    import {BOverlay} from 'bootstrap-vue'
    import SettingsMenu from "./SettingsMenu";
    import store from '../../store';
    import MoneyFormat from 'vue-money-format'

    export default {
      components: {
          SettingsMenu,
          'b-overlay': BOverlay,
          'money-format': MoneyFormat,
      },
      name: "settings-till",
        data () {
          return {
            tills: [],
            showOverlayTills: false,
          }
        },
        mounted () {
          this.loadData();
        },
        methods: {
          loadData : function () {
            this.showOverlayTills = true;
            this.$http.get('factory/'+store.state.factoryConnected.id+'/tills?page=1').then((response) => {
              this.showOverlayTills = false;
              if (response.ok === true) {
                this.tills = response.data.items;
              }
            });
          }
        }
    }
</script>
