<template>
    <div>
      <div class="row">
        <a name="caddy">&nbsp;</a>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-8"><ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link :to="{name: 'root'}"><i class="fa fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('users') }}</a></li>
            </ol></div>
            <div class="col-md-4" style="text-align: right">
              <a href="javascript:void(0);" class="btn btn-default btn-sq-md"><i class="fa fa-user-plus fa-5x"></i><br /> {{ $t('one') }}</a>
              <a href="javascript:void(0);" class="btn btn-info btn-sq-md"><i class="fa fa-user-plus fa-5x"></i><br /> {{ $t('more') }}</a>
            </div>
            <div class="col-md-12">
              <hr />
              <div class="card">
                <table class="table table-striped table-hover" id="userDataTable">
                  <thead>
                  <tr>
                    <th><i class="fa fa-user-secret"></i></th>
                    <th><i class="fa fa-home"></i> {{ $t('shop') }}</th>
                    <th>{{ $t('name') }}</th>
                    <th>{{ $t('title') }}</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-bind:class="user.trClass" v-for="user in users">
                    <td><i class="fa fa-check" v-if="user.admin===true"></i></td>
                    <td>{{user.shop?user.shop.name:''}}</td>
                    <td>{{user.user.name}}</td>
                    <td>{{user.title}}</td>
                    <td>
                      <router-link :to="{name: 'user', params : {userId: user.user.id}}" class="btn btn-xs btn-primary">
                        <span class="fa fa-edit"></span>
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    import store from '../../store'
    export default {
        name: "users",
        data () {
          return {
            users: [],
          }
        },
        mounted (){
          this.loadUsers();
        },
        methods: {
          loadUsers : function () {
            this.$http.get('factory/'+store.state.factoryConnected.id+'/users?page=1').then((response) => {
              this.users = response.data.items;
            });
          }
        }
    }
</script>
