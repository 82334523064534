<template>
    <div>
      <div class="row">
        <div class="col-md-12">
          <h1>{{ $t('company_information') }} <small><i class="fa fa-cog"></i> {{ $t('paymodes') }}</small></h1>
          <hr />
        </div>

        <div class="col-md-3">
          <settings-menu></settings-menu>
        </div>

        <div class="col-md-9">
          <b-overlay :show="showOverlayFactoryModule" rounded="sm">
            <div class="card">
              <table class="table table-bordered table-hover">
                <tbody>
                <tr v-if="modules.length===0"><td colspan="10">{{$t('no_module')}}</td></tr>
                <tr v-for="module in modules" :class="classTrModule(module.access)">
                  <th>{{module.name}}</th>
                  <th>
                    <b-button v-if="module.access===true" variant="warning" v-on:click="setModule(module, false)">{{ $t('click_to_deactivate') }}</b-button>
                    <b-button v-if="module.access===false" variant="success" v-on:click="setModule(module, true)">{{ $t('click_to_activate') }}</b-button>
                  </th>
                </tr>
                </tbody>
              </table>
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
</template>

<script>
    import SettingsMenu from "./SettingsMenu";
    import {BOverlay, BButton} from 'bootstrap-vue'
    import store from '../../store'

    export default {
      components: {
          SettingsMenu,
          'b-overlay':BOverlay,
          'b-button':BButton
      },
      name: "settings-modules",
        data () {
          return {
            modules: [],
            showOverlayFactoryModule: false,
          }
        },
        mounted () {
          this.loadModules();
        },
        methods: {
          loadModules: function (userId) {
              if (userId !== null) {
                  this.showOverlayFactoryModule = true;
                  this.$http.get('factory/' + store.state.factoryConnected.id + '/modules').then((response) => {
                      this.showOverlayFactoryModule = false;
                      if (response.ok === true) {
                          this.modules = response.data;
                      }
                  }).catch((response)=> {
                      this.showOverlayFactoryModule = false;
                  });
              }
          },
          classTrModule: function (access) {
              if (access === true) {
                  return 'table-success';
              }
              return null;
          },
          setModule : function (module, activate) {
              if (activate === true) {
                  this.showOverlayFactoryModule = true;
                  this.$http.post('factory/' + store.state.factoryConnected.id + '/module/' + module.id).then((response) => {
                      this.showOverlayFactoryModule = false;
                      if (response.ok === true) {
                          module.access = response.data.access;
                      }
                  }).catch((response) => {
                      this.showOverlayFactoryModule = false;
                  });
              } else if (activate === false) {
                  this.showOverlayFactoryModule = true;
                  this.$http.delete('factory/' + store.state.factoryConnected.id + '/module/' + module.id).then((response) => {
                      this.showOverlayFactoryModule = false;
                      if (response.ok === true) {
                          module.access = response.data.access;
                      }
                  }).catch((response) => {
                      this.showOverlayFactoryModule = false;
                  });
              }
          },
        }
    }
</script>
