<template>
  <div>
    <b-overlay :show="showOverlayCustomerAddress" rounded="sm">
      <form method="post" v-on:submit.prevent="save">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label>{{$t('country')}}:</label>
              <data-form-select-country v-model="customer.country.id" :country-id="customer.country.id"></data-form-select-country>
            </div>
            <div class="form-group">
              <label>{{$t('zipcode')}}:</label>
              <input type="text" class="form-control" v-model="customer.address.zipcode" v-bind:placeholder="$t('zipcode')">
            </div>
            <div class="form-group">
              <label>{{$t('city')}}:</label>
              <input type="text" class="form-control" v-model="customer.address.city" v-bind:placeholder="$t('city')">
            </div>
            <div class="form-group">
              <label>{{$t('street')}}:</label>
              <input type="text" class="form-control" v-model="customer.address.street" v-bind:placeholder="$t('street')">
            </div>
            <div class="form-group">
              <label>{{$t('number')}}:</label>
              <input type="text" class="form-control" v-model="customer.address.number"  v-bind:placeholder="$t('number')">
            </div>
            <div class="form-group">
              <label>{{$t('box')}}:</label>
              <input type="text" class="form-control" v-model="customer.address.box"  v-bind:placeholder="$t('box')">
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-success">
              <i class="fa fa-save"></i>
              {{$t('save')}}
            </button>
          </div>
        </div>
      </form>
    </b-overlay>
  </div>
</template>

<script>
    import {BOverlay} from 'bootstrap-vue'
    import store from '../../../store'
    import DataFormSelectCountry from "../../Data/FormSelect/DataFormSelectCountry";

    export default {
        name: "customer-table-address",
        components: {
            DataFormSelectCountry,
            'b-overlay': BOverlay
        },
        data() {
            return {
                showOverlayCustomerAddress: false,
            }
        },
        props: {
            customer: {
                type: Object,
                required: false,
            },
        },
        mounted() {
        },
        methods: {
            save: function () {
                if (this.customer.address.id) {
                    return this.update();
                } else {
                    return this.insert();
                }
            },
            update: function () {
                var data = {
                    country: this.customer.country,
                    address: this.customer.address,
                };
                this.showOverlayCustomerAddress = true;
                this.$http.put('customer/'+this.customer.id+'/address/'+this.customer.address.id, data).then((response) => {
                    this.showOverlayCustomerAddress = false;
                    if (response.ok === true) {
                        this.$parent.customer.address = response.data;
                    }
                }).catch((response) => {
                    this.showOverlayCustomerAddress = false;
                });;
            },
            insert: function () {
                var data = {
                    country: this.customer.country,
                    address: this.customer.address,
                };
                this.showOverlayCustomerAddress = true;
                this.$http.post('customer/'+this.customer.id+'/address', data).then((response) => {
                    this.showOverlayCustomerAddress = false;
                    if (response.ok === true) {
                        this.$parent.customer.address = response.data;
                    }
                }).catch((response) => {
                    this.showOverlayCustomerAddress = false;
                });;
            },
        }
    }
</script>

<style scoped>

</style>
