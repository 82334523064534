<template>
  <div class="modal fade" id="sendPostal">
    <div class="modal-dialog" role="document">
      <form class="form-horizontal" method="post" action="/invoice/2048/send/postal">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">Envoi bPost</h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <table class="table table-striped">
                    <thead><tr><th>Destination</th><th>Crédit</th><th>Couleur</th><th title="Recommandé .A.R">A.R.</th><th>Status</th><th>Date d'ajout</th></tr></thead>
                    <tbody>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-12">
                <div class="jumbotron">
                  <div class="form-group">
                    <label class="control-label col-xs-6">Envoie par pli:</label>
                    <div class="col-xs-1">
                      <input type="checkbox" style="width: auto" checked disabled/>
                    </div>
                    <div class="col-xs-4">
                      <small><em>(1 Cr)</em></small>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="control-label col-xs-6">Rappel:</label>
                    <div class="col-xs-5">
                      <input type="checkbox" name="rappel" value="1" style="width: auto">
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="control-label col-xs-6">Couleur :</label>
                    <div class="col-xs-1">
                      <input type="checkbox" name="color" value="1" style="width: auto">
                    </div>
                    <div class="col-xs-4">
                      <small><em>(+ 0.15 Cr)</em></small>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="control-label col-xs-6">Recommandé A.R.:</label>
                    <div class="col-xs-1">
                      <input type="checkbox" name="ar" value="1" style="width: auto">
                    </div>
                    <div class="col-xs-4">
                      <small><em>(+ 5 Cr)</em></small>
                    </div>
                  </div>
                  <input type="hidden" name="invoice" value="2048" />
                  <input type="hidden" name="type" value="postal" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary btn-lg"><i class="fa fa-send"></i> Envoyer</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-remove"></i> Fermer</button>
          </div>
        </div><!-- /.modal-content -->
      </form>
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->
</template>

<script>
    export default {
        name: "send-postal"
    }
</script>
