<template>
  <div class="container">
    <form id="formAddInvoice" class="form" method="post" v-on:submit.prevent="createSale">
      <div class="card">
        <div class="card-header">
          <h4 class="modal-title"><i class="fa fa-plus"></i> {{$t('add_a_sale')}}</h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>{{ $t('customer') }}</label>
            <select v-model="currentSale.mapping.customer_id" class="form-control">
              <option v-bind:value="customer.id" v-for="customer in customers">{{customer.full_name}}</option>
            </select>
          </div>
          <div class="form-group">
            <label>{{$t('date')}}:</label>
            <div class="input-group date input-append">
              <span class="input-group-addon"><span class="glyphicon glyphicon-calendar"></span></span>
              <input type="date" class="form-control" v-model="currentSale.date"/>
            </div>
          </div>
          <div class="form-group">
            <label>{{$t('title')}}</label>
            <div class="input-group date input-append">
              <span class="input-group-addon"><span class="glyphicon glyphicon-pencil"></span></span>
              <input type='text' class="form-control" v-model="currentSale.title" v-bind:placeholder="$t('title')" autocomplete="off"/>
            </div>
          </div>
          <div class="form-group">
            <label>{{$t('vat_rate')}}:</label>
            <div class="input-group date">
              <span class="input-group-addon">%</span>
              <select v-model="currentSale.vat_rate" class="form-control">
                <option value="">Selectionner un taux de T.V.A.</option>
                <option v-for="vat_rate in vat_rates" v-bind:value="vat_rate.rate">{{vat_rate.rate_formatted}}</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label>{{$t('amount')}} H.T.:</label>
            <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-euro"></i></span>
              <input type="text" v-model="currentSale.amount" class="form-control" v-bind:placeholder="$t('amount')"/>
            </div>
          </div>
          <div class="form-group">
            <label for="to_be_invoiced">&nbsp;</label>
            <div class="input-group">
              <span class="input-group-addon"><input type="checkbox" v-model="currentSale.to_be_invoiced" value="1" id="to_be_invoiced"/></span>
              <input type="text" value="A facturer ?" class="form-control" disabled/>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button type="submit" class="btn btn-success"  v-if="currentSale.id">
            <i v-if="progressBarAddInvoiceModal" class="fa fa-refresh fa-spin fa-fw"></i>
            <i v-else class="fa fa-edit"></i>
            {{ $t('edit') }}
          </button>
          <button type="submit" class="btn btn-primary"  v-else>
            <i v-if="progressBarAddInvoiceModal" class="fa fa-refresh fa-spin fa-fw"></i>
            <i v-else class="fa fa-plus"></i>
            {{ $t('add') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
    import store from '../../store'
    export default {
        name: "create-invoice",
        data () {
          return {
            currentSale: {
              _option: {},
              mapping: {}
            },
            progressBarAddInvoiceModal: false,
            vat_rates: [],
            customers: [],
          }
        },
        mounted () {
          this.loadCustomers();
          this.loadVatRates();
        },
        methods: {
          createSale : function() {
            this.progressBarAddInvoiceModal = true;
            this.$http.post('factory/'+store.state.factoryConnected.id+'/sale',this.currentSale).then((response) => {
              this.progressBarAddInvoiceModal = false;
              this.currentSale = {};
              this.$router.push({name: 'sale', params: {saleId: response.data.id}});
            });
          },
          loadCustomers : function() {
            this.$http.get('customers').then((response) => {
              this.customers = response.data;
            });
          },
          loadVatRates : function () {
            this.$http.get('factory/'+store.state.factoryConnected.id+'/settings/tax/vat_rate').then((response) => {
              if (response.ok === true) {
                this.vat_rates = response.data;
              }
            });
          },
        }
    }
</script>

<style scoped>

</style>
