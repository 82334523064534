<template>
  <div>
      <div v-for="sales in listTimeline">
        <b-overlay :show="showOverlaySales || sales.showOverlaySales" rounded="sm">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-3">
                  <router-link :to="{name:'customer', params: {'customerId':sales.customer.id}}">
                    <img class="img-circle" v-bind:src="sales.customer.picture" style="max-width: 75px; max-height: 75px"/>
                  </router-link>
                </div>
                <div class="col-md-9">
                  <h4><router-link :to="{name:'customer', params: {'customerId':sales.customer.id}}">{{sales.customer.name}}</router-link></h4>
                  <h6 v-if="sales.id !== null">{{sales.title}}
                    <router-link :to="{name:'sale', params: {'saleId':sales.id}}">
                      <money-format :value="sales.amount.amount_incl"
                                    locale='en'
                                    :currency-code="sales.currency.name"
                                    :subunit-value=true
                                    :hide-subunits=false>
                      </money-format>
                    </router-link>
                  </h6>
                </div>
                <div class="col-md-12">
                  <hr />
                  <router-link v-if="sales.user !== null" :to="{name:'user', params: {'userId':sales.user.id}}">{{sales.user.name}}</router-link> <i class="fa fa-clock-o"></i>
                  <timeago v-if="sales.id !== null" class="timeago" :datetime="sales.timestamp.create" :auto-update="60" :locale="locale">{{sales.timestamp.create}}</timeago>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
      <div class="col-md-12">
        <a href="" id="btnMoreSales" class="btn btn-default" style="display:none;width:100%"><i class="fa fa-refresh"></i> Charger encore</a>
      </div>
  </div>
</template>

<script>
  import timeago from 'timeago.js';
  import MoneyFormat from 'vue-money-format'
  import {BOverlay} from 'bootstrap-vue'

  var defaultSales = {
      id: null,
      showOverlaySales: false,
      overlay: true,
      amount: {
        amount_incl: null
      },
      user: {
          name: null
      },
      customer: {
          picture: null
      },
      timestamp: {
        create: null,
      },
      mapping: {
          customer_id: null
      }
  };

  export default {
    name: 'home-timeline',
    components: {
        'b-overlay':BOverlay,
        'money-format': MoneyFormat,
    },
    data () {
      return {
        listTimeline: [],
        lastTime: null,
        timeout: null,
        showOverlaySales: false
      }
    },
    computed: {
      locale: function () {
          return this.$i18n.locale;
      }
    },
    mounted () {
      this.loadDefaultSale();
      this.loadTimeline();
    },
    methods: {
      loadDefaultSale: function () {
          this.showOverlaySales = true;
          for (let i = 0; i <= 0; ++i) {
              this.listTimeline.push(defaultSales);
          }
      },
      loadTimeline : function (lastTime) {
        if (this.lastTime === null) {
            this.showOverlaySales = true;
        }
        this.$http.get('sales/latest?page=1'+(lastTime ? '&last_time='+lastTime: '')).then((response) => {
          if (this.lastTime === null) {
              this.showOverlaySales = false;
          }
          if (this.lastTime === null) {
              this.listTimeline = response.data.items;
          } else if (response.data.items.length > 0) {
              let items = response.data.items;
              for (let i in items) {
                  let item = items[i];
                  item.showOverlaySales = true;
                  this.listTimeline.unshift(item);
                  setTimeout(function() {
                      item.showOverlaySales = false
                  }.bind(item), 1000)
              }
          }
          this.lastTime = response.data.pagination.last_time;
        }).catch((response)=>{
          if (this.lastTime === null) {
              this.showOverlaySales = false;
          }
        });
      }
    }
  }
</script>
