<template>
  <div>
    <div class="card">
      <b-overlay :show="showOverlayStocks" rounded="sm">
        <table class="table table-hover table-bordered">
          <thead>
          <tr>
            <td>#</td>
            <td>{{ $t('serial_number') }}</td>
            <td>{{ $t('place') }}</td>
            <td>{{ $t('date_in') }}</td>
            <td>{{ $t('date_out') }}</td>
            <td>{{ $t('date_limit') }}</td>
            <td>{{ $t('nb_piece') }}</td>
            <td>{{ $t('comment') }}</td>
          </tr>
          </thead>
          <tbody>
          <tr v-if="stocks.length === 0">
            <td colspan="10">{{ $t('no_stock') }}</td>
          </tr>
          <tr v-for="stock in stocks">
            <td>
              <button type="button" v-on:click="restoreStock(stock)" class="btn btn-primary btn-xs">
                <i class="fa fa-refresh"></i> {{$t('restore')}}
              </button>
            </td>
            <td>{{ stock.serial }}</td>
            <td>{{ stock.place }}</td>
            <td>{{ stock.date.in }}</td>
            <td>{{ stock.date.out }}</td>
            <td>{{ stock.date.deadline }}</td>
            <td>{{ stock.piece }}</td>
            <td>{{ stock.comment ? 'Y' : ''}}</td>
          </tr>
          </tbody>
        </table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          @change="loadPaginationStocks"
        ></b-pagination>
      </b-overlay>
    </div>
  </div>
</template>

<script>
  import store from '../../store';
  import {BOverlay, BPagination, BTab, BTabs} from 'bootstrap-vue'
  export default {
    components: {
        BOverlay,
        BPagination,
        'b-tabs' : BTabs,
        'b-tab' : BTab,
    },
    name: 'article-stock',
    data () {
      return {
        currentPage: 1,
        nbPages: 1,
        perPage: 1,
        rows:0,
        showOverlayStocks: false,
        showOverlayStocksOut: false,
        quantity: 1,
        categories: [],
        availableSuppliers : [],
        stocks: [],
        stocksOut: [],
        suppliers: [],
        currentStock: {
            serial: null,
            piece: 1,
            comment: null,
            date: {
              in: null,
              out: null,
              deadline: null,
            }
        },
        progressBarSales: 0,
      }
    },
    props: {
        article: {
            type: String,
            required: true,
        },
        loadStocksIn: {
          type: Function,
          required: true,
        }
    },
    mounted () {
      this.interval = setInterval(function() {
          if (this.article) {
              this.loadStocks();
              clearInterval(this.interval);
          }
      }.bind(this), 1000);
    },
    methods : {
      reset: function () {
          this.currentStock = {
              serial: null,
              date: {
                  in: null,
                  out: null,
                  deadline: null,
              }
          };
      },
      loadPaginationStocks: function () {
          this.showOverlayStocks = true;
          setTimeout(this.loadStocks.bind(this), 100);
      },
      loadStocks : function (page) {
        this.showOverlayStocks = true;
        this.$http.get('article/'+this.article+'/stocks/out?page='+(this.currentPage)).then((response) => {
          this.showOverlayStocks = false;
          if (response.ok === true) {
            this.stocks = response.data.items;
            this.rows = response.data.pagination.result;
            this.perPage = response.data.pagination.by_page;
          }
        });
      },
      restoreStock : function(stock) {
        if (stock.id) {
          this.showOverlayStocks = true;
          this.$http.put('stock/'+stock.id+'/in').then((response) => {
            this.showOverlayStocks = false;
            if (response.ok === true) {
                let index = this.stocks.indexOf(stock);
                this.stocks.splice(index, 1);
                this.loadStocks();
                this.loadStocksIn();
            }
          });
        }
      },
    }
  }
</script>
