<template>
  <div class="card">
    <div class="card-header"><h1 class="tillAmount">
      <money-format v-if="currency" :value="till.amount"
                    locale='en'
                    :currency-code="currency.name"
                    :subunit-value=true
                    :hide-subunits=false>
      </money-format></h1></div>
    <b-overlay :show="isLoading" rounded="sm">
      <table id="tillCaddy" class="table table-striped table-hover">
        <thead>
          <tr>
            <th>{{ $t('title') }}</th>
            <th title="T.V.A.C.">{{currency.name}}</th>
            <th>X</th>
            <th>{{ $t('stock') }}</th>
            <th></th>
          </tr>
          <tr>
            <th colspan="4">{{ $t('empty') }}</th>
            <th>
              <button type="button" v-on:click="clearTill" class="btn btn-xs btn-danger" :disabled="isLoading">
                <span class="fa fa-trash"></span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in tillItems">
            <td>
              <router-link :to="{name: 'article', params: {articleId: item.article.id}}">{{item.name}}</router-link>
            <td>
              <a href="javascript:void(0);" class="btn_editTillArticle" data-title="Nouveau montant T.V.A.C.">
                <money-format v-if="currency" :value="item.amount.amount_incl"
                              locale='en'
                              :currency-code="item.currency.name"
                              :subunit-value=true
                              :hide-subunits=false>
                </money-format></a>
            </td>
            <td>{{item.nb.items}}</td>
            <td>
              <button type="button" class="btn btn-danger btn-xs" v-if="item.stock && item.stock.id" v-on:click="resetStock(item.id)">
                <i class="fa fa-trash"></i>
              </button>
              <button type="button" class="btn btn-info btn-xs" v-else-if="item.nb.stock > 0" v-on:click="findStock(item)">
                <i class="fa fa-search"></i>
              </button>
            </td>
            <td>
              <button v-on:click="delArticle(item.id)" class="btn btn-xs btn-warning btn_delArticle">- 1</button>
            </td>
          </tr>
          <tr v-if="isEmptyTill">
            <td colspan="10">{{ $t('caddy') }} {{ $t('empty') }} <span v-if="till.customer">{{ $t('for') }} {{till.customer.name}}...</span></td>
          </tr>
        </tbody>
        <tfoot v-if="!isEmptyTill">
          <tr>
            <th colspan="4">{{ $t('empty') }}</th>
            <th>
              <button type="button" v-on:click="clearTill" class="btn btn-xs btn-danger" :disabled="isLoading">
                <span class="fa fa-trash"></span>
              </button>
            </th>
          </tr>
        </tfoot>
      </table>
    </b-overlay>
    <div  v-if="!isEmptyTill" class="card-footer" style="text-align: center;">
      <button data-toggle="modal" data-target="#goSale" class="btn btn-success" :disabled="isLoading">
        <i class="fa fa-shopping-cart"></i> {{ $t('sale_to') }} {{till.customer.name}}
      </button>
    </div>
    <pos-stock ref="stock" :till-id="this.$route.params.tillId" :reload-caddy="loadTill"></pos-stock>
    <pos-go-sale :till-id="this.$route.params.tillId"></pos-go-sale>
  </div>
</template>

<script>
    import {BOverlay} from 'bootstrap-vue'
    import PosGoSale from "../Modal/PosGoSale"
    import MoneyFormat from 'vue-money-format'
    import store from '../../../store'
    import PosStock from "../Modal/PosStock";

    export default {
      components: {
          PosStock,
          PosGoSale,
          BOverlay,
          'money-format': MoneyFormat,
      },
      name: "pos-tab-caddy",
      props: {
          tillId: {
              type: String,
              required: false,
          },
          externalLoading: {
              type: Boolean,
              required: false,
          },
          isCaddyItemsLoader: {
              type: Function,
              required: true,
          },
          setCaddyItemsLoader: {
              type: Function,
              required: true,
          },
      },
      data () {
          return {
            items: [],
            isEmptyTill: true,
            showOverlayCaddyItems: false,
          }
      },
      mounted: {
      },
      computed: {
        isLoading: function () {
            return this.showOverlayCaddyItems === true || this.externalLoading === true || this.$parent.isCaddyItemsLoader() === true;
        },
        currency: function () {
            return store.state.factoryConnected.currency;
        },
        till : function() {
          return this.$parent.till;
        },
        factoryPaymodes : function () {
            return this.$parent.factoryPaymodes;
        },
        taxes : function () {
            return this.$parent.taxes;
        },
        tillItems : function () {
          try {
            this.isEmptyTill = this.$parent.till.items.length === 0;
          } catch(e) {
            this.isEmptyTill = true;
          }
          return this.$parent.till.items;
        },
      },
      methods: {
        loadTill: function () {
            this.$parent.loadTill();
        },
        findStock : function (article) {
            this.$refs['stock'].showStock(article);
        },
        resetStock : function (articleId) {
          this.setCaddyItemsLoader(true);
          this.$http.delete('pos/till/'+this.$route.params.tillId+'/article/'+articleId+'/stock').then((response) => {
              this.setCaddyItemsLoader(false);
              if (response.ok === true) {
                  this.loadTill();
              }
          });
        },
        clearTill : function() {
          this.showOverlayCaddyItems = true;
          this.$http.delete('pos/till/'+this.$route.params.tillId+'/articles').then((response) => {
            this.showOverlayCaddyItems = false;
            if (response.ok === true) {
              this.loadTill();
            }
          });
        },
        delArticle : function (articleId) {
          this.setCaddyItemsLoader(true);
          this.$http.delete('pos/till/'+this.$route.params.tillId+'/article/'+articleId).then((response) => {
            this.setCaddyItemsLoader(false);
            if (response.ok === true) {
              this.loadTill();
            }
          });
        }
      }
    }
</script>

<style scoped>

</style>
