<template>
    <div>
      <b-overlay :show="showOverlayVatCharges" rounded="sm">
        <b-card no-body>
          <b-card-header>Charges</b-card-header>
          <b-table striped hover :items="charges.rows" :fields="charges.fields" show-empty>
            <template v-slot:empty="scope">No charge</template>
            <template v-slot:cell(amount)="data">
              <money-format :value="data.value"
                            locale='en'
                            :currency-code="currency.name"
                            :subunit-value=true
                            :hide-subunits=false>
              </money-format>
            </template>
            <template v-slot:cell(amount_incl)="data">
              <money-format :value="data.value"
                            locale='en'
                            :currency-code="currency.name"
                            :subunit-value=true
                            :hide-subunits=false>
              </money-format>
            </template>
            <template v-slot:cell(amount_tax)="data">
              <money-format :value="data.value"
                            locale='en'
                            :currency-code="currency.name"
                            :subunit-value=true
                            :hide-subunits=false>
              </money-format>
            </template>
            <template v-slot:cell(tax_deductible)="data">
              <money-format :value="data.value"
                            locale='en'
                            :currency-code="currency.name"
                            :subunit-value=true
                            :hide-subunits=false>
              </money-format>
            </template>
            <template v-slot:cell(vat_rate_0)="data">
              <money-format v-if="data.value !== null && data.value !== ''"  :value="data.value"
                            locale='en'
                            :currency-code="currency.name"
                            :subunit-value=true
                            :hide-subunits=false>
              </money-format>
            </template>
            <template v-slot:cell(vat_rate_6)="data">
              <money-format v-if="data.value !== null && data.value !== ''"  :value="data.value"
                            locale='en'
                            :currency-code="currency.name"
                            :subunit-value=true
                            :hide-subunits=false>
              </money-format>
            </template>
            <template v-slot:cell(vat_rate_12)="data">
              <money-format v-if="data.value !== null && data.value !== ''" :value="data.value"
                            locale='en'
                            :currency-code="currency.name"
                            :subunit-value=true
                            :hide-subunits=false>
              </money-format>
            </template>
            <template v-slot:cell(vat_rate_21)="data">
              <money-format v-if="data.value !== null && data.value !== ''"  :value="data.value"
                            locale='en'
                            :currency-code="currency.name"
                            :subunit-value=true
                            :hide-subunits=false>
              </money-format>
            </template>
            <template v-slot:cell(intra)="data">
              <i class="fa fa-check" v-if="data.value === true"></i>
            </template>
          </b-table>
        </b-card>
    </b-overlay>
  </div>
</template>

<script>
    import RapportForm from "./RapportForm";
    import store from '../../store';
    import MoneyFormat from 'vue-money-format'
    import {BCard, BCardGroup, BCardText, BContainer, BCol, BCardHeader, BRow, BCardFooter, BCardBody, BTable, BOverlay} from 'bootstrap-vue'
    export default {
        components: {
            BCard,
            BCardText,
            BRow,
            BCol,
            BContainer,
            BCardHeader,
            BCardFooter,
            BCardBody,
            BTable,
            RapportForm,
            BOverlay,
            'b-card-group': BCardGroup,
            MoneyFormat,
        },
        name: "rapport-charge",
        mounted () {
            this.loadData();
        },
        computed: {
          currency: function () {
              return store.state.factoryConnected.currency;
          }
        },
        data () {
            return {
                timeStart: null,
                timeEnd: null,
                showOverlayVatCharges: false,
                charges: {
                    fields: [],
                    rows: [],
                },
            }
        },
        methods: {
            loadDataTimed: function (start, end) {
              this.timeStart = start;
              this.timeEnd = end;
              this.loadData()
            },
            loadData : function() {
                if (this.getPeriods()) {
                    this.showOverlayVatCharges = true;
                    this.$http.get('factory/'+store.state.factoryConnected.id+'/rapport/in?'+this.getPeriods()).then((response) => {
                        this.showOverlayVatCharges = false;
                        this.charges = response.data;
                    }).catch ((response)=>{
                        this.showOverlayVatCharges = false;
                    });
                }
            },
            getPeriods : function () {
                if (this.timeStart && this.timeEnd) {
                  return '&start='+this.timeStart+'&end='+this.timeEnd;
                } else if (this.$route.query.start && this.$route.query.end) {
                    return '&start='+this.$route.query.start+'&end='+this.$route.query.end;
                }
                return null;
            },
            isLoading: function () {
                return this.showOverlayVatCharges;
            }
        }
    }
</script>
