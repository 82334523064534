<template>
  <form ref="form" class="form" method="post">
    <b-modal
      ref="modal"
      id="modal-charge-file-edit"
      :title="$t('inventory')"
      @ok="submit">
    <div class="form-group">
      <label>Action</label>
      <select class="form-control" v-model="mode">
        <option value="add">Add</option>
        <option value="remove">Remove</option>
        <option value="update">Align</option>
      </select>
    </div>
    <div class="form-group">
      <label>File</label>
      <b-form-file
        v-model="file"
        :state="Boolean(file)"
        :multiple="false"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      ></b-form-file>
      <div class="mt-3">Selected file: {{ file ? file.name : '' }}</div>
    </div>
    </b-modal><!-- /.modal -->
  </form>
</template>
<script>
    import {BModal, BFormFile} from 'bootstrap-vue'
    import store from '../../../store'
    export default {
        name: 'modal-file-inventory',
        components: {
            BFormFile,
            BModal,
        },
        data () {
            return {
                main: null,
                file: null,
                mode : null,
            }
        },
        mounted() {
        },
        computed: {
        },
        methods: {
          resetFile: function () {
              this.mode = null;
              this.file = null;
          },
          showModal: function (main) {
              this.main = main;
              this.$refs['modal'].show();
          },
          submit : function (bvModalEvt) {
              var formData = new FormData();
              formData.append('image', this.file);
              if (!this.file) {
                alert('Select a file');
                return;
              }

              bvModalEvt.preventDefault();
              if ('add' === this.mode || 'insert' === this.mode || 'remove' === this.mode || 'delete' === this.mode || 'update' === this.mode) {
                this.$http.post('factory/' + store.state.factoryConnected.id + '/stock/inventory/upload/' + this.mode, formData).then((response) => {
                  if (response.ok === true) {
                    if (typeof this.main.loadArticles === 'function') {
                      this.main.loadArticles();
                    }
                    this.$refs['modal'].hide();
                  }
                });
              } else {
                alert('Select an action');
              }
          },
        }
    }
</script>
