<template>
  <select class="form-control">
    <option v-for="country in countries" :selected="country.id === countryId">{{country.name}}</option>
  </select>
</template>

<script>
    export default {
        name: "data-form-select-country",
        data () {
            return {
                countries: []
            }
        },
        props: {
            countryId: {
                type: Number,
                required: false,
            },
        },
        mounted () {
            this.loadCountries();
        },
        methods: {
            loadCountries : function() {
                this.$http.get('data/country').then((response) => {
                    if (response.ok === true) {
                        this.countries = response.data;
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>
