<template>
  <div class="container">
    <form id="formAddCharge" class="form" method="post" v-on:submit.prevent="createCharge">
      <b-overlay :show="showOverlayTicket" rounded="sm">
        <div class="card">
          <div class="card-header">
            <h4 class="modal-title"><i class="fa fa-plus"></i> {{$t('ticket')}}: {{ticket.id}}</h4>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label>{{$t('priority')}}:</label>
              <input type="text" class="form-control" v-model="ticket.priority" v-bind:placeholder="$t('priority')" disabled/>
            </div>
            <div class="form-group">
              <label>{{$t('status')}}:</label>
              <input type="text" class="form-control" v-model="ticket.status" v-bind:placeholder="$t('status')" disabled/>
            </div>
            <div class="form-group">
              <label>{{$t('summary')}}:</label>
              <input type="text" class="form-control" v-model="ticket.summary" v-bind:placeholder="$t('summary')" disabled/>
            </div>
            <div class="form-group">
              <label>{{$t('summary_short')}}:</label>
              <input type="text" class="form-control" v-model="ticket.summary_short" v-bind:placeholder="$t('summary_short')" disabled/>
            </div>
            <div class="form-group">
              <label>{{$t('description')}}:</label>
              <textarea class="form-control" v-model="ticket.description" v-bind:placeholder="$t('description')" disabled/>
            </div>
            <div class="form-group">
              <label>{{$t('creation')}}:</label>
              <input type="datetime" class="form-control" v-model="ticket.dates.create" v-bind:placeholder="$t('creation')" disabled/>
            </div>
          </div>
          <div class="card-footer">
            <router-link :to="{'name': 'tickets'}" class="btn btn-primary">
              <i class="fa fa-arrow-left"></i>
              {{ $t('tickets') }}
            </router-link>
          </div>
        </div>
      </b-overlay>
     </form>
    </div>
</template>

<script>
    import {BFormCheckbox, BOverlay} from 'bootstrap-vue'
    import store from '../../store'
    export default {
        name: "create-charge",
        components: {
          BFormCheckbox,
          BOverlay
        },
        data () {
          return {
            ticket: {
              id: null
            },
            showOverlayTicket: false,
            types: [],
            suppliers: [],
            invoiceTypes: [],
            nextNumber: null,
          }
        },
        mounted () {
            this.loadTicket();
        },
        methods: {
          loadTicket : function() {
              this.showOverlayTicket = true;
              this.$http.get('ticket/'+this.$route.params.ticketId).then((response) => {
                  this.showOverlayTicket = false;
                  this.ticket = response.data;
              });
          },
        }
    }
</script>

<style scoped>

</style>
