<template>
    <div>
      <div class="container-fluid">
      <h3>{{ $t('support') }}</h3>
      <hr />
      <span class="label label-info">{{ $t('in_waiting') }}</span>
      <span class="label label-success">{{ $t('close') }}</span>
      <br />&nbsp;
      <div class="card">
        <table class="table table-bordered table-hover">
          <thead>
          <tr style="text-align: center">
            <th>#</th>
            <th>{{ $t('user') }}</th>
            <th>{{ $t('level') }}</th>
            <th>{{ $t('type') }}</th>
            <th>{{ $t('summary') }}</th>
            <th>{{ $t('date') }}</th>
            <th>{{ $t('close') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td colspan="10"><a href="javascript:void(0);" data-toggle="modal" data-target="#formSupport"><span class="glyphicon glyphicon-plus"></span> Ajouter un support</a></td>
          </tr>
          <tr v-for="support in supports" v-bind:class="support.date.close == null ? 'warning' : ''">
            <td>{{support.mapping.support_id}}</td>
            <td>{{support.user}}</td>
            <td>{{ $t(support.level)}}</td>
            <td>{{ $t(support.type) }}</td>
            <td>{{support.summary}}</td>
            <td>{{support.date.create}}</td>
            <td>{{ $t('in_waiting') }}...</td>
            <td>
              <div class="btn-group">
                <a href="javascript:void(0);" class="btn btn-primary btn-xs">
                  <span class="glyphicon glyphicon-edit"></span> &nbsp; {{ $t('edit') }}
                </a>
                <button type="button" class="btn btn-primary btn-xs dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                  <span class="caret"></span>
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu dropup" role="menu">
                  <li><a href="?p=setDelete&sid=104" style="color:#f00">{{ $t('delete') }}</a></li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>
</template>

<script>

  export default {
    name: 'support',
    data () {
      return {
        supports: [],
      }
    },
    mounted () {
      this.loadTimeline();
    },
    methods: {
      loadTimeline : function () {
        this.$http.get('supports').then((response) => {
          this.supports = response.data;
        });
      }
    }
  }
</script>
