<template>
  <div>
    <b-overlay :show="showOverlayCustomerCharges" rounded="sm">
      <div class="card">
        <div class="card-header">
          <router-link :to="{name: 'create-customer-charge', params: {customerId: customerId}}" class="btn btn-info">
            <i class="fa fa-plus"></i> {{$t('create_charge')}}
          </router-link>
        </div>
        <table class="table table-bordered table-striped table-hover">
          <thead>
          <tr>
            <th>Payer</th>
            <th>Fournisseur</th>
            <th>N°</th>
            <th>Date</th>
            <th>Document</th>
            <th>T.V.A.C</th>
            <th>H.T.V.A.</th>
            <th>T.V.A. *</th>
            <th style="text-align: center"><i class="fa fa-edit"></i></th>
          </tr>
          </thead>
          <tbody>
            <tr v-if="charges.length===0"><td colspan="10">Aucune dépense</td></tr>
            <tr v-for="charge in charges">
              <td>
                <router-link :to="{name:'payments-target', params:{target:'charge', targetId: charge.id}}" class="btn btn-default btn-xs">
                  {{charge.amount.payed_pc}} %
                </router-link>
              </td>
              <td><a href="javascript:void(0);">{{charge.supplier.name}}</a></td>
              <td>{{charge.number}}</td>
              <td>{{charge.date }}</td>
              <td>{{charge.nb.files}}</td>
              <td>
                <money-format :value="charge.amount.amount_incl"
                              locale='en'
                              :currency-code="charge.currency.name"
                              :subunit-value=true
                              :hide-subunits=false>
                </money-format>
              </td>
              <td>
                <money-format :value="charge.amount.amount"
                              locale='en'
                              :currency-code="charge.currency.name"
                              :subunit-value=true
                              :hide-subunits=false>
                </money-format>
              </td>
              <td>
                <money-format :value="charge.amount.amount_tax"
                              locale='en'
                              :currency-code="charge.currency.name"
                              :subunit-value=true
                              :hide-subunits=false>
                </money-format>
              </td>
              <td style="text-align: center">
                <router-link :to="{name: 'charge', params: {'chargeId': charge.id}}" class="btn btn-xs btn-primary">
                  <i class="fa fa-edit"></i>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-overlay>
    <b-pagination
      v-model="currentPage"
      :number-of-pages="nbPages"
      :per-page="perPage"
      :total-rows="rows"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
      @change="loadPaginationCustomerCharges"
    ></b-pagination>
  </div>
</template>

<script>
    import {BOverlay, BPagination} from 'bootstrap-vue'
    import MoneyFormat from 'vue-money-format'
    export default {
      name: "customer-table-charge",
      components: {
          'money-format': MoneyFormat,
          'b-pagination':BPagination,
          'b-overlay': BOverlay
      },
      data () {
        return {
          charges: [],
          currentPage: 1,
          nbPages: 1,
          perPage: 1,
          rows: 0,
          showOverlayCustomerCharges: false,
        }
      },
      props: {
          customerId: {
              type: String,
              required: true,
          },
      },
      mounted () {
        this.loadCharges();
      },
      methods: {
        loadPaginationCustomerCharges: function (pageNum) {
            this.showOverlayCustomerCharges = true;
            this.currentPage = pageNum;
            this.$router.push({name: 'customer-charges', params: {customerId: this.customerId}, query: {page: this.currentPage}});
            this.loadCharges();
        },
          loadCharges : function () {
            if (typeof this.customerId !== 'undefined' && this.customerId) {
                this.showOverlayCustomerCharges = true;
                this.$http.get('customer/' + this.customerId + '/charges?page=' + this.currentPage).then((response) => {
                    this.charges = response.data.items;
                    this.rows = response.data.pagination.result;
                    this.perPage = response.data.pagination.by_page;
                    this.nbPages = response.data.pagination.pages;
                    this.showOverlayCustomerCharges = false;
                });
            }
        },
      }
    }
</script>

<style scoped>

</style>
