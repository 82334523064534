<template>
    <div>
      <div class="list-group">
        <router-link :to="{name : 'settings'}" :class="classListGroup('settings')">
          <h4 class="list-group-item-heading">
            <i class="fa fa-home"></i>
            {{ $t('company_information') }}
          </h4>
          <p class="list-group-item-text"></p>
        </router-link>
        <router-link :to="{name : 'settings-invoice'}" :class="classListGroup('settings-invoice')">
          <h4 class="list-group-item-heading">
            {{ $t('invoice') }}
          </h4>
          <p class="list-group-item-text"></p>
        </router-link>
        <router-link :to="{name: 'settings-paymode'}" :class="classListGroup('settings-paymode')">
          <h4 class="list-group-item-heading">
            <i class="fa fa-money"></i>
            {{ $t('paymodes') }}
          </h4>
          <p class="list-group-item-text"></p>
        </router-link>
        <router-link :to="{name: 'settings-modules'}" :class="classListGroup('settings-modules')">
          <h4 class="list-group-item-heading">
            {{ $t('module') }}
          </h4>
          <p class="list-group-item-text"></p>
        </router-link>
        <router-link :to="{name: 'settings-taxes'}" :class="classListGroup('settings-taxes')">
          <h4 class="list-group-item-heading">
            {{ $t('taxe') }}
          </h4>
          <p class="list-group-item-text"></p>
        </router-link>
        <router-link :to="{name: 'settings-shops'}" :class="classListGroup('settings-shops')">
          <h4 class="list-group-item-heading">
            {{ $t('shops') }}
          </h4>
          <p class="list-group-item-text"></p>
        </router-link>
        <router-link :to="{name: 'settings-till'}" :class="classListGroup('settings-till')">
          <h4 class="list-group-item-heading">
            {{ $t('till') }}
          </h4>
          <p class="list-group-item-text"></p>
        </router-link>
        <router-link :to="{name: 'externals'}" class="list-group-item">
          <h4 class="list-group-item-heading">
            {{ $t('externals') }}
          </h4>
          <p class="list-group-item-text"></p>
        </router-link>
      </div>
    </div>
</template>

<script>
    export default {
        name: "settings-menu",
        methods: {
            classListGroup: function (name) {
                if (this.$router.currentRoute.name === name) {
                    return 'list-group-item active';
                }
                return 'list-group-item';
            }
        }
    }
</script>

<style scoped>

</style>
