<template>
    <div>
      <b-overlay :show="showOverlayTwoFactor" rounded="sm">
        <form class="form" method="post" v-on:submit.prevent="checkCode">
          <div v-if="twoFactor === false" class="form-group">
            <img :src="qrCodeUrl" class="img-thumbnail" />
          </div>
          <div  v-if="twoFactor === false" class="form-group">
            <label for="2-fact-code">{{$t('code')}}</label>
            <input type="text" id="2-fact-code" class="form-control" v-model="code" :placeholder="$t('code')" required>
          </div>
          <button v-if="twoFactor === false" type="submit" class="btn btn-info pull-right">
            <i class="fa fa-save"></i> {{$t('confirm_the_code')}}
          </button>
          <button v-if="twoFactor === true" type="submit" class="btn btn-danger pull-right">
            <i class="fa fa-trash"></i> {{$t('revoke')}}
          </button>
        </form>
      </b-overlay>
    </div>
</template>

<script>
  import {BOverlay} from 'bootstrap-vue'
  export default {
    name: "user-two-factor-authentication",
    components: {
        'b-overlay': BOverlay
    },
    data () {
      return {
        password: {},
        showOverlayTwoFactor: false,
        userId: null,
        twoFactor: false,
        qrCodeUrl: null,
        code: null
      }
    },
    mounted (){
        this.twoFactor = this.$attrs.twoFactor;
        this.userId = this.$attrs.user;
        this.getUrlQrCode();
    },
    methods: {
        loadUser: function () {
          this.getUrlQrCode();
        },
        getUrlQrCode: function() {
            if (this.userId) {
                this.showOverlayTwoFactor = true;
                this.$http.get('user/' + this.userId + '/2fa').then((response) => {
                    this.showOverlayTwoFactor = false;
                    if (response.ok === true && typeof response.data === 'object' && typeof response.data[0].url === 'string') {
                        this.qrCodeUrl = response.data[0].url;
                    }
                }).catch((response) => {
                    this.showOverlayTwoFactor = false;
                });
            }
        },
        checkCode : function () {
          if (this.userId) {
              this.showOverlayTwoFactor = true;
              if (this.twoFactor === false && this.code) {
                  this.$http.post('user/' + this.userId + '/2fa', {
                      code: this.code
                  }).then((response) => {
                      if (response.ok === true) {
                          this.twoFactor = response.data.success;
                      }
                      this.showOverlayTwoFactor = false;
                  }).catch((response) => {
                      this.showOverlayTwoFactor = false;
                  });
              } else if (this.twoFactor === true) {
                  this.$http.delete('user/' + this.userId + '/2fa').then((response) => {
                      if (response.ok === true) {
                          this.twoFactor = !response.data.success;
                      }
                      this.showOverlayTwoFactor = false;
                  }).catch((response) => {
                      this.showOverlayTwoFactor = false;
                  });
              }
          }
        }
    },
    computed: {
        userName: function () {
            if (typeof this.$parent.getUser === 'function' && this.$parent.getUser()) {
                let user = this.$parent.getUser();
                return user.first_name + ' ' + user.last_name;
            }
            return null;
        },
    }
  }
</script>

