<template>
  <div class="container">
    <form id="formAddInvoice" class="form" method="post" v-on:submit.prevent="updateEvent">
      <b-overlay :show="showOverlayEvent" rounded="sm">
        <div class="card">
          <div class="card-header">
            <h4 class="modal-title"><i class="fa fa-plus"></i> {{$t('event')}}</h4>
          </div>
          <div class="card-body">
            <div v-if="currentEvent.dates.validate !== null" class="alert alert-info">
              <i class="fa fa-check"></i> Validé, <timeago :datetime="currentEvent.dates.validate">{{currentEvent.dates.validate}}</timeago>
            </div>
            <div v-if="currentEvent.dates.validate === null" class="alert alert-warning">
              Validation en cours..
            </div>
            <div class="form-group">
              <label>{{$t('title')}}:</label>
              <input type='text' class="form-control" v-model="currentEvent.name" v-bind:placeholder="$t('title')" required/>
            </div>
            <div class="form-group">
              <label for="event-date-start">{{$t('begin')}}: </label>
              <input type="date" id="event-date-start" class="form-control" v-model="currentEvent.start.date" v-bind:placeholder="$t('begin')" required/>
              <input type="time" id="event-hour-start" class="form-control" v-model="currentEvent.start.time" v-bind:placeholder="$t('hour')"/>
            </div>
            <div class="form-group">
              <label for="event-date-end">{{$t('end')}}:</label>
              <input type="date" id="event-date-end" class="form-control" v-model="currentEvent.end.date" v-bind:placeholder="$t('begin')" required/>
              <input type="time" id="event-hour-end" class="form-control" v-model="currentEvent.end.time" v-bind:placeholder="$t('hour')"/>
            </div>
            <div class="form-group">
              <label>{{$t('description')}}:</label>
              <textarea type="date" class="form-control" v-model="currentEvent.description" v-bind:placeholder="$t('description')"></textarea>
            </div>
            <div v-if="currentEvent.allow.validate === true && currentEvent.dates.validate === null" class="form-group">
              <label>{{$t('validation')}}:</label>
              <button type="button" class="form-control btn btn-default" @click="validEvent">Valider</button>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-primary">
              <i class="fa fa-save"></i> {{ $t('save') }}
            </button>
            <button v-if="currentEvent.allow.delete === true" type="button" class="btn btn-danger" @click="removeEvent">
              <i class="fa fa-trash"></i> {{ $t('delete') }}
            </button>
            <button type="button" class="btn btn-default" @click="createEvent">
              <i class="fa fa-plus"></i> {{ $t('create_event') }}
            </button>
          </div>
        </div>
      </b-overlay>
    </form>
  </div>
</template>

<script>
    import {BOverlay} from 'bootstrap-vue';
    import { Datetime } from 'vue-datetime'
    import 'vue-datetime/dist/vue-datetime.css'
    import store from '../../store';
    import timeago from 'timeago.js';
    export default {
        name: "update-event",
        components: {
            'datetime': Datetime,
            'b-overlay': BOverlay
        },
        data () {
          return {
            currentEvent: {
              name: null,
              start: {
                  date: null,
                  time: null,
              },
              end: {
                  date: null,
                  time: null,
              },
              description: null,
              dates: {
                  validate: null
              },
              allow: {
                  delete: false,
                  validate: false
              }
            },
            users: [],
            customers: [],
            showOverlayEvent: false,
          }
        },
        mounted () {
            this.loadEvent(this.$route.params.eventId);
        },
        methods: {
          loadUsers : function() {
              this.$http.get('factory/'+store.state.factoryConnected.id+'/users?page=1').then((response) => {
                  this.users = response.data.items;
              });
          },
          loadCustomers : function() {
              this.$http.get('factory/'+store.state.factoryConnected.id+'/customers?page=1').then((response) => {
                  this.customers = response.data.items;
              });
          },
          loadEvent : function(eventId) {
              this.$http.get('event/'+eventId).then((response) => {
                  this.currentEvent = response.data;
                  if (null === this.currentEvent.owner) {
                      this.loadUsers();
                      this.currentEvent.owner = {
                          id: null,
                          name: null,
                      }
                  } else {
                      this.users = [];
                      this.users.push(this.currentEvent.owner);
                  }
                  if (null === this.currentEvent.customer) {
                      this.loadCustomers();
                      this.currentEvent.customer = {
                          id: null,
                          name: null,
                      }
                  } else {
                      this.customers = [];
                      this.customers.push(this.currentEvent.customer);
                  }
              });
          },
          updateEvent : function() {
              if (this.currentEvent.id) {
                  this.showOverlayEvent = true;
                  this.$http.put('event/' + this.currentEvent.id, {
                      title: this.currentEvent.title,
                      start: this.currentEvent.start,
                      end: this.currentEvent.end,
                      description: this.currentEvent.description,
                      owner: this.currentEvent.owner,
                      customer: this.currentEvent.customer,
                  }).then((response) => {
                      this.showOverlayEvent = false;
                      this.loadEvent(response.data.id);
                  }).catch((response) => {
                      this.showOverlayEvent = false;
                  });
              }
          },
          validEvent() {
              if (this.currentEvent && typeof this.currentEvent.id !== 'undefined' && this.currentEvent.id !== null) {
                  this.showOverlayEvent = true;
                  this.$http.put('event/' + this.currentEvent.id+'/validate').then((response) => {
                      this.showOverlayEvent = false;
                  }).catch((response) => {
                      this.showOverlayEvent = false;
                  });
              }
          },
          removeEvent() {
              if (this.currentEvent && typeof this.currentEvent.id !== 'undefined' && this.currentEvent.id !== null) {
                  this.showOverlayEvent = true;
                  this.$http.delete('event/' + this.currentEvent.id).then((response) => {
                      this.showOverlayEvent = false;
                      this.$router.push({name: "events"})
                  }).catch((response) => {
                      this.showOverlayEvent = false;
                  });
              }
          },
          createEvent() {
              this.$router.push({name: "event-new"})
          },
        }
    }
</script>

<style scoped>

</style>
