<template>
  <form ref="form" class="form" method="post">
    <b-modal
      ref="modal"
      id="modal-upload-picture"
      :title="$t('add_picture')"
      @ok="submit">
        <b-overlay :show="showOverlayPicture" rounded="sm">
          <div class="form-group">
            <img :src="picture" style="max-width: 100px;max-height: 100px" class="img-thumbnail" />
          </div>
          <div class="form-group">
            <label>File</label>
            <b-form-file
              v-model="file"
              :state="Boolean(file)"
              :multiple="false"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
            <div class="mt-3">Selected file: {{ file ? file.name : '' }}</div>
          </div>
        </b-overlay>
    </b-modal>
  </form>
</template>
<script>
    import {BOverlay, VBModal, BModal, BFormFile} from 'bootstrap-vue'
    export default {
        name: 'upload-picture',
        components: {
            'b-overlay': BOverlay,
            'b-modal': BModal,
            'b-form-file': BFormFile,
        },
        directives: {
            'b-modal': VBModal
        },
        props: {
          target: String,
          targetId: String,
          picture: String,
          onSuccess: Function
        },
        data () {
            return {
              file : null,
              imgSrcCustom: null,
              showOverlayPicture: false
            }
        },
        methods: {
          submit : function (bvModalEvt) {
            var formData = new FormData();
            formData.append('image', this.file);
            bvModalEvt.preventDefault();
            this.$http.post('picture/upload?target=' + this.target + '&id='+this.targetId, formData).then((response) => {
              if (response.ok === true) {
                this.$refs['modal'].hide();
                if (typeof this.onSuccess === 'function') {
                  this.onSuccess(this.targetId);
                }
              }
            });
          }
        }
    }
</script>
