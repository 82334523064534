<template>
    <div>
      <b-overlay :show="showOverlayAccount" rounded="sm">
        <div class="card">
          <div class="card-header">
            <h4 class="modal-title"><i class="fa fa-plus"></i> Stripe Account</h4>
          </div>
          <div class="card-body">
            <div class="form-group" v-for="item in formItems">
              <label>{{item.title}}: </label>
              <input type="text" id="event-hour-start" class="form-control" v-bind:placeholder="item.title" v-model="item.value"/>
            </div>
          </div>
          <div class="card-footer">
            <button v-if="this.exists === true" type="button" class="btn btn-primary" v-on:click="save">
              <i class="fa fa-save"></i> {{ $t('save') }}
            </button>
            <button v-else type="button" class="btn btn-primary" v-on:click="save">
              <i class="fa fa-plus"></i> {{ $t('add') }}
            </button>
            <button v-if="this.exists === true" type="button" :class="btnPingClass" v-on:click="launchPing">
              <i class="fa fa-refresh"></i> {{ $t('ping') }}
            </button>
          </div>
        </div>
      </b-overlay>
    </div>
</template>

<script>
    import {BOverlay} from 'bootstrap-vue'
    import store from '../../store';
    export default {
        name: "fund",
        components: {
            'b-overlay':BOverlay
        },
        data () {
          return {
            exists : null,
            ping : null,
            formItems: [],
            showOverlayAccount: false,
          }
        },
        mounted () {
          this.loadForm();
        },
        computed : {
            btnPingClass: function () {
                if (this.ping === true) {
                    return 'btn btn-success';
                } else if (this.ping === false) {
                    return 'btn btn-warning';
                } else {
                    return 'btn btn-default';
                }
            }
        },
        methods: {
          loadForm : function () {
            this.showOverlayAccount = true;
            this.$http.get('webservices/stripe/account/factory/'+store.state.factoryConnected.id+'/form').then((response) => {
                this.showOverlayAccount = false;
                this.exists = response.data.exists;
                this.formItems = response.data.form;
            });
          },
          save: function () {
            if (null !== this.exists) {
                var object = new Object();
                for (let d in this.formItems) {
                    object[this.formItems[d].field] = this.formItems[d].value;
                }
                if (this.exists === true) {
                    this.showOverlayAccount = true;
                    this.$http.put('webservices/stripe/account/factory/'+store.state.factoryConnected.id+'/form', object).then((response) => {
                        this.showOverlayAccount = false;
                    });
                } else if (this.exists === false) {
                    this.showOverlayAccount = true;
                    this.$http.post('webservices/stripe/account/factory/'+store.state.factoryConnected.id+'/form', object).then((response) => {
                        this.showOverlayAccount = false;
                    });
                }
            }
          }
        }
    }
</script>
