<template>
  <div>
      <div class="row">
        <div class="col-md-12 col-xs-12">
          <home-toolbar></home-toolbar>
        </div>
        <div class="col-md-3">&nbsp;</div>
        <div class="col-md-5 col-xs-12">
            <home-timeline></home-timeline>
            <home-message></home-message>
        </div>
        <div class="col-md-4 col-xs-12">
          <home-summary></home-summary>
          <home-donut></home-donut>
        </div>
    </div>
  </div>
</template>

<script>
  import HomeTimeline from './HomeTimeline.vue'
  import HomeToolbar from './HomeToolbar.vue'
  import HomeDonut from './HomeDonut.vue'
  import HomeMessage from './HomeMessage.vue'
  import HomeSummary from './HomeSummary.vue'
  import HomeCalendar from './HomeCalendar.vue'

  export default {
    components: {
        HomeTimeline,
        HomeSummary,
        HomeCalendar,
        HomeMessage,
        HomeDonut,
        HomeToolbar
    },
    data () {
      return {
        factoryId: null,
        messages: [],
        birthdays: []
      }
    },
    mounted () {
        this.factoryId = this.$parent.factory.id;
    },
    methods: {
        getFactoryId: function () {
            return this.$parent.factory.id;
        }
    }
  }
</script>
