<template>

</template>

<script>
    export default {
        name: "data-language",
        data () {
          return {

          }
        },
        mounted () {
          this.loadLanguages();
        },
        methods: {
            loadLanguages : function() {
                this.$http.get('languages').then((response) => {
                    if (response.ok === true) {
                        this.languages = response.data;
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>
