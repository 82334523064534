<template>
  <div class="container">
    <div class="row">
      <div id="loader">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="lading"></div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "loading"
    }
</script>

<style src="../assets/css/loading.css" scoped></style>

