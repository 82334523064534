<template>
    <div>
      <b-overlay :show="showOverlayUserSession" rounded="sm">
        <div class="table-responsive">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th><span class="fa fa-trash"></span></th>
                <th>{{$t('device')}}</th>
                <th>{{$t('browser')}}</th>
                <th>{{$t('ip_address')}}</th>
                <th>{{$t('start')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="sessions.length===0"><td colspan="10">{{$t('no_session')}}</td></tr>
              <tr v-for="session in sessions" :class="trClass(session.open)">
                <td>
                  <button v-if="session.open===true" v-on:click="deleteSession(session.id)" type="button" class="btn btn-danger btn-xs"><span class="fa fa-trash"></span></button>
                  <button v-else type="button" class="btn btn-default btn-xs disabled"><span class="fa fa-trash"></span></button>
                </td>
                <td>{{session.device}}</td>
                <td>{{session.browser}}</td>
                <td><span :class="flagClass(session.country)"></span> {{session.ip}}</td>
                <td><timeago class="timeago" v-bind:datetime="session.start">{{session.creation}}</timeago></td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
    </div>
</template>

<script>
  import timeago from 'timeago.js';
  import {BButton, BOverlay} from 'bootstrap-vue'
  export default {
    components: {
        'b-button': BButton,
        'b-overlay': BOverlay
    },
    name: "user-session",
    props: {
        userId: {
            type: String,
            required: false,
        },
    },
    data () {
      return {
        sessions: [],
        interval: null,
        showOverlayUserSession: false
      }
    },
    mounted () {
        this.interval = setInterval(function() {
            if (this.userId !== null) {
                this.loadUser(this.userId);
                clearInterval(this.interval);
            }
        }.bind(this), 1000);
    },
    computed: {

    },
    methods: {
      loadUser : function (userId) {
          this.loadSessions(userId);
      },
      trClass : function(open) {
          if (open === true) {
              return null;
          }
          return 'table-warning';
      },
      flagClass : function(countryCode) {
          if (typeof countryCode === 'undefined' || countryCode === null || countryCode === '') {
              return null;
          }
          return 'flag-icon flag-icon-'+countryCode.toLowerCase();
      },
      loadSessions: function (userId) {
          this.showOverlayUserSession = true;
          this.$http.get('user/' + this.userId + '/sessions').then((response) => {
              this.showOverlayUserSession = false;
              if (response.ok === true) {
                  this.sessions = response.data;
              }
          }).catch((response)=> {
              this.showOverlayUserSession = false;
          });
      },
      deleteSession: function (sessionId) {
          this.showOverlayUserSession = true;
          this.$http.delete('user/' + this.userId + '/session/' + sessionId).then((response) => {
              this.showOverlayUserSession = false;
              if (response.ok === true) {
                  this.loadSessions()
              }
          }).catch((response) => {
              this.showOverlayUserSession = false;
          });
      }
    }
  }
</script>

