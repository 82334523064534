<template>
  <div class="container">
    <form id="formAddCharge" class="form" method="post" v-on:submit.prevent="createCharge">
      <b-overlay :show="showOverlayCreateCharge" rounded="sm">
        <div class="card">
          <div class="card-header">
            <h4 class="modal-title"><i class="fa fa-plus"></i> {{$t('add_charge')}}</h4>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label>{{ $t('customer') }}:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                        <i class="fa fa-user"></i>
                    </span>
                </div>
                <select class="form-control" @change="selectSupplier" v-model="supplier">
                  <option v-for="supplier in suppliers" :value="supplier">{{supplier.name}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
    </form>
  </div>
</template>

<script>
import {BFormCheckbox, BOverlay} from 'bootstrap-vue'
import store from '../../store'
export default {
  name: "create-charge",
  components: {
    BFormCheckbox,
    BOverlay
  },
  data () {
    return {
      showOverlayCreateCharge: false,
      supplier: null,
      suppliers: [],
    }
  },
  created () {
    this.loadSuppliers();
  },
  methods: {
    selectSupplier : function () {
      if (typeof this.supplier.id === 'string') {
        this.$router.push({name: 'create-customer-charge', params: {customerId: this.supplier.id}});
      }
    },
    loadSuppliers : function() {
      this.showOverlayCreateCharge = true;
      this.$http.get('factory/'+store.state.factoryConnected.id+'/suppliers').then((response) => {
        this.suppliers = response.data;
        this.showOverlayCreateCharge = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
