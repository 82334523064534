<template>
  <div>
    <div class="card" v-if="years.length>0">
      <div class="card-body">
        <select class="form-control" id="donut_client_years" v-model="year" v-bind:onchange="chgYear()">
          <option v-for="year in years">{{year}}</option>
        </select>
        <div id="donut_client"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '../../store'
  export default {
    name: 'home-donut',
    data () {
      return {
        years: [],
        year: null,
      }
    },
    mounted () {
      this.loadYears();
      this.loadGraph();
    },
    methods: {
      loadYears : function () {
        this.$http.get('factory/'+store.state.factoryConnected.id+'/graph/client/years').then((response) => {

          this.years = response.data;
        });
      },
      chgYear : function () {
        this.loadGraph(this.year);
      },
      loadGraph : function () {
        if (typeof chart == 'undefined') {
          var chart;
        }
        this.$http.get('factory/'+store.state.factoryConnected.id+'/graph/client/year'+(this.year ? '?year='+this.year : '')).then((response) => {
          try {
            this.year = response.data.year;
            chart = c3.generate({
              bindto: '#donut_client',
              data: {
                json: response.data.data,
                mimeType: 'json',
                type: 'donut',
                onclick: function (d, i) {
                },
                onmouseover: function (d, i) {
                },
                onmouseout: function (d, i) {
                }
              },
              donut: {
                title: "TOP 5 - " + response.data.year
              }
            });
          } catch (e) {
          }
        });
      }
    }
  }
</script>
