<template>
    <div>
      <div class="row">
        <div class="col-md-4">
          <div class="card">
            <div class="card-header"><h4 class="heading">{{current_date}}</h4></div>
            <div class="card-body">
                <form v-on:submit.prevent="changeDate">
                  <div class="input-group">
                    <div class="input-group-btn">
                      <router-link :to="{name: 'fund'}" class="btn btn-default">{{ $t('today') }}</router-link>
                    </div>
                    <input type="date" name="date" class="form-control datepicker" v-model="date">
                    <span class="input-group-btn">
                      <button class="btn btn-primary" type="submit"><i class="fa fa-search"></i></button>
                    </span>
                  </div>
                </form>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h4 class="heading">{{ $t('paymodes') }}</h4>
            </div>
            <table class="table table-hover table-bordered">
              <thead>
              <tr>
                <th>{{ $t('paymode') }}</th>
                <th>{{ $t('charge') }}</th>
                <th>{{ $t('sale') }}</th>
                <th>{{ $t('invoice') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="amounts.length===0"><td colspan="10">{{ $t('anything_for_the_moment') }}...</td></tr>
              <tr v-for="amount in amounts">
                <td>{{$t(amount.mode)}}</td>
                <td>{{amount.charge}}</td>
                <td>{{amount.sale}}</td>
                <td>{{amount.invoice}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-8">
          <ul class="nav nav-pills" id="myTab">
            <li class="nav-item active"><a href="#details" class="nav-link active" data-toggle="tab"><i class="fa fa-shopping-cart"></i> {{ $t('sales_of') }} {{date}}</a></li>
            <li class="nav-item"><a href="#payment" class="nav-link" data-toggle="tab"><i class="fa fa-money"></i> Mouvements d'argents du {{date}}</a></li>
          </ul>
          <hr />
          <div class="tab-content">
            <div class="tab-pane active" id="details">
              <div class="card">
                <div class="card-header">
                  <h4 class="heading">{{ $t('sales_of') }} {{date}}</h4>
                </div>
                <b-overlay :show="showOverlaySales" rounded="sm">
                  <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                      <th>#</th>
                      <th><span class="glyphicon glyphicon-time"></span> &nbsp; {{ $t('hour') }}</th>
                      <th><span class="glyphicon glyphicon-user"></span> &nbsp; {{ $t('customer') }}</th>
                      <th>{{ $t('tva') }} (%)</th>
                      <th>{{ $t('tvac') }}</th>
                      <th>{{ $t('invoice') }} ?</th>
                      <th>{{ $t('payed') }} (%)</th>
                      <th style="text-align: center"><i class="fa fa-trash"></i></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="sales.length===0"><td colspan="10">{{ $t('anything_for_the_moment') }}...</td></tr>
                    <tr v-for="sale in sales" v-bind:class="sale._trClass">
                      <td>
                        <router-link :to="{name: 'sale', params: {saleId: sale.id}}" class="btn btn-xs btn-info"><i class="fa fa-edit"></i></router-link>
                      </td>
                      <td>{{sale.date}}</td>
                      <td>
                        <router-link :to="{name: 'customer', params: {customerId: sale.customer.id}}">{{sale.customer.name}}</router-link>
                      </td>
                      <td>{{sale.vat_rate}} %</td>
                      <td>{{sale.amount}}</td>
                      <td>
                        <router-link :to="{name: 'invoice', params: {invoiceId: sale.invoice.id}}" v-if="sale.invoice && sale.invoice.id"><i class="fa fa-file"></i> {{sale.invoice.number}}</router-link>
                      </td>
                      <td>
                        <router-link :to="{name:'payments-target', params:{target:'invoice', targetId: sale.invoice.id}}" v-if="sale.invoice && sale.invoice.id" class="btn btn-default btn-xs">{{sale.amounts.payed_pc}} %</router-link>
                        <router-link :to="{name:'payments-target', params:{target:'sale', targetId: sale.id}}" v-else class="btn btn-default btn-xs">{{sale.amount.payed_pc}} %</router-link>
                      </td>
                      <td style="text-align: center">
                        <button v-on:click="deleteSale(sale)" class="btn btn-danger btn-xs"><i class="fa fa-trash"></i></button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-overlay>
              </div>
            </div>
            <div class="tab-pane" id="payment">
              <div class="card">
                <div class="card-header">
                  <h4 class="heading">Mouvements d'argents du {{date}}</h4>
                </div>
                <b-overlay :show="showOverlayPayments" rounded="sm">
                  <table class="table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th><i class="fa fa-edit"></i></th>
                        <th>{{ $t('date') }}</th>
                        <th>{{ $t('target') }}</th>
                        <th>{{ $t('amount') }}</th>
                        <th>{{ $t('paymode') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="payments.length===0"><td colspan="10">{{ $t('anything_for_the_moment') }}...</td></tr>
                      <tr v-for="payment in payments" v-bind:class="payment._trClass">
                        <td>
                          <router-link :to="{name:'payments-target', params:{target: payment.target, targetId: payment.target_id}}" class="btn btn-default btn-xs"><i class="fa fa-edit"></i></router-link>
                        </td>
                        <th>{{ $t(payment.target) }}</th>
                        <td>{{payment.date}}</td>
                        <td>{{payment.currency}} {{payment.amount}}</td>
                        <td>{{$t(payment.mode)}}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-overlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    import {BOverlay} from 'bootstrap-vue'
    export default {
        name: "fund",
        components: {
            'b-overlay':BOverlay
        },
        data () {
          return {
            current_date : '',
            amounts: [],
            sales: [],
            payments: [],
            date : null,
            showOverlayPayments: false,
            showOverlaySales: false,
          }
        },
        mounted () {
          let date = new Date();
          let month = (date.getMonth()+1);
          let monthString = month.toString().length === 1  ? '0'+month.toString() : month;
          this.date = date.getFullYear()+'-'+monthString+'-'+date.getDate();
        },
        methods: {
          changeDate : function () {
            if (this.date) {
              this.loadSales(this.date);
              this.loadPayments(this.date);
            }
          },
          loadSales : function (date) {
            this.showOverlaySales = true;
            this.$parent.setApplicationSpinner(true);
            this.$http.get('fund/'+date+'/sales').then((response) => {
              this.showOverlaySales = false;
              this.$parent.setApplicationSpinner(false);
              this.sales = response.data;
            });
          },
          loadPayments : function (date) {
            this.showOverlayPayments = true;
            this.$http.get('fund/'+date+'/payments').then((response) => {
              this.showOverlayPayments = false;
              this.$parent.setApplicationSpinner(false);
              this.payments = response.data;
            });
          },
          deleteSale : function(sale) {
              this.showOverlaySales = true;
              this.$http.delete('sale/'+sale.id).then((response) => {
                  this.showOverlaySales = false;
                  if (response.ok === true) {
                      let index = this.sales.indexOf(sale);
                      this.sales.splice(index, 1);
                  }
              });
          },
        }
    }
</script>
