<template>
    <div>
      <div class="row">
        <div class="col-md-12">
          <h1>{{ $t('company_information') }} <small><i class="fa fa-cog"></i> {{ $t('paymodes') }}</small></h1>
          <hr />
        </div>

        <div class="col-md-3">
          <settings-menu></settings-menu>
        </div>

        <div class="col-md-9">
          <b-overlay :show="showOverlayPaymode" rounded="sm">
            <div class="card">
              <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>{{ $t('paymodes') }}</th>
                    <th>{{ $t('sale') }}</th>
                    <th>{{ $t('charge') }}</th>
                    <th>{{ $t('document') }}</th>
                    <th>{{ $t('cash') }}</th>
                    <th></th>
                  </tr>
                  </thead>
                <tfoot>
                  <tr>
                    <td colspan="5">
                      <select v-model="new_paymode_id" class="form-control">
                        <option value="">{{ $t('paymodes') }}</option>
                        <option v-for="paymode in paymodes" v-bind:value="paymode.id">{{ $t(paymode.name) }}</option>
                      </select>
                    </td>
                    <td><button type="button" class="btn btn-primary" v-on:click="addMode()">{{ $t('add') }}</button></td>
                </tr>
                </tfoot>
                <tbody>
                  <tr v-for="factoryPaymode in factoryPaymodes">
                    <td>{{ $t(factoryPaymode.name) }}</td>
                    <td style="text-align: right">
                      <money-format v-if="factoryPaymode.payments.sales" :value="factoryPaymode.payments.sales"
                                    locale='en'
                                    :currency-code="currency.name"
                                    :subunit-value=true
                                    :hide-subunits=false>
                      </money-format>
                    </td>
                    <td style="text-align: right">
                      <money-format v-if="factoryPaymode.payments.charge" :value="factoryPaymode.payments.charge"
                                    locale='en'
                                    :currency-code="currency.name"
                                    :subunit-value=true
                                    :hide-subunits=false>
                      </money-format>
                    </td>
                    <td style="text-align: right">
                      <money-format v-if="factoryPaymode.payments.document" :value="factoryPaymode.payments.document"
                                    locale='en'
                                    :currency-code="currency.name"
                                    :subunit-value=true
                                    :hide-subunits=false>
                      </money-format>
                    </td>
                    <td style="text-align: center"><i class="fa fa-check" v-if="factoryPaymode.mean.cash"></i></td>
                    <td>
                      <a v-if="factoryPaymode.isDeletable" class="btn btn-xs btn-danger">
                        <i class="fa fa-trash"></i> {{ $t('delete') }}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
</template>

<script>
    import SettingsMenu from "./SettingsMenu";
    import store from '../../store';
    import {BOverlay} from 'bootstrap-vue'

    import MoneyFormat from 'vue-money-format'
    export default {
      components: {
          SettingsMenu,
          'b-overlay':BOverlay,
          'money-format': MoneyFormat,
      },
      name: "settings-paymode",
        data () {
          return {
            paymodes : [],
            factory: {},
            factoryPaymodes: [],
            new_paymode_id: null,
            showOverlayPaymode: false
          }
        },
        computed: {
            currency: function () {
                return store.state.factoryConnected.currency;
            },
        },
        mounted () {
          this.loadFactoryPaymodes();
          this.loadSystemPaymodes();
        },
        methods: {
          loadSystemPaymodes : function () {
              this.$http.get('settings/payment/means').then((response) => {
                  if (response.ok === true) {
                      this.paymodes = response.data;
                  }
              });
          },
          loadFactoryPaymodes: function () {
            this.showOverlayPaymode = true;
            this.$http.get('factory/'+store.state.factoryConnected.id+'/settings/payment/means').then((response) => {
                this.showOverlayPaymode = false;
                if (response.ok === true) {
                    this.factoryPaymodes = response.data;
                }
            }).catch((response)=>{
                this.showOverlayPaymode = false;
            });
          },
          addMode: function () {
              if (this.new_paymode_id !== null) {
                  this.$http.post('factory/'+store.state.factoryConnected.id+'/settings/payment/paymode/' + this.new_paymode_id).then((response) => {
                      if (response.ok === true) {
                          this.loadFactoryPaymodes();
                          this.new_paymode_id = null;
                      }
                  });
              }
          }
        }
    }
</script>
