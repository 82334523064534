<template>
  <div>
    <template>
      <b-breadcrumb :items="items"></b-breadcrumb>
    </template>
    <b-overlay :show="showOverlayCharge" rounded="sm">
      <div class="row">
        <div class="col-md-4">
          <form id="formAddCharge" class="form" method="post" v-on:submit.prevent="updateCharge">
            <b-overlay :show="showOverlayUpdateCharge" rounded="sm">
              <div class="card">
                <div class="card-header">
                  <h4 class="modal-title"><i class="fa fa-file"></i> {{$t('charge')}} n° {{charge.number}}</h4>
                </div>
                <div class="card-body">
                  <div class="form-group">
                    <label>{{$t('suppliers')}}:</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="fa fa-user"></i>
                        </span>
                      </div>
                      <input type="text" class="form-control" v-model="charge.supplier.name" :readonly="true"/>
                      <input type="hidden" v-model="charge.supplier.id"/>
                    </div>
                  </div>
                  <div class="form-group">
                    <b-form-checkbox switch :checked="charge.intra" v-model="charge.intra">{{$t('tax_intra_community')}}</b-form-checkbox>
                  </div>

                  <div class="form-group">
                    <label>{{$t('number')}}:</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="fa fa-file"></i>
                        </span>
                      </div>
                      <input type="text" class="form-control" v-model="charge.number" v-bind:placeholder="$t('number')"/>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{{$t('title')}}:</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="fa fa-text-width"></i>
                        </span>
                      </div>
                      <input type="text" class="form-control" v-model="charge.name" v-bind:placeholder="$t('title')"/>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{{$t('description')}}:</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="fa fa-align-justify"></i>
                        </span>
                      </div>
                      <textarea class="form-control" v-model="charge.description" v-bind:placeholder="$t('description')"></textarea>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{{$t('internal_number')}}:</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="fa fa-barcode"></i>
                        </span>
                      </div>
                      <input type="text" class="form-control" v-model="charge.barcode" v-bind:placeholder="$t('internal_number')" />
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{{$t('date')}}:</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                      <input type='date' class="form-control" v-model="charge.date" v-bind:placeholder="$t('date')" required="true"/>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{{$t('deadline_of_invoice')}}:</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </span>
                      </div>
                      <b-form-input id="input-invalid" :state="isOutDated === true ? false : null" type="date" class="form-control"  v-model="charge.payment.deadline" v-bind:placeholder="$t('deadline_of_invoice')"></b-form-input>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{{$t('bank_account')}}:</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="fa fa-bank"></i>
                        </span>
                      </div>
                      <input type="text" class="form-control" v-model="charge.payment.bank" v-bind:placeholder="$t('bank_account')"/>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{{$t('pay_reference')}}:</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="fa fa-link"></i>
                        </span>
                      </div>
                      <input type="text" class="form-control" v-model="charge.payment.reference" v-bind:placeholder="$t('pay_reference')"/>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">
                    <i class="fa fa-save"></i>
                    {{ $t('save') }}
                  </button>
                  <button type="button" class="btn btn-danger" v-on:click="deleteCharge(charge)">
                    <i class="fa fa-trash"></i>
                    {{ $t('delete') }}
                  </button>
                </div>
              </div>
            </b-overlay>
          </form>
        </div>
        <div class="col-md-8">
          <div class="card">
            <div class="card-header">
              <h4 class="modal-title">{{$t('amounts')}}</h4>
            </div>
            <b-overlay :show="showOverlayAmounts" rounded="sm">
              <b-table striped hover :items="amounts" :fields="fieldsAmounts">
                <template v-slot:cell(id)="data">
                  <button type="button" class="btn btn-info btn-xs" v-on:click="loadAmount(data.item)">
                    <i class="fa fa-edit"></i>
                  </button>
                  <button type="button" class="btn btn-danger btn-xs" v-on:click="deleteAmount(data.item)">
                    <i class="fa fa-trash"></i>
                  </button>
                </template>
                <template v-slot:cell(type)="data">
                  {{data.item.type.name}}
                </template>
                <template v-slot:cell(amount.amount)="data">
                  <money-format v-if="data.value !== null && data.value !== '' && data.value !== 0" :value="data.value"
                      locale='en'
                      :currency-code="charge.currency.name"
                      :subunit-value=true
                      :hide-subunits=false>
                  </money-format>
                </template>
                <template v-slot:cell(amount.amount_incl)="data">
                  <money-format v-if="data.value !== null && data.value !== '' && data.value !== 0" :value="data.value"
                      locale='en'
                      :currency-code="charge.currency.name"
                      :subunit-value=true
                      :hide-subunits=false>
                  </money-format>
                </template>
                <template v-slot:cell(amount.vat_rate)="data">
                  {{ data.value }} %
                </template>
                <template v-slot:cell(amount.deductible)="data">
                  {{ data.value }} %
                </template>
                <template v-slot:cell(description)="data">
                  <span v-b-tooltip.hover :title="data.item.description">{{ data.item.summary }}</span>
                </template>
              </b-table>
            </b-overlay>
            <div class="card-footer">
              <button type="button" class="btn btn-info" v-on:click="addAmount()"><i class="fa fa-plus"></i> {{ $t('add') }}</button>
            </div>
          </div>
          <!-- File -->
          <div class="card">
            <div class="card-header">
              <h4 class="modal-title">{{$t('files')}}</h4>
            </div>
            <b-overlay :show="showOverlayFiles" rounded="sm">
              <b-table striped hover :items="files" :fields="fieldsFiles">
                <template v-slot:cell(id)="data">
                  <button type="button" class="btn btn-info btn-xs" v-on:click="loadAmount(data.item)">
                    <i class="fa fa-edit"></i>
                  </button>
                  <button type="button" class="btn btn-danger btn-xs" v-on:click="deleteAmount(data.item)">
                    <i class="fa fa-trash"></i>
                  </button>
                </template>
                <template v-slot:cell(name)="data">
                  <b-link :href=data.item.url target="alt">{{data.item.name}}</b-link>
                </template>
                <template v-slot:cell(delete)="data">
                  <b-button variant="danger" v-on:click="deleteFile(data.item)"><i class="fa fa-trash"></i></b-button>
                </template>
              </b-table>
            </b-overlay>
            <div class="card-footer">
              <button type="button" class="btn btn-info" v-on:click="addFile()"><i class="fa fa-plus"></i> {{ $t('add') }}</button>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
    <charge-modal-amount-edit ref="modal-amount"></charge-modal-amount-edit>
    <charge-modal-file-edit ref="modal-file"></charge-modal-file-edit>
  </div>
</template>

<script>
  import {BFormCheckbox, BOverlay, BTable, BLink, BButton, BFormInput, BBreadcrumb, VBTooltip, VBModal} from 'bootstrap-vue'
  import MoneyFormat from 'vue-money-format'
  import ChargeModalAmountEdit from "./Modal/AmountEdit";
  import ChargeModalFileEdit from "./Modal/FileEdit";

  export default {
    name: 'charge',
    components: {
      ChargeModalAmountEdit,
      ChargeModalFileEdit,
      BLink,
      BButton,
      BOverlay,
      BTable,
      BFormCheckbox,
      BFormInput,
      BBreadcrumb,
      MoneyFormat
    },
    directives: {
      'b-tooltip': VBTooltip,
      'b-modal': VBModal
    },
    data () {
      return {
        msg: 'Welcome to one charge',
        showOverlayCharge: false,
        showOverlayAmount: false,
        showOverlayAmounts: false,
        showOverlayUpdateCharge: false,
        showOverlayFiles: false,
        amounts: [],
        fieldsAmounts: [
            {
                key: 'id',
                label: 'I.D.',
            },
            {
                key: 'type',
                label: 'Type',
            },
            {
                key: 'description',
                label: 'Description',
            },
            {
                key: 'amount.amount',
                label: 'HT',
            },
            {
                key: 'amount.vat_rate',
                label: 'VAT (%)',
            },
            {
                key: 'amount.amount_incl',
                label: 'TTC',
            },
            {
                key: 'amount.deductible',
                label: 'Deductible',
            }
        ],
        files: [],
        fieldsFiles: [
            {
                key: 'name',
                label: 'Name',
            },
            {
                key: 'delete',
                label: 'delete',
            }
        ],
        charge: {
            id: null,
            supplier: {
                id: null,
                name: null,
            },
            intra: null,
            date: null,
            name: null,
            description: null,
            payment: {
                bank: null,
                reference: null,
                deadline: null
            }
        },
      }
    },
    computed: {
        items: function () {
            return [
                {
                    text: 'Home',
                    to: {name: 'root'}
                },
                {
                    text: 'Charges',
                    to: {name: 'charges'}
                },
                {
                    text: this.charge.name,
                    active: true
                }
            ];
        },
        isOutDated: function () {
            if (!this.charge.payment.deadline) {
                return false;
            }

            return new Date(this.charge.payment.deadline) < new Date();
        }
    },
    mounted() {
        if (this.$route.params.chargeId) {
            this.loadCharge(this.$route.params.chargeId);
        }
    },
    methods: {
        updateCharge: function () {
            if (this.charge.id) {
                this.showOverlayUpdateCharge = true;
                let charge = {
                    intra: this.charge.intra,
                    number: this.charge.number,
                    name: this.charge.name,
                    description: this.charge.description,
                    barcode: this.charge.barcode,
                    date: this.charge.date,
                    payment: {
                        deadline: this.charge.payment.deadline,
                        bank: this.charge.payment.bank,
                        reference: this.charge.payment.reference,
                    }
                };
                this.$http.put('charge/' + this.charge.id, this.charge).then((response) => {
                    if (response.ok === true) {
                        this.charge = response.data;
                    }
                }).catch((response) => {
                    this.showOverlayUpdateCharge = false;
                }).finally(() => this.showOverlayUpdateCharge = false)
            }
        },
        loadCharge : function (chargeId) {
            if (chargeId) {
                this.showOverlayUpdateCharge = true;
                this.$http.get('charge/' + chargeId).then((response) => {
                    if (response.ok === true) {
                        this.charge = response.data;
                        this.loadAmounts(chargeId);
                        this.loadFiles(chargeId);
                    }
                }).catch((response) => {
                    this.showOverlayUpdateCharge = false;
                }).finally(() => this.showOverlayUpdateCharge = false)
            }
        },
        loadFiles : function (chargeId) {
            if (chargeId) {
                this.showOverlayFiles = true;
                this.$http.get('charge/' + chargeId+'/files').then((response) => {
                    if (response.ok === true) {
                        this.files = response.data;
                    }
                }).catch((response) => {
                    this.showOverlayFiles = false;
                }).finally(() => this.showOverlayFiles = false)
            }
        },
        deleteFile : function (file) {
            if (file) {
                this.showOverlayFiles = true;
                this.$http.delete('charge/' + file.charge.id +'/file/'+file.id).then((response) => {
                    if (response.ok === true) {
                        let index = this.files.indexOf(file);
                        this.files.splice(index, 1);
                    }
                }).catch((response) => {
                    this.showOverlayFiles = false;
                }).finally(() => this.showOverlayFiles = false)
            }
        },
        addFile: function () {
            this.$refs['modal-file'].showModal(this, this.charge);
        },
        loadAmounts : function (chargeId) {
            if (chargeId) {
                this.showOverlayAmounts = true;
                this.$http.get('charge/' + chargeId+'/amounts').then((response) => {
                    if (response.ok === true) {
                        this.amounts = response.data;
                    }
                }).catch((response) => {
                    this.showOverlayAmounts = false;
                }).finally(() => this.showOverlayAmounts = false)
            }
        },
        loadAmount : function (amount) {
            this.$refs['modal-amount'].showModal(this, amount.charge, amount);
        },
        addAmount : function () {
            this.$refs['modal-amount'].showModal(this, this.charge, null);
        },
        deleteAmount : function (amount) {
            if (amount) {
                this.showOverlayAmounts = true;
                this.$http.delete('charge/' + amount.charge.id +'/amount/'+amount.id).then((response) => {
                    if (response.ok === true) {
                        let index = this.amounts.indexOf(amount);
                        this.amounts.splice(index, 1);
                    }
                }).catch((response) => {
                    this.showOverlayAmounts = false;
                }).finally(() => this.showOverlayAmounts = false)
            }
        },
        deleteCharge : function (charge) {
            if (charge) {
                this.showOverlayCharge = true;
                this.$http.delete('charge/' + charge.id).then((response) => {
                    if (response.ok === true) {
                        this.$router.push({name: 'charges'});
                    }
                }).catch((response) => {
                    this.showOverlayCharge = false;
                }).finally(() => this.showOverlayCharge = false)
            }
        },
    }
  }
</script>
