<template>
  <div>
    <div class="bootstrap snippet">
      <div class="row">
        <div class="col-md-12">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link :to="{name: 'root'}">{{$t('home')}}</router-link></li>
            <li class="breadcrumb-item">{{$t('search')}}</li>
            <li class="breadcrumb-item">{{$t('customers')}}</li>
            <li class="breadcrumb-item active">{{searchQuery}}</li>
          </ol>
        </div>
        <div class="col-lg-12 col-lg-offset-2">
          <form id="searchClients" v-on:submit.prevent="searchCustomersSubmit">
            <div class="card">
              <div class="card-body p-t-0">
                <div class="input-group">
                  <input type="text" v-model="searchQuery" class="form-control" placeholder="Search" autocomplete="off">
                  <span class="input-group-btn">
                      <button type="submit" class="btn btn-effect-ripple btn-primary"><i class="fa fa-search"></i></button>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-12">
          Results: <b>{{rows}}</b>
        </div>
        <div class="col-md-12">
          <b-overlay :show="showOverlayCustomerSearch" rounded="sm">
            <div class="card">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>{{$t('company')}}</th>
                    <th>{{$t('first_name')}}</th>
                    <th>{{$t('last_name')}}</th>
                    <th>{{$t('city')}}</th>
                    <th>{{$t('create')}}</th>
                    <th><i class="fa fa-edit"></i></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="customer in customers">
                    <td>{{customer.company}}</td>
                    <td>{{customer.first_name}}</td>
                    <td>{{customer.last_name}}</td>
                    <td>{{customer.address.city}}</td>
                    <td><timeago :datetime="customer.dates.createdAt">{{customer.dates.createdAt}}</timeago></td>
                    <td>
                      <router-link :to="{name: 'customer', params:{customerId: customer.id}}" class="btn btn-primary btn-xs">
                        <i class="fa fa-edit"></i>
                      </router-link>
                    </td>
                  </tr>
                  <tr v-if="customers.length === 0">
                    <td colspan="10">No customer</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-overlay>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            first-text="First"
            prev-text="Prev"
            next-text="Next"
            last-text="Last"
            @change="loadPaginationCustomer"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import timeago from 'timeago.js';
  import store from '../../store';
  import {BOverlay, BPagination} from 'bootstrap-vue'

  export default {
    name: 'customer-search',
    components: {
        'b-overlay': BOverlay,
        'b-pagination':BPagination
    },
    data () {
      return {
        customers: [],
        currentPage: 1,
        nbPages: 1,
        perPage: 1,
        rows: 0,
        searchQuery: null,
        showOverlayCustomerSearch: false
      }
    },
    mounted () {
      this.searchQuery = this.$route.params.searchCustomer;
      this.searchCustomersSubmit();
    },
    methods: {
      clearSubmit: function () {
        this.searchQuery = null;
        this.searchCustomersSubmit();
      },
      loadPaginationCustomer: function () {
        this.showOverlayCustomerSearch = true;
        setTimeout(this.loadCustomers.bind(this), 100);
      },
      searchCustomersSubmit: function () {
          this.currentPage = 1;
          this.loadCustomers();
      },
      loadCustomers : function () {
        let query = this.searchQuery;
        if (query && query.trim()) {
            this .showOverlayCustomerSearch = true;
            this.$http.get('factory/'+store.state.factoryConnected.id+'/customers?search='+ query.trim()+"&page="+this.currentPage).then((response)=>{
              this .showOverlayCustomerSearch = false;
                if (response.ok === true) {
                    this.rows = response.data.pagination.result;
                    this.perPage = response.data.pagination.by_page;
                    this.customers = response.data.items;
                }
            }).catch ((response) => {
              this .showOverlayCustomerSearch = false;
                this.customers = [];
            });
            this.$router.push({name: 'customerSearch', params: {searchCustomer: query.trim()}});
        } else {
            this.customers = [];
            this.$router.push({name: 'search'});
        }
      }
    }
  }
</script>

<style scoped>
  .media-main a.pull-left {
    width: 100px;
  }
  .thumb-lg {
    height: 84px;
    width: 84px;
  }
  .media-main .info {
    overflow: hidden;
    color: #000;
  }
  .media-main .info h4 {
    padding-top: 10px;
    margin-bottom: 5px;
  }
  .social-links li a {
    background: #EFF0F4;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #7A7676;
  }
</style>
