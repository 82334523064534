<template>
  <div>
    <template>
      <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
    </template>
    <div class="row">

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <router-link :to="{name: 'documents', query: {'type': type.name}}" :class="btnDocumentTypeClass(type)" v-for="type in types" style="margin-right:5px">
                  <i class="fa fa-file"></i> {{ $t(type.name) }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-2">
                <div class="btn-group" v-if="years.length > 0">
                  <button type="button" class="btn btn-default" v-on:click="switchYear(year)">
                    <i class="fa fa-refresh"></i>
                    <span class="currentYear">{{year}}</span>
                  </button>
                  <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <a v-for="year in years" v-on:click="switchYear(year.date)" class="dropdown-item chgYear">
                      {{year.date}} <span class="badge badge-default badge-pill">{{year.nb}}</span>
                    </a>
                  </ul>
                </div>
                <button type="button" class="btn btn-default disabled" v-else>
                  {{ $t('no_invoice')}}
                </button>
              </div>

              <div class="col-lg-3">
                <form class="form-horizontal" id="SearchInvoice" role="form">
                  <div class="input-group" id="adv-search">
                    <input type="text" class="form-control" name="q" value="" v-bind:placeholder="$t('keywords')" disabled/>
                    <div class="input-group-btn">
                      <button type="button" class="btn btn-primary"><i class="fa fa-search" aria-hidden="true"></i></button>
                    </div>
                  </div>
                </form>
              </div>

              <div class="col-lg-7" style="text-align:right">
                <button class="btn btn-danger disabled"><i class="fa fa-bank"></i> {{ $t('unpayed') }}</button>
                <router-link :to="{name: 'create-invoice'}" class="btn btn-info"><i class="fa fa-plus"></i> {{ $t('create_invoice') }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <b-overlay :show="showOverlayInvoices" rounded="sm">
          <div class="card" id="tabInvoice">
            <div class="table-responsive">
              <table class="table table-condensed table-hover table-bordered">
                <thead class="align-center">
                <tr style="font-weight: bold;text-align: center;">
                  <th>#</th>
                  <th>{{ $t('customer') }}</th>
                  <th>{{ $t('document') }}</th>
                  <th>{{ $t('number:table') }}</th>
                  <th>{{ $t('date') }}</th>
                  <th>{{ $t('amount') }}</th>
                  <th>{{ $t('payed') }}</th>
                  <th>@</th>
                  <th><i class="fa fa-mobile"></i></th>
                  <th><i class="fa fa-fax"></i></th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="invoices.length===0">
                  <td colspan="15">{{ $t('any_invoice') }}...</td>
                </tr>
                <tr v-for="invoice in invoices" v-bind:class="invoice.style.class">
                  <td>
                    <router-link :to="{name: 'document', params: {invoiceId: invoice.id}}" class="btn btn-xs btn-info"><i class="fa fa-edit"></i> {{ $t('open') }}</router-link>
                    <button type="button" class="btn btn-xs btn-danger disabled" v-if="invoice.locked"><i class="fa fa-lock"></i></button>
                    <button type="button" class="btn btn-xs btn-success disabled" v-else><i class="fa fa-unlock"></i></button>
                    <div class="btn-group">
                      <a v-on:click="printInvoice(invoice.id)" target="_alt" class="btn btn-xs btn-default"><i class="fa fa-print"></i></a>
                      <button type="button" class="btn btn-xs btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="sr-only">Toggle Dropdown</span>
                      </button>
                      <ul class="dropdown-menu">
                        <a href="javascript:void(0);" v-on:click="copyInvoice(invoice.id, copy)" v-for="copy in invoice.allow.copy" class="dropdown-item">
                          <i class="fa fa-files-o"></i> {{ $t(copy) }}
                        </a>
                      </ul>
                    </div>
                  </td>
                  <td><router-link :to="{name: 'customer', params: {customerId: invoice.customer.id}}">{{invoice.customer.name}}</router-link></td>
                  <td style="text-align:center"><b>{{ $t(invoice.name) }}</b></td>
                  <td style="text-align:center"><b>{{invoice.number}}</b></td>
                  <td style="text-align:center">{{invoice.date}}</td>
                  <td style="text-align:right">
                    <money-format :value="invoice.amount.amount_incl"
                                  locale='en'
                                  :currency-code="invoice.currency.name"
                                  :subunit-value=true
                                  :hide-subunits=false>
                    </money-format>
                  </td>
                  <td style="text-align:center">{{invoice.amount.payed_pc}} %</td>
                  <td style="text-align:center"><div v-if="invoice.has.email === true" ><i class="fa fa-check"></i></div></td>
                  <td style="text-align:center"><div v-if="invoice.has.mobile === true" ><i class="fa fa-check"></i></div></td>
                  <td style="text-align:center"><div v-if="invoice.has.fax === true" ><i class="fa fa-check"></i></div></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-overlay>
        <b-pagination
          v-model="currentPage"
          :total-rows="invoices.length"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          @change="loadPaginationInvoice"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import {BOverlay, BPagination, BBreadcrumb} from 'bootstrap-vue'
  import MoneyFormat from 'vue-money-format'
  import store from '../../store';
  export default {
    components: {
        'b-overlay':BOverlay,
        'b-pagination':BPagination,
        'b-breadcrumb':BBreadcrumb,
        'money-format': MoneyFormat,
    },
    data () {
      return {
        invoices: [],
        currentPage: 1,
        nbPages: 1,
        perPage: 1,
        years: [],
        types: [],
        year: null,
        showOverlayInvoices: false
      }
    },
    computed: {
        breadcrumbItems: function () {
            return [
                {
                    text: 'Home',
                    to: {name: 'root'}
                },
                {
                    text: 'Invoices',
                    active: true
                },
            ];
        },
    },
    watch:{
      $route (to, from) {
        if (to !== from && typeof to.query.type === 'string') {
          this.loadYears()
        }
      }
    },
    mounted () {
      this.loadTypes()
    },
    methods: {
      getCurrentDocumentType: function () {
        return this.$route.query.type ? this.$route.query.type : 'invoice';
      },
      btnDocumentTypeClass: function (type) {
        return type.name === this.getCurrentDocumentType() ? 'btn btn-default' : 'btn btn-light'
      },
      loadTypes: function () {
        this.$http.get('factory/' + store.state.factoryConnected.id+'/settings/invoice/types').then((response) => {
          if (response.ok === true) {
            this.types = response.data;
            this.loadYears()
          }
        });
      },
      loadYears : function() {
        this.$http.get('factory/'+store.state.factoryConnected.id+'/invoices/years?type='+this.getCurrentDocumentType()).then((response) => {
          this.years = response.data;
          if (this.years.length > 0) {
              this.year = this.years[0].date;
          }
          this.loadInvoices()
        });
      },
      switchYear: function (year) {
          this.year = year;
          this.loadInvoices();
      },
      loadPaginationInvoice: function () {
          this.showOverlayInvoices = true;
          setTimeout(this.loadInvoices.bind(this), 100);
      },
      loadInvoices : function() {
          if (this.year) {
              this.showOverlayInvoices = true;
              this.$parent.setApplicationSpinner(true);
              var invoiceType = this.$route.query.type;
              var invoiceUrl = 'factory/'+store.state.factoryConnected.id+'/documents?';
              invoiceUrl += 'page='+this.currentPage+'&';
              invoiceUrl += 'year=' + this.year+'&';
              if (invoiceType) {
                  invoiceUrl += 'type=' + invoiceType + '&';
              }
              this.$http.get(invoiceUrl).then((response) => {
                  this.showOverlayInvoices = false;
                  this.$parent.setApplicationSpinner(false);
                  this.invoices = response.data.items;
                  this.rows = response.data.pagination.result;
                  this.perPage = response.data.pagination.by_page;
              }).catch((response) => {
                  this.showOverlayInvoices = false;
              });
          }
      },
      printInvoice : function (invoiceId) {
          this.$http.get('invoice/'+invoiceId+'/print').then((response) => {
              var data = response.data;
              if (data.length > 0 && data[0].path) {
                  window.open(data[0].path);
              }
          });
      },
      copyInvoice : function (invoiceId, typeName) {
        this.$http.post('invoice/'+invoiceId+'/copy/'+typeName).then((response) => {
          if (response.ok === true) {
              var invoice = response.data;
              return this.$router.push({
                  name: "invoice",
                  params: {
                      invoiceId: invoice.id
                  }
              });
          }
        });
      },
      loadInvoicesByCustomer : function(customerId) {
        this.$parent.setApplicationSpinner(true);
        this.$http.get('customer/'+customerId+'/invoices').then((response) => {
          this.$parent.setApplicationSpinner(false);
          this.invoices = response.data;
        });
      },
    }
  }
</script>
