<template>
  <div class="row">
    <div class="col-md-6">
      <h1>
        Mes crédits
        <small class="intime_sum_credit">
          {{ factory.credit }}
        </small>
        <small>Cr</small>
      </h1>
    </div>
    <div class="col-md-4">
      <form class="form" v-on:submit.prevent="addCredit">
        <div class="col-lg-10" style="padding:10px 0px">
          <input class="form-control input-lg" type="number" v-model="addCreditAmount" min="5" max="20000" name="amount" placeholder="Entre 5 et 20000 crédits" autocomplete="off"/>
          <em>1 Crédit = $ 0,00</em>
        </div>
        <div class="col-lg-2" style="padding:10px 0px">
          <button type="submit" class="btn btn-success btn-lg">
            <i class="fa fa-plus"></i>
            {{ $t('add')}}
          </button>
        </div>
      </form>
    </div>
    <div class="col-md-12">
      <hr />
    </div>
    <div class="col-md-4">
      <div class="col-md-12" v-if="currentSubscription && currentSubscription.id">
        <form class="quick_search"  v-on:submit.prevent="addSubscription">
          <div class="input-group">
            <select v-model="currentSubscription.unit" class="form-control" >
              <option v-for="month in months"v-bind:value="month.nb">{{ month.nb }} mois {{ currentSubscription.name }} pour {{ currentSubscription.amounts.credit * month.ratio }} Cr</option>
            </select>
            <div class="input-group-btn">
              <button type="submit" class="btn btn-info ">{{ $t('subscribe')}}</button>
            </div>
          </div>
        </form>
        <hr />
      </div>
      <div class="card" style="max-height: 400px;overflow: auto">
        <b-overlay :show="showOverlayHistories" rounded="sm">
          <table class="table table-bordered table-striped table-hover" cellspacing="0" style="text-align: left;">
            <thead>
              <tr>
                <th>{{ $t('begin')}}</th>
                <th>{{ $t('end')}}</th>
                <th>{{ $t('amount')}}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="s in subscriptions">
                <td>{{ s.date_start }}</td>
                <td>{{ s.date_end }}</td>
                <td>{{ s.credit }}</td>
                <td>
                  <button type="button" v-on:click="deleteSubscription(s)" class="btn btn-danger btn-xs">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot v-if="this.subscriptions === 0">
              <tr>
                <td colspan="10">Aucun abonnement pour le moment...</td>
              </tr>
            </tfoot>
          </table>
        </b-overlay>
      </div>
    </div>
    <div class="col-md-8">
      <b-overlay :show="showOverlayCredits" rounded="sm">
        <div class="card" style="max-height: 400px;overflow: auto">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>
                  <a href="javascript:;" v-on:click="loadCredits"><i class="fa fa-refresh"></i></a>
                  {{$t('title')}}
                </th>
                <th>Cr</th>
                <th>{{$t('htva')}}</th>
                <th>{{$t('tvac')}}</th>
                <th>{{$t('date')}}</th>
                <th><i class="fa fa-plus"></i></th>
              </tr>
            </thead>
            <tbody>
              <tr v-bind:class="c._trClass" v-for="c in credits">
                <td>{{$t(c.title)}}</td>
                <td>{{c.amount}}</td>
                <td>{{c.price.amount}}</td>
                <td>{{c.price.amount_incl}}</td>
                <td><timeago :datetime="c.inserted">{{c.inserted}}</timeago></td>
                <td>
                  <a v-if="c.action.payed === true" href="javascript:;" v-bind:href="c.pay.url" target="_alt" class="btn btn-success btn-xs"><i class="fa fa-credit-card"></i></a>
                  <a v-if="c.action.deleted === true" href="javascript:;" v-on:click="deleteCredit(c)" class="btn btn-danger btn-xs"><i class="fa fa-trash"></i></a>
                  <a v-if="c.invoice != null" href="javascript:void(0);" target="_alt" class="btn btn-xs btn-primary"><i class="fa fa-print"></i></a>
                </td>
              </tr>
            </tbody>
            <tfoot v-if="this.credits === 0">
              <tr>
                <td colspan="10">{{$t('no_credit_for_the_moment')}}...</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </b-overlay>
    </div>

    <div class="col-md-12">
      <div class="row">
        <div class="col-xs-12 col-md-4" v-for="x in subscriptionList">
          <div class="card">
            <div class="cnrflash" v-if="x.amount == 20"><div class="cnrflash-inner"><span class="cnrflash-label">MOST<br>POPULAR</span></div></div>
            <div class="card-header"><h3 class="panel-title">{{x.name}}</h3></div>
            <div class="the-price">
              <h1>Cr {{x.amounts.credit}}<span class="subscript">/{{$t('month')}}</span></h1>
              <small>1 {{$t('year')}} = 10 {{$t('months')}}</small>
            </div>
            <table class="table">
              <tbody>
                <tr><td>{{ x .limit.user }} {{$t('users')}} (+{{ x .amounts.user }} Cr / {{$t('user')}} / {{$t('month')}})</td></tr>
                <tr class="active"><td>{{ x .limit.user }} {{$t('customers')}}</td></tr>
                <tr>
                  <td v-if="x .limit.turnover">€{{ x.limit.turnover }} {{$t('turnover')}} / {{$t('month')}}</td>
                  <td v-else>{{$t('unlimited')}}</td>
                </tr>
                <tr class="active"><td>{{ x .limit.storage }}MB Stockage</td></tr>
                <tr>
                  <td v-if="x .limit.support">Support Premium</td>
                  <td v-else>Support standard</td>
                </tr>
                <tr class="active"><td>Extranet</td>
                </tr>
              </tbody>
            </table>
            <div style="text-align: center;padding: 10px;">
              <button type="button" v-on:click="selectSubscription(x)" class="btn btn-success">{{ $t('select') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import timeago from 'timeago.js';
    import {BOverlay} from 'bootstrap-vue'
    import store from '../../store'
    export default {
      name: "Subscription",
      components: {
        'b-overlay':BOverlay
      },
      computed : {
        factory : function () {
          return this.$parent.factory;
        },
      },
      mounted () {
        this.loadCredits();
        this.loadSubscriptions();
        this.loadSubscriptionList();
      },
      data () {
        return {
          subscriptions: [],
          months: [
              {nb: 1, ratio: 1},
              {nb: 2, ratio: 2},
              {nb: 3, ratio: 3},
              {nb: 4, ratio: 4},
              {nb: 5, ratio: 5},
              {nb: 6, ratio: 6},
              {nb: 7, ratio: 7},
              {nb: 8, ratio: 8},
              {nb: 9, ratio: 9},
              {nb: 10, ratio: 10},
              {nb: 12, ratio: 10},
            ],
          subscriptionList: [],
          credits: [],
          addCreditAmount: 0,
          addSubscriptionUnit: 1,
          currentSubscription: {},
          showOverlayHistories: false,
          showOverlayCredits: false
        }
      },
      methods: {
        loadCredits : function () {
          this.showOverlayCredits = true;
          this.$parent.setApplicationSpinner(true);
          this.$http.get('factory/'+store.state.factoryConnected.id+'/credits').then((response)=>{
            this.showOverlayCredits = false;
            this.$parent.setApplicationSpinner(false);
            if (response.ok === true) {
              this.credits = response.data;
            }
          }).catch((response)=>{
              this.showOverlayCredits = false;
          });
        },
        loadSubscriptions : function () {
          this.showOverlayHistories = true;
          this.$parent.setApplicationSpinner(true);
          this.$http.get('factory/'+store.state.factoryConnected.id+'/subscriptions').then((response)=>{
            this.showOverlayHistories = false;
            this.$parent.setApplicationSpinner(false);
            if (response.ok === true) {
              this.subscriptions = response.data;
            }
          });
        },
        loadSubscriptionList : function () {
          this.$http.get('subscriptions').then((response)=>{
            if (response.ok === true) {
              this.subscriptionList = response.data;
            }
          });
        },
        deleteCredit : function (credit) {
          this.$http.delete('credit/'+credit.id).then((response)=>{
            if (response.ok === true) {
              this.loadCredits();
            }
          });
        },
        deleteSubscription : function (subscription) {
          this.$http.delete('subscription/'+subscription.id).then((response)=>{
            if (response.ok === true) {
              this.loadSubscriptions();
              this.$parent.loadFactory();
            }
          });
        },
        addSubscription : function () {
          if (!this.currentSubscription.id || this.currentSubscription.unit <= 0) {
            return null;
          }
          this.$http.post('factory/'+store.state.factoryConnected.id+'/subscription',{subscription: this.currentSubscription.id, unit: this.currentSubscription.unit}).then((response)=>{
            if (response.ok === true) {
              this.loadSubscriptions();
              this.$parent.loadFactory();
            }
          });
        },
        addCredit : function () {
          this.$parent.setApplicationSpinner(true);
          this.$http.post('factory/'+store.state.factoryConnected.id+'/credit/'+this.addCreditAmount).then((response)=>{
            this.$parent.setApplicationSpinner(false);
            if (response.ok === true) {
              this.addCreditAmount = 0;
              this.loadCredits();
            }
          });
        },
        selectSubscription : function(subscription) {
          this.currentSubscription = subscription;
        }
      }
    }
</script>
