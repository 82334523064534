<template>
  <form class="form" method="post">
    <b-modal
      id="modal-invoice-add-discount"
      :title="$t('add_discount')"
      @ok="addDiscountInInvoice">
    <div class="form-group">
      <label>Méthode</label>
      <select class="form-control" v-model="discount.method">
        <option value="pc">Pourcentage</option>
        <option value="amount">Montant</option>
      </select>
    </div>
    <div class="form-group">
      <label>Montant</label>
      <input class="form-control" type="text" v-model="discount.amount" />
    </div>
    </b-modal><!-- /.modal -->
  </form>
</template>
<script>
    import {BModal} from 'bootstrap-vue'
    export default {
        components: {
            'b-modal':BModal,
        },
        data () {
            return {
              discount : {
                  method: null,
                  amount: null,
              }
            }
        },
        methods: {
          addDiscountInInvoice : function () {
            if (this.discount.method === null || this.discount.amount === null) {
                return;
            }
            this.$http.post('invoice/'+this.$parent.invoice.id+'/discount', this.discount).then((response) => {
              if (response.ok === true) {
                if (typeof this.$parent.loadSales === 'function') {
                    this.$parent.loadSales();
                }
                if (typeof this.$parent.loadInvoice === 'function') {
                    this.$parent.loadInvoice(false);
                }
              }
            });
          },
        }
    }
</script>
