<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link :to="{name: 'root'}"><i class="fa fa-home"></i></router-link></li>
          <li class="breadcrumb-item"><router-link :to="{name: 'customers'}">{{$t('customers')}}</router-link></li>
          <li class="breadcrumb-item active">
            <a v-if="!profileMainProgressBar" v-on:click="loadCustomer"><i class="fa fa-refresh"></i></a>
            <a v-else href="javascript:void(0);" ><i class="fa fa-refresh fa-spin fa-fw"></i></a>
            {{ customer.name}}
          </li>
        </ol>
      </div>
      <div v-if="alert.display" class="col-md-12">
        <span v-bind:class="alert.class">{{alert.message}}</span>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2">
                <a href="javascript:void(0);" v-b-modal.modal-upload-picture>
                  <img v-bind:src="customer.picture" style="max-width: 100px;max-height: 100px" class="img-thumbnail" />
                </a>
                <upload-picture :picture="customer.picture" :target="'customer'" :targetId="customer.id" :on-success="loadCustomer"></upload-picture>
              </div>
              <div class="col-md-10">
                <h1>
                  {{ customer.first_name}} {{ customer.last_name}}
                  <small>{{ customer.company}}</small>
                </h1>
                <h6>{{$t('added')}} <timeago class="timeago" v-bind:datetime="customer.dates.createdAt">{{customer.dates.createdAt}}</timeago></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <b-tabs pills>
          <b-tab :title="$t('information')" :active="this.$route.name==='customer'" @click="moveTo('customer')">
            <br />
            <customer-table-information :customer="customer"></customer-table-information>
          </b-tab>
          <b-tab :title="$t('company')" :active="this.$route.name==='customer-company'" @click="moveTo('customer-company')">
            <br />
            <customer-table-company :customer="customer"></customer-table-company>
          </b-tab>
          <b-tab :title="$t('contact')" :active="this.$route.name==='customer-contacts'" @click="moveTo('customer-contacts')">
            <br />
            <customer-table-contact :customer="customer"></customer-table-contact>
          </b-tab>
          <b-tab :title="$t('address')" :active="this.$route.name==='customer-addresses'" @click="moveTo('customer-addresses')">
            <br />
            <customer-table-address :customer="customer"></customer-table-address>
          </b-tab>
          <b-tab :title="$t('sales')" :active="this.$route.name==='customer-sales'" @click="moveTo('customer-sales')">
            <br />
            <customer-table-sales :customer-id="this.$route.params.customerId"></customer-table-sales>
          </b-tab>
          <b-tab :title="$t('charges')" :active="this.$route.name==='customer-charges'" @click="moveTo('customer-charges')">
            <br />
            <customer-table-charge :customer-id="this.$route.params.customerId"></customer-table-charge>
          </b-tab>
          <b-tab :title="$t('invoices')" :active="this.$route.name==='customer-invoices'" @click="moveTo('customer-invoices')">
            <br />
            <customer-table-invoice :customer-id="this.$route.params.customerId"></customer-table-invoice>
          </b-tab>
          <b-tab :title="$t('loyalties')" :active="this.$route.name==='customer-loyalties'" @click="moveTo('customer-loyalties')">
            <br />
            <customer-table-loyalty></customer-table-loyalty>
          </b-tab>
          <b-tab :title="$t('events')" :active="this.$route.name==='customer-events'" @click="moveTo('customer-events')">
            <br />
            <customer-table-event></customer-table-event>
          </b-tab>
          <b-tab :title="$t('documents')" :active="this.$route.name==='customer-documents'" @click="moveTo('customer-documents')">
            <br />
            <customer-table-document></customer-table-document>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
  import UploadPicture from '../Upload/UploadPicture';
  import timeago from 'timeago.js';
  import CustomerTableCharge from "./Table/CustomerTableCharge";
  import CustomerTableInvoice from "./Table/CustomerTableInvoice";
  import CustomerTableLoyalty from "./Table/CustomerTableLoyalty";
  import CustomerTableSales from "./Table/CustomerTableSales";
  import CustomerTableEvent from "./Table/CustomerTableEvent";
  import CustomerTableDocument from "./Table/CustomerTableDocument";
  import CustomerTableInformation from "./Table/CustomerTableInformation";
  import CustomerTableCompany from "./Table/CustomerTableCompany";
  import CustomerTableContact from "./Table/CustomerTableContact";
  import CustomerTableAddress from "./Table/CustomerTableAddress";
  import {VBModal, BModal, BTabs, BTab, BCard} from 'bootstrap-vue'

  export default {
    components: {
      UploadPicture,
      CustomerTableSales,
      CustomerTableLoyalty,
      CustomerTableInvoice,
      CustomerTableCharge,
      CustomerTableEvent,
      CustomerTableDocument,
      CustomerTableInformation,
      CustomerTableCompany,
      CustomerTableContact,
      CustomerTableAddress,
      'b-tabs' : BTabs,
      'b-tab' : BTab,
      'b-card' : BCard,
      'b-modal': BModal
    },
    directives: {
        'b-modal': VBModal
    },
    name: 'customer',
    data () {
      return {
        customerId: 0,
        customer: {
          external_id: null,
          address: {},
          country: {},
          gender: {},
          language: {},
          dates: {},
          amount: {},
          alert: {
              birthday_sms : false,
              birthday_email : false,
          }
        },
        profileMainProgressBar: false,
        alert:{
          display: false,
          class: "",
          message: "",
        }
      }
    },
    mounted () {
      this.customerId = this.$route.params.customerId;
      this.loadCustomer();
    },
    methods: {
      loadCustomer : function () {
        this.profileMainProgressBar = true;
        this.$http.get('customer/'+this.customerId).then((response)=>{
          this.profileMainProgressBar = false;
          if (response.ok === true) {
            this.customer = response.data;
          }
        }).catch((response)=> {
            if (response.status === 403 || response.status === 404) {
                this.$router.push({ name: "customers"});
            }
        });
      },
      setAlertError : function (message) {
        this.alert.display = true;
        this.alert.message = message;
        this.alert.class = "alert alert-danger";
      },
      setAlertSuccess : function (message) {
        this.alert.display = true;
        this.alert.message = message;
        this.alert.class = 'alert alert-success';
      },
      moveTo: function (name) {
          return this.$router.push({name: name, params: {customerId: this.$route.params.customerId}});
      }
    }
  }
</script>

