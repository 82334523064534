<template>
  <div>
    <ul class="media-list">
      <li class="media" v-for="message in messages" v-bind:id="message.pk_message">
        <div class="media-body">
          <div class="media">
            <a class="pull-left" v-bind:href="sales._user_url">
              <img style="max-height: 50px;max-width: 50px" class="media-object img-circle " v-bind:src="message.picture" />
            </a>
            <div class="media-body" >
              <div class="col-md-6">
                <a href="./message.php">{{message.user_fname}} {{message.user_name}}</a>
                <i class="fa fa-hand-o-right"></i>
                {{message.message_content}}
                <br />
                <small class="text-muted">
                  {{message._datetime}}
                  <a class="readMessage" href="javascript:void(0);">
                    <i class="fa fa-remove"></i>
                  </a>
                </small>
              </div>
              <div class="col-md-6">
                <form action="./message.php?p=postMessage" method="post">
                  <div class="input-group">
                    <input type="hidden" name="target_user" v-model="message.pk_user" />
                    <input type="hidden" name="file" value="" />
                    <input type="text" class="form-control input-sm" name="message" placeholder="Message...." />
                    <span class="input-group-btn">
                       <button class="btn btn-info btn-sm" type="submit"><i class="fa fa-forward"></i></button>
                     </span>
                  </div>
                </form>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'home-message',
    data () {
      return {
        messages: [],
      }
    },
    mounted () {
        this.loadMessage();
    },
    methods: {
      loadMessage : function () {
        /*
        this.$http.get('ajax/timelineSales').then((response) => {
          this.listTimeline = response.data;
        });
        */
      }
    }
  }
</script>
