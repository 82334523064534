<template>
    <div>
      <div class="card" v-if="birthdays.length > 0">
        <div class="card-body">
          <router-link :to="{name: 'supports'}" class="btn btn-danger" v-if="supportInWaiting>0"><i class="fa fa-bug"></i> {{supportInWaiting}} supports en attentes</router-link>
          <h6 v-for="birthday in birthdays">
            <a v-bind:href="birthday._url"><i class="fa fa-gift" style="color:red"></i> {{birthday._name}}</a>
            <span v-if="birthday._spec===1" style="color:red"> a {{birthday._aged}} ans aujourd'hui</span>
            <span v-if="birthday._spec===-1" style="color:green">aura {{birthday._aged}} ans  le même jour que toi</span>
            <span v-if="birthday._spec===0"> aura {{birthday._aged}} ans  le <a href="javascript:void(0);">{{birthday._day}} {{birthday._month}}</a></span>
          </h6>
        </div>
      </div>
    </div>
</template>

<script>
  import store from '../../store'
  export default {
    name: 'home-summary',
    data () {
      return {
        birthdays: [],
        supportInWaiting: 0
      }
    },
    mounted () {
      this.loadBirthdays();
      this.supportInWaiting = this.$parent.$parent.info.supportInWaiting;
    },
    methods: {
      loadBirthdays : function () {
        this.$http.get('factory/'+store.state.factoryConnected.id+'/customers/birthdays').then((response) => {

          this.birthdays = response.data;
        });
      }
    }
  }
</script>
