<template>
  <div class="container">
    <form id="formAddInvoice" class="form" method="post" v-on:submit.prevent="createInvoice">
      <b-overlay :show="showOverlayNewInvoice" rounded="sm">
        <div class="card">
          <div class="card-header">
            <h4 class="modal-title"><i class="fa fa-plus"></i> {{$t('create_invoice')}}</h4>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label>{{ $t('customer') }}:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                        <i class="fa fa-user"></i>
                    </span>
                </div>
                <select class="form-control" @change="selectCustomer" v-model="customer">
                  <option v-for="customer in customers" :value="customer">{{customer.name}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
    </form>
  </div>
</template>

<script>
    import {BOverlay} from 'bootstrap-vue'
    import store from '../../store'

    export default {
        name: "create-invoice",
        components: {
            'b-overlay':BOverlay
        },
        data () {
          return {
            showOverlayNewInvoice: false,
            customer: null,
            customers: [],
          }
        },
        mounted () {
          this.loadCustomers();
        },
        methods: {
          selectCustomer : function () {
            if (typeof this.customer.id === 'string') {
              this.$router.push({name: 'create-customer-invoice', params: {customerId: this.customer.id}});
            }
          },
          loadCustomers : function () {
              this.showOverlayNewInvoice = true;
              this.$http.get('factory/'+store.state.factoryConnected.id+'/customers').then((response)=>{
                this.showOverlayNewInvoice = false;
                  if (response.ok === true) {
                      this.customers = response.data;
                  }
              });
          }
        }
    }
</script>

<style scoped>

</style>
