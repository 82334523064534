<template>
  <div>
    <form v-on:submit.prevent="submitStock">
      <div class="card">
        <div class="card-header">
          <h3 class="title" v-if="currentStock.id">{{ $t('stock_element')}} n°{{ currentStock.id}}</h3>
          <h3 class="title" v-else>{{ $t('new_stock_element')}}</h3>
        </div>
        <div class="card-body">
          <div class="form-group" v-if="!currentStock.id">
            <label>{{ $t('quantity')}}</label>
            <input type="number" class="form-control" v-model="quantity" v-bind:placeholder="$t('quantity')" min="1">
          </div>
          <div class="form-group" v-if="quantity === 1 || quantity === '1'">
            <label>{{ $t('serial')}}</label>
            <input type="text" class="form-control" v-model="currentStock.serial" v-bind:placeholder="$t('serial')">
          </div>
          <div class="form-group">
            <label>{{ $t('comment')}}</label>
            <textarea type="text" class="form-control" v-model="currentStock.comment" v-bind:placeholder="$t('comment')"></textarea>
          </div>
          <div class="form-group">
            <label>{{ $t('date_in')}}</label>
            <input type="date" class="form-control" v-model="currentStock.date.in" v-bind:placeholder="$t('date_in')">
          </div>
          <div class="form-group">
            <label>{{ $t('date_out')}}</label>
            <input type="date" class="form-control" v-model="currentStock.date.out" v-bind:placeholder="$t('date_out')">
          </div>
          <div class="form-group">
            <label>{{ $t('date_limit')}}</label>
            <input type="date" class="form-control" v-model="currentStock.date.deadline" v-bind:placeholder="$t('date_limit')">
          </div>
          <div class="form-group">
            <label>{{ $t('nb_piece')}}</label>
            <input type="number" class="form-control" v-model="currentStock.piece" v-bind:placeholder="$t('nb_piece')">
          </div>
        </div>
        <div class="card-footer">
          <button type="submit" class="btn btn-primary" v-if="currentStock.id"><i class="fa fa-save"></i> {{ $t('save')}}</button>
          <button type="submit" class="btn btn-primary" v-else><i class="fa fa-plus"></i> {{ $t('save')}}</button>
          <button type="button" class="btn btn-default" @click="reset"><i class="fa fa-eraser"></i></button>
        </div>
      </div>
    </form>
    <div class="card">
      <b-overlay :show="showOverlayStocks" rounded="sm">
        <table class="table table-hover table-bordered">
          <thead>
            <tr>
              <td>#</td>
              <td>{{ $t('serial_number') }}</td>
              <td>{{ $t('place') }}</td>
              <td>{{ $t('date_in') }}</td>
              <td>{{ $t('date_out') }}</td>
              <td>{{ $t('date_limit') }}</td>
              <td>{{ $t('nb_piece') }}</td>
              <td>{{ $t('comment') }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-if="stocks.length === 0">
              <td colspan="10">{{ $t('no_stock') }}</td>
            </tr>
            <tr v-for="stock in stocks">
              <td>
                <button type="button" v-on:click="selectStock(stock)" class="btn btn-default btn-xs">
                  <i class="fa fa-edit"></i> {{$t('edit')}}
                </button>
                <button type="button" v-on:click="deleteStock(stock)" class="btn btn-danger btn-xs">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
              <td>{{ stock.serial }}</td>
              <td>{{ stock.place }}</td>
              <td>{{ stock.date.in }}</td>
              <td>{{ stock.date.out }}</td>
              <td>{{ stock.date.deadline }}</td>
              <td>{{ stock.piece }}</td>
              <td>{{ stock.comment ? 'Y' : ''}}</td>
            </tr>
          </tbody>
        </table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          @change="loadPaginationStocks"
        ></b-pagination>
      </b-overlay>
    </div>
  </div>
</template>

<script>
  import store from '../../store';
  import {BOverlay, BPagination, BTab, BTabs} from 'bootstrap-vue'
  export default {
    components: {
        BOverlay,
        BPagination,
        'b-tabs' : BTabs,
        'b-tab' : BTab,
    },
    name: 'article-stock',
    data () {
      return {
        currentPage: 1,
        nbPages: 1,
        perPage: 1,
        rows:0,
        showOverlayStocks: false,
        showOverlayStocksOut: false,
        quantity: 1,
        categories: [],
        availableSuppliers : [],
        stocks: [],
        stocksOut: [],
        suppliers: [],
        currentStock: {
            serial: null,
            piece: 1,
            comment: null,
            date: {
              in: null,
              out: null,
              deadline: null,
            }
        },
        progressBarSales: 0,
      }
    },
    props: {
        article: {
            type: String,
            required: true,
        },
        loadStocksOut: {
          type: Function,
          required: true,
        }
    },
    mounted () {
      this.loadAvailableSuppliers();
      this.interval = setInterval(function() {
          if (this.article) {
              this.loadStocks();
              clearInterval(this.interval);
          }
      }.bind(this), 1000);
    },
    methods : {
      reset: function () {
          this.currentStock = {
              serial: null,
              date: {
                  in: null,
                  out: null,
                  deadline: null,
              }
          };
      },
      loadAvailableSuppliers : function () {
          this.$http.get('factory/'+store.state.factoryConnected.id+'/suppliers').then((response) => {
              this.availableSuppliers = response.data;
          });
      },
      loadPaginationStocks: function () {
          this.showOverlayStocks = true;
          setTimeout(this.loadStocks.bind(this), 100);
      },
      loadStocks : function (page) {
        this.showOverlayStocks = true;
        this.$http.get('article/'+this.article+'/stocks?page='+(this.currentPage)).then((response) => {
          this.showOverlayStocks = false;
          if (response.ok === true) {
            this.stocks = response.data.items;
            this.rows = response.data.pagination.result;
            this.perPage = response.data.pagination.by_page;
          }
        });
      },
      selectStock : function (stock) {
        this.currentStock = stock;
      },
      submitStock: function () {
          if (this.currentStock && this.currentStock.id) {
              this.updateStock();
          } else {
              this.addStock();
          }
      },
      updateStock : function() {
          this.showOverlayStocks = true;
          this.$http.put('stock/'+this.currentStock.id, this.currentStock).then((response) => {
            this.showOverlayStocks = false;
            if (response.ok === true) {
              this.loadStocks();
            }
          });
      },
      addStock : function() {
        this.showOverlayStocks = true;
        this.$http.post('article/'+this.article+'/stock', {
            quantity: this.quantity,
            serial: this.currentStock.serial,
            date: {
                in: this.currentStock.date.in,
                out: this.currentStock.date.out,
                deadline: this.currentStock.date.deadline,
            },
        }).then((response) => {
          this.showOverlayStocks = false;
          if (response.ok === true) {
            this.loadStocks();
          }
        });
      },
      deleteStock : function(stock) {
        if (stock.id) {
          this.showOverlayStocks = true;
          this.$http.delete('stock/'+stock.id).then((response) => {
            this.showOverlayStocks = false;
            if (response.ok === true) {
                let index = this.stocks.indexOf(stock);
                this.stocks.splice(index, 1);
                this.loadStocks();
                this.loadStocksOut();
            }
          });
        }
      },
    }
  }
</script>
