<template>
  <div>
    <button
      v-for="article in articles"
      class="btn btn-default btn-lg btn-style"
      v-on:click="addArticle(article)"
      :disabled="caddyItemsLoader || article.nb.stock === 0">
      {{article.name}}
    </button>
    <span v-if="articles.length===0">No article...</span>
  </div>
</template>

<script>
    import MoneyFormat from 'vue-money-format'
    export default {
      components: {
          'money-format': MoneyFormat,
      },
      name: "pos-tab-article",
      data () {
        return {
          articles: [],
        }
      },
      props: {
          tillId: {
              type: String,
              required: false,
          },
          isCaddyItemsLoader: {
              type: Function,
              required: true,
          },
          setCaddyItemsLoader: {
              type: Function,
              required: true,
          },
          loadTill: {
              type: Function,
              required: true,
          },
      },
      computed: {
          caddyItemsLoader: function () {
              return this.isCaddyItemsLoader() === true
          }
      },
      methods: {
        loadArticles (category) {
          this.$http.get('pos/till/'+this.tillId+'/category/'+category.id+'/articles').then((response) => {
            if (response.ok === true) {
              this.articles = response.data;
            }
          });
        },
        addArticle : function (article) {
          this.setCaddyItemsLoader(true);
          this.$http.post('pos/till/'+this.tillId+'/article/'+article.id).then((response) => {
            this.setCaddyItemsLoader(false);
            if (response.ok === true) {
              this.loadTill();
            }
          });
        }
      }
    }
</script>

<style scoped>
  .btn-style {
    width:150px;
    height:150px;
    margin:5px;
  }
</style>
