<template>
  <form class="form" method="post">
    <b-modal
      ref="modal"
      id="modal-charge-amount-edit"
      :title="$t('amount')"
      @ok="submit">
    <div class="form-group">
      <label>Type</label>
      <select class="form-control" v-model="amount.type.id" required>
        <option v-for="type in types" :value="type.id">{{$t(type.name)}}</option>
      </select>
    </div>
    <div class="form-group">
      <label>Description</label>
      <textarea class="form-control" v-model="amount.description" required></textarea>
    </div>
    <div class="form-group">
      <label>Montant HT</label>
      <input class="form-control" type="text" v-model="amount.amount.amount" required/>
    </div>
    <div class="form-group">
      <label>Taux T.V.A.</label>
      <select class="form-control" v-model="amount.amount.vat_rate" required>
        <option v-for="vat_rate in vat_rates" :value="vat_rate.rate">{{vat_rate.rate}} %</option>
      </select>
    </div>
    <div class="form-group">
      <label>Montant TTC</label>
      <input class="form-control" type="text" v-model="amountIncl" disabled/>
    </div>
    <div class="form-group">
      <label>Déductible</label>
      <input class="form-control" type="number" v-model="amount.amount.deductible" min="0" max="100"/>
    </div>
    </b-modal><!-- /.modal -->
  </form>
</template>
<script>
    import {BModal} from 'bootstrap-vue'
    import store from '../../../store'
    export default {
        name: 'charge-modal-amount-edit',
        components: {
            'b-modal':BModal,
        },
        data () {
            return {
                main: null,
                types: [],
                vat_rates: [],
                charge: {
                    id: null,
                    name: null,
                },
                amount: {
                    id: null,
                    type: {
                        id: null,
                        name: null,
                    },
                    description: null,
                    method: null,
                    amount: {
                        amount: null,
                        vat_rate: null,
                        deductible: 100,
                    }
                },
            }
        },
        mounted() {
          this.loadTypes();
          this.loadVatRates();
        },
        computed: {
            amountIncl: function () {
                if (null === this.amount.amount.amount || null === this.amount.amount.vat_rate) {
                    return null;
                }
                return this.amount.amount.amount * (1+(this.amount.amount.vat_rate/100));
            }
        },
        methods: {
          resetAmount: function () {
              this.amount = {
                  id: null,
                  description: null,
                  method: null,
                  type: {
                      id: null,
                      name: null,
                  },
                  amount: {
                      amount: null,
                      vat_rate: null,
                      deductible: 100,
                  }
              };
          },
          loadTypes: function () {
              this.$http.get('factory/' + store.state.factoryConnected.id+'/settings/charge/types').then((response) => {
                  if (response.ok === true) {
                      this.types = response.data;
                  }
              });
          },
          loadVatRates: function () {
              this.$http.get('factory/' + store.state.factoryConnected.id+'/settings/tax/vat_rate').then((response) => {
                  if (response.ok === true) {
                      this.vat_rates = response.data;
                  }
              });
          },
          showModal: function (main, charge, amount) {
              this.main = main;
              this.charge = charge;
              if (null === amount) {
                  this.resetAmount();
              } else {
                  this.amount = amount;
              }
              this.$refs['modal'].show();
          },
          submit : function (bvModalEvt) {
            bvModalEvt.preventDefault();
            var amount = {
                type: {
                    id: this.amount.type.id,
                },
                description: this.amount.description,
                amount: {
                    amount: this.amount.amount.amount,
                    vat_rate: this.amount.amount.vat_rate,
                    deductible: this.amount.amount.deductible,
                }
            };
            console.error('amount', amount);
            if (this.amount.id) {
                this.$http.put('charge/' + this.amount.charge.id + '/amount/' + this.amount.id, amount).then((response) => {
                    if (response.ok === true) {
                        this.amount = response.data;
                        if (typeof this.main.loadAmounts === 'function') {
                            this.main.loadAmounts(this.amount.charge.id);
                        }
                        this.$refs['modal'].hide();
                    }
                });
            } else {
                this.$http.post('charge/' + this.charge.id + '/amount', amount).then((response) => {
                    if (response.ok === true) {
                        this.amount = response.data;
                        if (typeof this.main.loadAmounts === 'function') {
                            this.main.loadAmounts(this.charge.id);
                        }
                        this.$refs['modal'].hide();
                    }
                });
            }
          },
        }
    }
</script>
