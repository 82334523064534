<template>
    <div>
      <div class="card">
        <table class="table table-striped">
          <thead>
            <tr><th>Titre</th><th>Montant</th></tr>
          </thead>
          <tbody>
            <tr><td>Montant des ventes</td><td>$ 0,00</td></tr>
            <tr><td>Cadeau</td><td><b>$ 223,73</b></td></tr>
            <tr><td>Cadeau utilisé</td><td>$ 0,00</td></tr>
            <tr>
              <td>Cadeau restant</td>
              <td>
                <div class="btn-group dropdown">
                  <button type="button" class="btn btn-success">$ 223,73</button>
                  <button type="button" class="btn btn-success dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu" role="menu">
                    <li><a href="javascript:void(0);" data-toggle="modal" data-target="#resetClient">Vider la carte</a></li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</template>

<script>
    export default {
        name: "customer-table-loyalty",
        data () {
          return {

          }
        },
    }
</script>

<style scoped>

</style>
