<template>
  <div class="container">
    <form id="formAddInvoice" class="form" method="post" v-on:submit.prevent="createInvoice">
      <b-overlay :show="showOverlayNewInvoice" rounded="sm">
        <div class="card">
          <div class="card-header">
            <h4 class="modal-title"><i class="fa fa-plus"></i> {{$t('create_invoice')}}</h4>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label>{{ $t('customer') }}:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                      <router-link :to="{name: 'customer', params: {'customerId': newInvoice.customer.id}}">
                        <i class="fa fa-user"></i>
                      </router-link>
                    </span>
                </div>
                <input type="text" class="form-control" v-model="newInvoice.customer.name" disabled>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $t('type') }}</label>
              <select v-model="newInvoice.type.id" class="form-control" v-on:change="loadNextNumber(newInvoice.type.id)">
                <option value="">{{$t('type')}}</option>
                <option v-for="type in invoiceTypes" v-bind:value="type.id">{{ $t(type.name) }}</option>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('last_number') }}</label>
              <input v-model="newInvoice.number" type="text" class="form-control" id="nextInvoiceNumber" disabled/>
            </div>
            <div class="form-group">
              <label>{{ $t('date') }}</label>
              <input type="date" class="form-control" v-model="newInvoice.date"/>
            </div>
            <div class="form-group">
              <label>{{ $t('description') }}</label>
              <textarea class="form-control" v-model="newInvoice.description" v-bind:placeholder="$t('description')"></textarea>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-primary">
              <i class="fa fa-plus"></i>
              {{ $t('add') }}
            </button>
          </div>
        </div>
      </b-overlay>
    </form>
  </div>
</template>

<script>
    import {BOverlay} from 'bootstrap-vue'
    import store from '../../store'

    export default {
        name: "create-invoice",
        components: {
            'b-overlay':BOverlay
        },
        data () {
          return {
            newInvoice: {
                customer: {},
                type: {},
                number: null,
            },
            showOverlayNewInvoice: false,
            types: [],
            invoiceTypes: [],
          }
        },
        mounted () {
          this.loadCustomer(this.$route.params.customerId);
          this.loadInvoiceTypes();
        },
        methods: {
          createInvoice : function () {
            this.showOverlayNewInvoice = true;
            this.$http.post('factory/'+store.state.factoryConnected.id+'/document', {
                number: this.newInvoice.number,
                type: {
                    id: this.newInvoice.type.id,
                },
                customer: {
                    id: this.newInvoice.customer.id,
                },
                date: this.newInvoice.date,
                description: this.newInvoice.description,
            }).then((response) => {
              this.showOverlayNewInvoice = false;
              $("#formAddInvoice").modal('hide');
              this.newInvoice = {};
              this.$router.push({name: 'invoice', params: {invoiceId: response.data.id}});
            });
          },
          loadCustomer : function (customerId) {
              this.showOverlayNewInvoice = true;
              this.$http.get('customer/'+customerId).then((response)=>{
                this.showOverlayNewInvoice = false;
                  if (response.ok === true) {
                      this.newInvoice.customer = response.data;
                  }
              });
          },
          loadInvoiceTypes : function () {
            this.showOverlayNewInvoice = true;
            this.$http.get('factory/'+store.state.factoryConnected.id+'/settings/invoice/types').then((response) => {
              if (response.ok === true) {
                this.showOverlayNewInvoice = false;
                this.invoiceTypes = response.data;
              }
            });
          },
          loadNextNumber : function (invoiceTypeId) {
            if (invoiceTypeId !== '') {
              this.showOverlayNewInvoice = true;
              this.$http.get('factory/'+store.state.factoryConnected.id+'/documents/type/' + invoiceTypeId).then((response) => {
                this.showOverlayNewInvoice = false;
                this.newInvoice.number = null;
                if (response.ok === true && typeof response.data.last_number === 'string') {
                  this.newInvoice.number = response.data.last_number;
                }
              });
            }
          }
        }
    }
</script>

<style scoped>

</style>
