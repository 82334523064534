<template>
  <div>
    <form v-on:submit.prevent="updateSupplier" v-if="currentSupplier && currentSupplier.id">
      <div class="card">
        <div class="card-header">
          <h3 class="title">{{ currentSupplier.supplier}}</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>{{ $t('price')}}</label>
            <div class='input-group' >
              <span class="input-group-addon">{{ currentSupplier.currency }}</span>
              <input type="text" class="form-control" v-model="currentSupplier.amount"  v-bind:placeholder="$t('price')">
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button type="submit" class="btn btn-primary"><i class="fa fa-save"></i> {{ $t('save')}}</button>
          <button type="button" class="btn btn-default" v-on:click="selectSupplier({})"><i class="fa fa-eraser"></i></button>
        </div>
      </div>
    </form>
    <div class="card">
      <table class="table table-hover table-bordered">
        <thead>
          <tr>
            <td>#</td>
            <td>{{ $t('supplier') }}</td>
            <td>{{ $t('price') }}</td>
            <td>{{ $t('currency') }}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <button type="button" class="btn btn-primary" v-on:click="addSupplier" :disabled="newSupplier==='' || newSupplier === null">
                <i class="fa fa-plus"></i> {{$t('add_supplier')}}
              </button>
            </td>
            <td colspan="10">
              <select class="form-control" v-model="newSupplier">
                <option value="">{{$t('select_new_supplier')}}</option>
                <option v-for="supplier in availableSuppliers" v-bind:value="supplier.id">
                  {{ supplier.name }}
                </option>
              </select>
            </td>
          </tr>
          <tr v-if="suppliers.length === 0">
            <td colspan="10">{{ $t('no_supplier') }}</td>
          </tr>
          <tr v-for="supplier in suppliers">
            <td>
              <button type="button" v-on:click="selectSupplier(supplier)" class="btn btn-default btn-xs">
                <i class="fa fa-edit"></i> {{$t('edit')}}
              </button>
              <button type="button" v-on:click="deleteSupplier(supplier)" class="btn btn-danger btn-xs">
                <i class="fa fa-trash"></i>
              </button>
            </td>
            <td>{{ supplier.supplier.name }}</td>
            <td>
              <span v-if="supplier.amount">{{ supplier.amount }}</span>
              <span v-else>-</span>
            </td>
            <td>{{ supplier.currency.name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import store from '../../store';
  export default {
    name: 'article-supplier',
    data () {
      return {
        interval: null,
        newSupplier : null,
        availableSuppliers : [],
        suppliers: [],
        currentSupplier: {},
        progressBarSales: 0,
        sales: []
      }
    },
    props: {
        article: {
            type: String,
            required: true,
        },
    },
    mounted () {
      this.interval = setInterval(function() {
          if (this.article) {
              this.loadSuppliers();
              clearInterval(this.interval);
          }
      }.bind(this), 1000);
      this.loadAvailableSuppliers();
    },
    methods : {
      loadAvailableSuppliers : function () {
          this.$http.get('factory/'+store.state.factoryConnected.id+'/suppliers').then((response) => {
              this.availableSuppliers = response.data;
          });
      },
      loadSuppliers : function () {
        this.$http.get('article/'+this.article+'/suppliers').then((response) => {
          if (response.ok === true) {
            this.suppliers = response.data;
          }
        });
      },
      addSupplier : function() {
        if (this.newSupplier) {
          this.$http.post('article/'+this.article+'/supplier/'+this.newSupplier).then((response) => {
            if (response.ok === true) {
              this.loadSuppliers();
              this.newSupplier = null;
            }
          });
        }
      },
      deleteSupplier : function(supplier) {
          if (supplier.id) {
            this.$http.delete('article/'+this.article+'/supplier/'+supplier.id).then((response) => {
              if (response.ok === true) {
                  let index = this.suppliers.indexOf(supplier);
                  this.suppliers.splice(index, 1);
              }
            });
          }
      },
      selectSupplier : function (supplier) {
        this.currentSupplier = supplier;
      },
      updateSupplier : function() {
        if (this.currentSupplier && this.currentSupplier.id) {
          this.$http.put('article/'+this.article+'/supplier/'+this.currentSupplier.mapping.supplier.id, this.currentSupplier).then((response) => {
            if (response.ok === true) {
              this.loadSuppliers();
              this.currentSupplier = {};
            }
          });
        }
      }
    }
  }
</script>
