import { render, staticRenderFns } from "./CustomerTableContact.vue?vue&type=template&id=7410bee8&scoped=true&"
import script from "./CustomerTableContact.vue?vue&type=script&lang=js&"
export * from "./CustomerTableContact.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7410bee8",
  null
  
)

export default component.exports