<template>
  <div>
      <div class="card">
        <div class="card-body"><div ref="calendar"></div></div>
      </div>
  </div>
</template>

<script>
  export default {
    name: 'home-calendar',
    data () {
      return {
        calendar: null
      }
    },
    mounted () {
        this.loadCalendar();
    },
    methods: {
      loadCalendar : function () {
        this.$http.get('event/iframe').then((response) => {
          this.$refs.calendar = response.data;
          this.calendar = response.data;
        });
      }
    }
  }
</script>
