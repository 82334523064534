<template>
  <div>
    <b-overlay :show="showOverlayCustomerSales" rounded="sm">
      <div class="card">
        <div class="table-responsive">
          <div class="card-header">
            <router-link :to="{name: 'sale-customer-new', params: {customerId: customerId}}" class="btn btn-info">
              <i class="fa fa-plus"></i> {{ $t('add_one_sale') }}
            </router-link>
          </div>
          <table class="table table-bordered">
            <thead>
              <tr style="text-align: center">
                <th>#</th>
                <th>{{$t('date')}}</th>
                <th>{{$t('title')}}</th>
                <th>{{$t('vat_rate')}} (%)</th>
                <th>{{$t('amount')}} {{$t('vat_incl')}}</th>
                <th>{{$t('amount')}}</th>
                <th>{{$t('invoice')}}</th>
                <th>{{$t('payed')}}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="sales.length===0"><td colspan="10">{{ $t('no_sale') }}</td></tr>
              <tr v-for="sale in sales" v-bind:class="sale._trClass">
                <td>
                  <router-link  v-if="sale.allow.edit===true" :to="{name: 'sale-customer', params: {customerId: sale.customer.id, saleId: sale.id}}" class="btn btn-xs btn-primary">
                    <i class="fa fa-edit"></i>
                  </router-link>
                  <router-link  v-else :to="{name: 'sale-customer', params: {customerId: sale.customer.id, saleId: sale.id}}" class="btn btn-xs btn-info">
                    <i class="fa fa-eye"></i>
                  </router-link>
                </td>
                <td v-bind:data-title="$t('date')">{{sale.date}}</td>
                <td v-bind:data-title="$t('title')">{{sale.title}}</td>
                <td v-bind:data-title="$t('vat_rate')">{{getTax(sale, 'vat', 'rate')}} %</td>
                <td v-bind:data-title="$t('vat_incl')">
                  <money-format :value="sale.amount.amount_incl"
                                locale='en'
                                :currency-code="sale.currency.name"
                                :subunit-value=true
                                :hide-subunits=false>
                  </money-format>
                </td>
                <td v-bind:data-title="$t('amount')">
                  <money-format :value="sale.amount.amount"
                                locale='en'
                                :currency-code="sale.currency.name"
                                :subunit-value=true
                                :hide-subunits=false>
                  </money-format>
                </td>
                <td v-bind:data-title="$t('invoice')">
                  <router-link v-if="sale.invoice" :to="{name: 'invoice', params: {invoiceId: sale.invoice.id}}" class="btn btn-info btn-xs"><i class="fa fa-file"></i></router-link>
                  <button v-if="sale.invoice" v-on:click="printInvoice(sale.invoice.id)" class="btn btn-info btn-xs"><i class="fa fa-print"></i></button>
                </td>
                <td v-bind:data-title="$t('payed')">
                  <router-link :to="{name:'payments-target', params : {target:'sale', targetId: sale.id}}" class="btn btn-default btn-xs ">
                    {{sale.payed}} %
                  </router-link>
                </td>
                <td v-bind:data-title="$t('delete')">
                  <button class="btn btn-danger btn-xs" v-if="sale.allow.delete===true" :title="$t('delete_sale')" v-on:click="deleteSale(x)">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-overlay>
    <b-pagination
      v-model="currentPage"
      :number-of-pages="nbPages"
      :per-page="perPage"
      :total-rows="rows"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
      @change="loadPaginationCustomerSales"
    ></b-pagination>
  </div>
</template>

<script>
    import MoneyFormat from 'vue-money-format'
    import {BOverlay, BPagination} from 'bootstrap-vue'
    export default {
      name: "customer-table-sales",
      data () {
        return {
          sales: [],
          currentPage: 1,
          nbPages: 1,
          perPage: 1,
          rows: 0,
          showOverlayCustomerSales: false,
        }
      },
      components: {
        'b-overlay': BOverlay,
        'b-pagination':BPagination,
        'money-format': MoneyFormat,
      },
      props: {
          customerId: {
              type: String,
              required: true,
          },
      },
      mounted () {
        this.currentPage = this.$route.query.page ? this.$route.query.page : 1;
        this.loadSales();
      },
      methods: {
        deleteSale : function (sale) {
          this.$http.delete('sale/'+sale.id).then((response) => {
            if (response.ok === true) {
              this.loadSales();
            }
          }).catch((response) => {

          });
        },
        getTax : function (sale, name, field) {
          let taxes = sale.amount.taxes;
          for (let i in taxes) {
              let tax = taxes[i];
              if (tax.name === name) {
                  return typeof tax[field] !== 'undefined' ? tax[field] : null;
              }
          }
          return null;
        },
        loadPaginationCustomerSales: function (pageNum) {
          this.showOverlayCustomerSales = true;
          this.currentPage = pageNum;
          this.$router.push({name: 'customer-sales', params: {customerId: this.customerId}, query: {page: this.currentPage}});
          this.loadSales();
        },
        loadSales : function () {
          this.showOverlayCustomerSales = true;
          this.$http.get('customer/'+this.customerId+'/sales?page='+this.currentPage).then((response) => {
            this.showOverlayCustomerSales = false;
            if (response.ok === true) {
                this.rows = response.data.pagination.result;
                this.perPage = response.data.pagination.by_page;
                this.nbPages = response.data.pagination.pages;
                this.sales = response.data.items;
            }
          });
        },
        printInvoice : function (invoiceId) {
            this.$http.get('invoice/'+invoiceId+'/print').then((response) => {
                var data = response.data;
                if (data.length > 0 && data[0].path) {
                    window.open(data[0].path);
                }
            });
        },
      }
    }
</script>

<style scoped>

</style>
