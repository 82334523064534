import { render, staticRenderFns } from "./ArticleSupplier.vue?vue&type=template&id=05f20010&"
import script from "./ArticleSupplier.vue?vue&type=script&lang=js&"
export * from "./ArticleSupplier.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports