<template>
  <div>
    <template>
      <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
    </template>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-2">
                <div v-if="nbBrands === 0">
                  <button type="button" class="btn btn-default" v-on:click="loadByBrand(currentBrand.id)">
                    <i class="fa fa-refresh fa-pulse fa-fw" v-if="showOverlayArticlesSearch"></i>
                    <i class="fa fa-refresh" v-else></i>
                    <span class="currentYear">{{currentBrand.name}}</span>
                  </button>
                </div>
                <div v-else class="dropdown">
                  <button type="button" class="btn btn-default" v-on:click="loadByBrand(currentBrand.id)">
                    <i class="fa fa-refresh fa-pulse fa-fw" v-if="showOverlayArticlesSearch"></i>
                    <i class="fa fa-refresh" v-else></i>
                    <span class="currentYear">{{currentBrand.name}}</span>
                  </button>
                  <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="caret"></span>
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <li v-for="brand in brands">
                      <a href="javascript:void(0);" v-on:click="loadBrands(brand.id)" class="chgYear">
                        {{brand.name}} <span class="badge">{{brand.nb}}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-3">
                <form class="form-horizontal" v-on:submit.prevent="submitSearchArticle">
                  <div class="input-group">
                    <input type="text" class="form-control" v-model="searchArticle" v-bind:placeholder="$t('keywords')"/>
                    <div class="input-group-btn">
                      <button type="submit" class="btn btn-primary">
                        <i class="fa fa-search"></i> {{ $t('search') }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div class="col-lg-7" style="text-align:right">
                <router-link :to="{name:'article-new'}" class="btn btn-info">
                  <i class="fa fa-plus"></i> {{ $t('add_article') }}
                </router-link>
                <button type="button" class="btn btn-default" v-on:click="importInventory">{{ $t('inventory') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <b-overlay :show="showOverlayArticlesSearch" rounded="sm">
          <div class="card" id="tabInvoice">
            <table class="table table-condensed table-hover table-bordered">
              <thead class="align-center">
                <tr style="font-weight: bold;text-align: center;">
                  <th>#</th>
                  <th>{{ $t('category') }}</th>
                  <th>{{ $t('brand') }}</th>
                  <th>{{ $t('modele') }}</th>
                  <th>{{ $t('title') }}</th>
                  <th>{{ $t('description') }}</th>
                  <th>{{ $t('stock') }}</th>
                  <th>{{ $t('stock_min') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="nbArticles === 0">
                  <td colspan="15">{{ $t('any_article') }}...</td>
                </tr>
                <tr v-for="article in articles">
                  <td>
                    <router-link :to="{name: 'article', params: {articleId: article.id}}" class="btn btn-xs btn-info"><i class="fa fa-edit"></i> {{ $t('open') }}</router-link>
                  </td>
                  <td style="text-align:center">{{ article.category ? article.category.name : null }}</td>
                  <td style="text-align:center">{{ article.brand ? article.brand.name : null }}</td>
                  <td style="text-align:center">{{ article.modele }}</td>
                  <td style="text-align:center">{{ article.name }}</td>
                  <td style="text-align:center">{{ article.description }}</td>
                  <td style="text-align:center">{{ article.nb.stock>0?article.nb.stock:null }}</td>
                  <td style="text-align:center">{{ article.stock.min }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-overlay>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          @change="loadPaginationArticles"
        ></b-pagination>
      </div>
    </div>
    <modal-file-inventory ref="modal-inventory"></modal-file-inventory>
  </div>
</template>

<script>
  import store from '../../store'
  import {BOverlay, BPagination, BBreadcrumb} from 'bootstrap-vue'
  import ModalFileInventory from "./Modal/FileInventory";
  export default {
    data () {
      return {
        searchArticle : null,
        showOverlayArticlesSearch: false,
        articles: [],
        brands: [],
        currentPage: 1,
        nbPages: 1,
        perPage: 1,
        rows: 0,
        currentBrand: {
          id : null,
          name: 'All',
          nb: null,
        }
      }
    },
    components: {
        'b-overlay': BOverlay,
        'b-pagination':BPagination,
        'b-breadcrumb':BBreadcrumb,
        ModalFileInventory
    },
    computed: {
      nbArticles : function () {
        return this.articles ? this.articles.length : 0;
      },
      nbBrands : function () {
        return this.brands ? this.brands.length : 0;
      },
      breadcrumbItems: function () {
          return [
              {
                  text: 'Home',
                  to: {name: 'root'}
              },
              {
                  text: 'Stocks',
                  active: true
              },
          ];
      },
    },
    mounted () {
      this.loadArticles();
    },
    methods: {
      loadYears : function() {
        this.$http.get('articles/years').then((response) => {
          this.years = response.data;
        });
      },
      importInventory: function () {
        this.$refs['modal-inventory'].showModal(this);
      },
      loadPaginationArticles: function () {
          this.showOverlayArticlesSearch = true;
          setTimeout(this.loadArticles.bind(this), 100);
      },
      loadArticles : function() {
        this.showOverlayArticlesSearch = true;
        this.$http.get('factory/'+store.state.factoryConnected.id+'/articles?page='+this.currentPage).then((response) => {
          this.articles = response.data.items;
          this.rows = response.data.pagination.result;
          this.perPage = response.data.pagination.by_page;
          this.showOverlayArticlesSearch = false;
        });
      },
      deleteArticle : function(article) {
          this.showOverlayArticlesSearch = true;
          this.$http.delete('article/'+article.id).then((response) => {
            this.showOverlayArticlesSearch = false;
              if (response.ok === true) {
                  let index = this.articles.indexOf(article);
                  this.articles.splice(index, 1);
              }
          });
      },
      submitSearchArticle : function () {
        if (this.searchArticle) {
          this.showOverlayArticlesSearch = true;
          this.$http.get('factory/'+store.state.factoryConnected.id+'/articles?search='+this.searchArticle).then((response) => {
            this.articles = response.data;
            this.showOverlayArticlesSearch = false;
          });
        }
      },
      loadByBrand : function (brandId) {
        if (!brandId) {
          this.loadArticles();
        }
      }
    }
  }
</script>
