<template>
    <div>
      <b-container :fluid=true>
          <b-tabs pills>
            <b-tab title="Form" active class="mt-3">
              <b-card-group deck>
                <b-card no-body>
                  <b-card-header>Select period</b-card-header>
                  <b-card-body><rapport-form :submit-parent="submit"></rapport-form></b-card-body>
                </b-card>
              </b-card-group>
            </b-tab>
            <b-tab title="Customer" class="mt-3">
              <template v-slot:title>
                <b-spinner type="border" v-if="isLoading('customer') === true" small></b-spinner> {{$t('customers')}}
              </template>
              <rapport-customer ref="customer"></rapport-customer>
            </b-tab>
            <b-tab title="Code" class="mt-3">
              <template v-slot:title>
                <b-spinner type="border" v-if="isLoading('code') === true" small></b-spinner> {{$t('codes')}}
              </template>
              <rapport-code ref="code"></rapport-code>
            </b-tab>
            <b-tab title="Charges" class="mt-3">
              <template v-slot:title>
                <b-spinner type="border" v-if="isLoading('charge') === true" small></b-spinner> {{$t('charges')}}
              </template>
              <rapport-charge ref="charge"></rapport-charge></b-tab>
            <b-tab title="Invoices" class="mt-3">
              <template v-slot:title>
                <b-spinner type="border" v-if="isLoading('invoice') === true" small></b-spinner> {{$t('invoices')}}
              </template>
              <rapport-invoice ref="invoice"></rapport-invoice>
            </b-tab>
          </b-tabs>
      </b-container>
    </div>
</template>

<script>
    import RapportForm from "./RapportForm";
    import MoneyFormat from 'vue-money-format'
    import {BCard, BCardGroup, BCardText, BContainer, BCol, BCardHeader, BRow, BCardFooter, BCardBody, BTabs, BTab, BSpinner} from 'bootstrap-vue'
    import RapportCode from "./RapportCode";
    import RapportCharge from "./RapportCharge";
    import RapportInvoice from "./RapportInvoice";
    import RapportCustomer from "./RapportCustomer";
    export default {
        components: {
            RapportCustomer,
            RapportInvoice,
            RapportCharge,
            RapportCode,
            BCardText,
            BRow,
            BCol,
            BContainer,
            BCard,
            BCardHeader,
            BCardFooter,
            BCardBody,
            RapportForm,
            BTabs,
            BTab,
            BCardGroup,
            BSpinner,
            MoneyFormat,
        },
        name: "rapport",
        methods: {
            submit : function (start, end) {
                let ref;
                let keys = Object.keys(this.$refs);
                for (let i = 0; i < keys.length; ++i) {
                    ref = this.$refs[keys[i]];
                    console.error('typeof ref.loadData', typeof ref.loadData)
                    if (typeof ref.loadDataTimed === 'function') {
                      ref.loadDataTimed(start, end);
                    } else if (typeof ref.loadData === 'function') {
                        ref.loadData();
                    }
                }
            },
            isLoading : function (refName) {
                let ref = this.$refs[refName];
                if (typeof ref !== 'undefined' && typeof ref.isLoading === 'function') {
                    return ref.isLoading();
                }
                return false;
            }
        }
    }
</script>
