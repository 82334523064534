<template>
  <div>
    <b-overlay :show="showOverlayCustomer" rounded="sm">
      <div class="card">
        <div class="card-header">
          <h4 class="modal-title"><i class="fa fa-plus"></i> {{$t('add_customer')}}</h4>
        </div>
        <div class="card-body">
          <b-form-checkbox switch :checked="isCompany===true" v-model="isCompany">Company</b-form-checkbox>
          <div class="form-group"><div class="col-md-12"><hr /></div></div>
          <div class="form-group">
            <label for="newCustomerExternalId">{{$t('external_id')}}:</label>
            <input type="text" id="newCustomerExternalId" class="form-control" v-model="customer.external_id" v-bind:placeholder="$t('external_id')">
          </div>
          <div class="form-group" v-if="isCompany === true">
            <label>{{$t('vat_number')}}:</label>
            <b-input-group class="mt-3">
              <template v-slot:prepend>
                <b-input-group-text>
                  <a href="javascript:void(0);" v-on:click="loadVatNumberInfo">
                    <i class="fa fa-refresh"></i>
                  </a>
                </b-input-group-text>
              </template>
              <b-form-input v-model="customer.vat_number" v-bind:placeholder="$t('vat_number')"></b-form-input>
            </b-input-group>
          </div>
          <div class="form-group" v-if="isCompany === true">
            <label>{{$t('supplier')}}:</label>
            <b-form-checkbox switch :checked="customer.supplier===true" v-model="customer.supplier"></b-form-checkbox>
          </div>
          <div class="form-group">
            <label for="newCustomerLastName">{{$t('last_name')}}:</label>
            <input type="text" id="newCustomerLastName" class="form-control" v-model="customer.last_name" v-bind:placeholder="$t('last_name')">
          </div>
          <div class="form-group">
            <label for="newCustomerFirstName">{{$t('first_name')}}:</label>
            <input type="text" id="newCustomerFirstName" class="form-control" v-model="customer.first_name" v-bind:placeholder="$t('first_name')">
          </div>
          <div class="form-group">
            <label for="newCustomerCompany">{{$t('company')}}:</label>
            <input type="text" id="newCustomerCompany" class="form-control" v-model="customer.company" v-bind:placeholder="$t('company')">
          </div>
          <div class="form-group">
            <label for="newCustomerDob">{{$t('birthday_date')}}:</label>
            <input type="date" id="newCustomerDob" class="form-control" v-model="customer.dob" v-bind:placeholder="$t('birthday_date')">
          </div>
          <div class="form-group">
            <label for="newCustomerEmail">{{$t('email')}}:</label>
            <div class="input-group">
              <span class="input-group-addon"><span class="glyphicon glyphicon-envelope"></span></span>
              <input type="text" id="newCustomerEmail" class="form-control" v-model="customer.email" v-bind:placeholder="$t('email')">
            </div>
          </div>
          <div class="form-group">
            <label>{{$t('mobile')}}:</label>
            <b-input-group class="mt-3">
              <template v-slot:prepend>
                <b-input-group-text>
                  <i class="fa fa-mobile-phone"></i>
                </b-input-group-text>
              </template>
              <b-form-input v-model="customer.mobile" :placeholder="getCountryPhone"></b-form-input>
            </b-input-group>
          </div>
          <div class="form-group">
            <label>{{$t('phone')}}:</label>
            <b-input-group class="mt-3">
              <template v-slot:prepend>
                <b-input-group-text>
                  <i class="fa fa-phone"></i>
                </b-input-group-text>
              </template>
              <b-form-input v-model="customer.phone" :placeholder="getCountryPhone"></b-form-input>
            </b-input-group>
          </div>
          <div class="form-group">
            <div class="col-md-12">
              <hr />
            </div>
          </div>
          <div class="form-group">
            <label for="newCustomerAddressCountry">{{$t('country')}}:</label>
            <div class="input-group">
              <span class="input-group-addon"><span class="glyphicon glyphicon-globe"></span></span>
              <select id="newCustomerAddressCountry" v-model="countryId" class="form-control" @change="changeCountry">
                <option value="">{{$t('country_list')}}</option>
                <option v-for="country in countries" v-bind:value="country.id" :selected="country.default===true">{{country.name}}</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="newCustomerAddressZipcode">{{$t('zipcode')}}:</label>
            <input type="text" list="list_zipcodes" id="newCustomerAddressZipcode" data-geo="postal_code" class="form-control" v-model="address.zipcode" v-bind:placeholder="$t('zipcode')" :disabled="allowedZipCode === false"  @change="changeZipcode">
            <datalist id="list_zipcodes">
              <option v-for="zipcode in zipcodes" :value="zipcode" />
            </datalist>
          </div>
          <div class="form-group">
            <label for="newCustomerAddressCity">{{$t('city')}}:</label>
            <input type="text" list="list_cities" id="newCustomerAddressCity" class="geocomplete form-control" v-model="address.city" v-bind:placeholder="$t('city')" autocomplete="off" :disabled="allowedCity === false">
            <datalist id="list_cities">
              <option v-for="city in cities" :value="city" />
            </datalist>
          </div>
          <div class="form-group">
            <label for="newCustomerAddressStreet">{{$t('street')}}:</label>
            <input type="text" list="list_streets" id="newCustomerAddressStreet" class="form-control" v-model="address.street" v-bind:placeholder="$t('street')" autocomplete="off"  :disabled="allowedStreet === false">
            <datalist id="list_streets">
              <option v-for="street in streets" :value="street" />
            </datalist>
          </div>
          <div class="form-group">
            <label for="newCustomerAddressStreet">{{$t('number')}}:</label>
            <input type="text" class="form-control" id="number" v-model="address.number" v-bind:placeholder="$t('number')" autocomplete="off" :disabled="allowedNumber === false">
          </div>
          <div class="form-group">
            <label for="newCustomerAddressBox">{{$t('box')}}:</label>
            <input type="text" class="form-control" id="newCustomerAddressBox" v-model="address.box" v-bind:placeholder="$t('box')" autocomplete="off" :disabled="allowedBox === false">
          </div>
        </div>
        <div class="card-footer">
          <button type="button" class="btn btn-primary" v-on:click="createCustomer" :disabled="allowedCreateButton === false">
            <i class="fa fa-plus"></i> {{ $t('add') }}
          </button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
    import {BFormCheckbox, BOverlay, BInputGroup, BFormInput, BInputGroupText} from 'bootstrap-vue'
    import store from '../../store'
    export default {
        name: "create-customer",
        components: {
            'b-overlay': BOverlay,
            'b-form-checkbox': BFormCheckbox,
            BInputGroup,
            BFormInput,
            BInputGroupText
        },
        data () {
          return {
            countries: [],
            cities: [],
            streets: [],
            zipcodes: [],
            isCompany: true,
            countryId: {
              id: null,
              phone: null,
              code: null,
              name: null
            },
            address: {
              city: null,
              zipcode: null,
              street: null,
              number: null,
              box: null
            },
            customer: {
              external_id: null,
              phone: null,
              mobile: null,
              last_name: null,
              first_name: null,
              company: null,
              dob: null,
              supplier: false,
              email: false
            },
            showOverlayCustomer: false,
          }
        },
        mounted () {
          this.loadCountries();
        },
        computed: {
          allowedCreateButton: function() {
            if (true === this.isCompany && (typeof this.customer.company === 'undefined' || null === this.customer.company || '' === this.customer.company)) {
              return false
            }
            return (
              typeof this.customer.first_name !== 'undefined' && null !== this.customer.first_name && '' !== this.customer.first_name &&
              typeof this.customer.last_name !== 'undefined' && null !== this.customer.last_name && '' !== this.customer.last_name
            ) || (typeof this.customer.company !== 'undefined' && null !== this.customer.company && '' !== this.customer.company)
          },
          allowedZipCode: function () {
            return null !== this.getCountry()
          },
          allowedCity: function () {
            return typeof this.address.zipcode !== 'undefined' && null !== this.address.zipcode && '' !== this.address.zipcode
          },
          allowedStreet: function () {
            return typeof this.address.city !== 'undefined' && null !== this.address.city && '' !== this.address.city
          },
          allowedNumber: function () {
            return typeof this.address.street !== 'undefined' && null !== this.address.street && '' !== this.address.street
          },
          allowedBox: function () {
            return typeof this.address.number !== 'undefined' && null !== this.address.number && '' !== this.address.number
          },
          getCountryPhone: function () {
            return this.getCountry() ? '+'+this.getCountry()['phone'] : null;
          },
        },
        methods: {
          getCountry: function () {
            for (var idx in this.countries) {
              if (typeof this.countries[idx].id !== 'undefined' && this.countries[idx].id === this.countryId) {
                return this.countries[idx];
              }
            }
            return null;
          },
          getCountryCode: function () {
            return this.getCountry()['code'];
          },
          changeCountry: function () {
            this.loadZipcodes()
          },
          changeZipcode: function () {
            this.loadCities()
            this.loadStreets()
          },
          loadCountries : function() {
            this.showOverlayCustomer = true;
            this.$http.get('geo/countries').then((response) => {
              this.showOverlayCustomer = false;
              if (response.ok === true) {
                this.countries = response.data;
              }
            }).catch ((response)=>{
              this.showOverlayCustomer = false;
            });
          },
          loadZipcodes : function() {
            this.showOverlayCustomer = true;
            this.$http.get('geo/country/'+this.getCountryCode()+'/zipcodes').then((response) => {
              this.showOverlayCustomer = false;
              if (response.ok === true) {
                this.zipcodes = response.data;
              }
            }).catch ((response)=>{
              this.showOverlayCustomer = false;
            });
          },
          loadCities : function() {
            this.showOverlayCustomer = true;
            this.$http.get('geo/country/'+this.getCountryCode()+'/zipcode/'+this.address.zipcode+'/cities').then((response) => {
              this.showOverlayCustomer = false;
              if (response.ok === true) {
                this.cities = response.data;
              }
            }).catch ((response)=>{
              this.showOverlayCustomer = false;
            });
          },
          loadStreets : function() {
            this.showOverlayCustomer = true;
            this.$http.get('geo/country/'+this.getCountryCode()+'/zipcode/'+this.address.zipcode+'/streets').then((response) => {
              this.showOverlayCustomer = false;
              if (response.ok === true) {
                this.streets = response.data;
              }
            }).catch ((response)=>{
              this.showOverlayCustomer = false;
            });
          },
          createCustomer : function () {
            this.showOverlayCustomer = true
            var customer = this.customer
            customer.address = this.address
            customer.address.country = this.getCountry()
            this.$http.post('factory/'+store.state.factoryConnected.id+'/customer', customer).then((response) => {
              this.showOverlayCustomer = false;
              this.customer = {address: {mapping: {}}};
              this.$router.push({name: 'customer', params: {customerId: response.data.id}});
            }).catch ((response)=>{
                this.showOverlayCustomer = false;
            });
          },
          loadVatNumberInfo : function() {
            if (this.customer.vat_number  && this.customer.vat_number !== '') {
              this.showOverlayCustomer = true;
              this.$http.get('webservices/vat_number?vat_number='+this.customer.vat_number).then((response) => {
                this.showOverlayCustomer = false;
                if (response.ok === true) {
                  this.customer = response.data;
                }
              }).catch((response) => {
                this.showOverlayCustomer = false;
              });
            }
          }
        }
    }
</script>
