<template>
    <div class="row">
      <div class="col-md-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link :to="{name: 'root'}"><i class="fa fa-home"></i></router-link></li>
            <li class="breadcrumb-item"><router-link :to="{name: 'stocks'}">{{ $t('articles') }}</router-link></li>
            <li class="breadcrumb-item active" aria-current="page">{{ article.name }}</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-3">
          <div class="card">
            <b-overlay :show="showOverlayArticle" rounded="sm">
              <div class="card-body">
                <form method="post" v-on:submit.prevent="submitArticle">
                  <div class="form-group">
                    <img :src="article.picture" style="max-width: 100px;max-height: 100px" class="img-thumbnail"/>
                  </div>
                  <div class="form-group">
                    <label>{{ $t('picture') }}:</label>
                    <input type="text" class="form-control" v-model="article.picture" required/>
                  </div>
                  <hr />
                  <div class="form-group">
                    <label>{{ $t('title') }}:</label>
                    <input type="text" class="form-control" v-model="article.name" required/>
                  </div>
                  <div class="form-group">
                    <label>{{ $t('ean') }}:</label>
                    <input type="text" class="form-control" v-model="article.ean"/>
                  </div>
                  <hr />
                  <div class="form-group">
                    <label>{{ $t('category') }}:</label>
                    <select class="form-control" v-model="article.category.id">
                      <option value="">{{ $t('select_category') }}</option>
                      <option v-for="category in categories" v-bind:value="category.id">
                        {{ category.name }}
                      </option>
                    </select>
                  </div>
                  <hr >
                  <div class="form-group">
                    <label>{{ $t('brand') }}:</label>
                    <input type="text" class="form-control" v-model="article.brand.name"/>
                  </div>
                  <div class="form-group">
                    <label>{{ $t('modele') }}:</label>
                    <input type="text" class="form-control" v-model="article.modele"/>
                  </div>
                  <div class="form-group">
                    <label>{{$t('vat_rate')}}:</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">%</span>
                      </div>
                      <select v-model="article.price.vat_rate" class="form-control">
                        <option value="">Selectionner un taux de T.V.A.</option>
                        <option v-for="vat_rate in vat_rates" v-bind:value="vat_rate.rate">{{vat_rate.rate}} %</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{{ $t('available_for') }}:</label>
                    <div class='input-group' >
                      <span class="input-group-addon"><input type="checkbox" v-model="article.available.stock"></span>
                      <input type="text" class="form-control" v-bind:value="$t('stock')" disabled/>
                    </div>
                    <div class='input-group' >
                      <span class="input-group-addon"><input type="checkbox" v-model="article.available.rent"></span>
                      <input type="text" class="form-control" v-bind:value="$t('rent')" disabled/>
                    </div>
                    <div class='input-group' >
                      <span class="input-group-addon"><input type="checkbox" v-model="article.available.service"></span>
                      <input type="text" class="form-control" v-bind:value="$t('service')" disabled/>
                    </div>
                    <div class='input-group' >
                      <span class="input-group-addon"><input type="checkbox" v-model="article.available.space"></span>
                      <input type="text" class="form-control" v-bind:value="$t('website')" disabled/>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{{ $t('stock_minimum') }}:</label>
                    <input type="number" class="form-control" v-model="article.stock.min"/>
                  </div>
                  <div class="form-group">
                    <label>{{ $t('nb_piece') }}:</label>
                    <input type="number" class="form-control" v-model="article.piece"/>
                  </div>
                  <div class="form-group">
                    <label>{{ $t('description') }}:</label>
                    <textarea class="form-control" style="padding:5px;height: 100px;" v-model="article.description"></textarea>
                  </div>
                  <button type="submit" class="btn  btn-primary"><i class="fa fa-save"></i> {{ $t('save') }}</button>
                  <button type="button" class="btn  btn-danger" v-on:click="deleteArticle"><i class="fa fa-trash"></i> {{ $t('delete') }}</button>
                </form>
              </div>
            </b-overlay>
        </div>
      </div>
      <div class="col-md-9">
        <b-tabs pills>
          <b-tab :title="$t('price')" v-if="article.id" active>
            <br />
            <article-price :article="article.id"></article-price>
          </b-tab>
          <b-tab :title="$t('stock')" v-if="article.id">
            <br />
            <article-stock ref="stock-in" :article="article.id" :loadStocksOut="loadStocksOut"></article-stock>
          </b-tab>
          <b-tab :title="$t('stock out')" v-if="article.id">
            <br />
            <article-stock-out ref="stock-out" :article="article.id" :loadStocksIn="loadStocksIn"></article-stock-out>
          </b-tab>
          <b-tab :title="$t('supplier')" v-if="article.id">
            <br />
            <article-supplier :article="article.id"></article-supplier>
          </b-tab>
        </b-tabs>
      </div>
  </div>
</template>

<script>
  import {BTabs, BTab, BOverlay} from 'bootstrap-vue'
  import ArticleStock from "../Article/ArticleStock";
  import ArticleStockOut from "../Article/ArticleStockOut";
  import ArticleSupplier from "../Article/ArticleSupplier";
  import ArticlePrice from "./ArticlePrice";
  import store from '../../store';
  export default {
    components: {
        'b-tabs' : BTabs,
        'b-tab' : BTab,
        BOverlay,
        ArticleStock,
        ArticleStockOut,
        ArticleSupplier,
        ArticlePrice
    },
    data () {
      return {
        categories: [],
        vat_rates: [],
        newSupplier : null,
        availableSuppliers : [],
        suppliers: [],
        currentSupplier: {},
        currentStock: {},
        progressBarSales: 0,
        showOverlayArticle: false,
        article: {
          picture: null,
          brand: {
              id: null,
              name: null
          },
          category: {
              id: null,
              name: null
          },
          id: null,
          stock: {},
          piece: 1,
          sizes: {},
          categories: {},
          mapping: {},
          available: {},
          price: {
            vat_rate: null,
          }
        },
        address: {},
        articles: [],
        sales: []
      }
    },
    computed: {
    },
    mounted () {
      this.loadArticle(true);
      this.loadVatRates();
      this.loadAvailableSuppliers();
      this.loadCategories();
    },
    methods : {
      loadStocksIn: function () {
        var stockIn = this.$refs['stock-in'];
        if (stockIn && typeof stockIn.loadStocks === 'function') {
          stockIn.loadStocks();
        }
      },
      loadStocksOut: function () {
        var stockOut = this.$refs['stock-out'];
        if (stockOut && typeof stockOut.loadStocks === 'function') {
          stockOut.loadStocks();
        }
      },
      loadCategories : function () {
        this.$http.get('factory/'+store.state.factoryConnected.id+'/categories?target=article').then((response) => {
          if (response.ok === true) {
            this.categories = response.data;
          }
        });
      },
      deleteArticle : function() {
        this.$http.delete('article/'+this.article.id).then((response) => {
          if (response.ok === true) {
            this.$router.push({name: 'stocks'});
          }
        });
      },
      loadArticle : function (cascade) {
        if (this.$route.params.articleId) {
          this.showOverlayArticle = true;
          this.$http.get('article/' + this.$route.params.articleId).then((response) => {
            this.showOverlayArticle = false;
            let article = response.data;
            if (typeof article.category === 'undefined' || !article.category) {
              article.category = {id: null, name: null};
            }
            this.article = article;

            if (cascade === true) {
              this.loadSuppliers();
            }
          });
        }
      },
      submitArticle: function() {
          if (this.article.id) {
              this.updateArticle();
          } else {
              this.addArticle();
          }
      },
      updateArticle : function () {
        this.showOverlayArticle = true;
        this.$http.put('article/'+this.article.id, this.article).then((response) => {
          this.showOverlayArticle = false;
          if (response.ok === true) {
            this.loadArticle(false)
          }
        });
      },
      addArticle : function() {
        this.showOverlayArticle = true;
          this.$http.post('factory/'+store.state.factoryConnected.id+'/article', this.article).then((response) => {
            this.showOverlayArticle = false;
              if (response.ok === true) {
                this.article = response.data;
                  this.$router.push({
                      name: 'article',
                      params: {
                          articleId: response.data.id
                      }
                  });
              }
          });
      },
      loadSuppliers : function () {
        this.$http.get('article/'+this.article.id+'/suppliers').then((response) => {
          if (response.ok === true) {
            this.suppliers = response.data;
          }
        }).catch((response) => {
        });
      },
      addSupplier : function() {
        if (this.newSupplier) {
          this.$http.post('article/'+this.article.id+'/supplier/'+this.newSupplier).then((response) => {
            if (response.ok === true) {
              this.loadSuppliers();
              this.newSupplier = null;
            }
          });
        }
      },
      loadAvailableSuppliers : function () {
        this.$http.get('factory/'+store.state.factoryConnected.id+'/suppliers').then((response) => {
          this.availableSuppliers = response.data;
        });
      },
      deleteSupplier : function(supplierId) {
          if (supplierId) {
            this.$http.delete('article/'+this.article.id+'/supplier/'+supplierId).then((response) => {
              if (response.ok === true) {
                this.loadSuppliers();
              }
            });
          }
      },
      selectSupplier : function (supplier) {
        this.currentSupplier = supplier;
      },
      updateSupplier : function() {
        if (this.currentSupplier && this.currentSupplier.id) {
          this.$http.put('article/'+this.article.id+'/supplier/'+this.currentSupplier.id, this.currentSupplier).then((response) => {
            if (response.ok === true) {
              this.loadSuppliers();
              this.currentSupplier = {};
            }
          });
        }
      },
      loadVatRates : function () {
          this.$http.get('factory/'+store.state.factoryConnected.id+'/settings/tax/vat_rate').then((response) => {
              if (response.ok === true) {
                  this.vat_rates = response.data;
              }
          });
      },
    }
  }
</script>
