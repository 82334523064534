<template>
  <div>
    <div class="col-md-12">
      <form method="get" v-on:submit.prevent="sendSearch">
        <div class="input-group" style="width: 100%">
          <input type="text" class="form-control" v-model="searchQuery" autocomplete="off" v-bind:placeholder="$t('keywords')">
          <div class="input-group-btn">
            <button type="button" v-on:click="sendSearch" class="btn btn-default"><i class="fa fa-search"></i></button>
            <a href="javascript:void(0);" class="btn btn-default"><i class="fa fa-user"></i> {{ $t('customer_counter') }}</a>
            <a href="javascript:void(0);" data-toggle="modal" data-target="#createClientModalPos" class="btn btn-primary">
              <i class="fa fa-plus"> {{ $t('add_customer') }}</i>
            </a>
          </div>
        </div>
      </form>
      <br />
      <div class="card">
        <table class="table table-hover table-striped table-bordered">
          <thead>
            <tr>
              <th>{{ $t('customer') }}</th>
              <th>{{ $t('city') }}</th>
              <th style="text-align:center"><i class="fa fa-edit"></i></th>
            </tr>
          </thead>
          <tbody class="searchCustomerResult">
            <tr v-if="search.length==0"><td colspan="5">{{ $t('nothing_for_the_moment') }}</td></tr>
            <tr v-for="result in search">
              <td>{{result.name}}</td>
              <td>{{result.address.city}}</td>
              <td style="text-align:center">
                <button v-on:click="selectCustomer(result.id)" class="btn btn-primary btn-xs"><i class="fa fa-edit"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <form v-on:submit.prevent="createClient" method="post">
      <div class="modal fade" id="createClientModalPos" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <h4 class="modal-title">{{ $t('add_customer') }}</h4>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="inputAddCustomerLastName">{{ $t('last_name') }}:</label>
                    <input type="text" class="form-control" v-model="newCustomer.last_name" v-bind:placeholder="$t('last_name')" id="inputAddCustomerLastName">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="inputAddCustomerLastName">{{ $t('first_name') }}:</label>
                    <input type="text" class="form-control" v-model="newCustomer.first_name" v-bind:placeholder="$t('first_name')" id="inputAddCustomerLastName">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="inputAddCustomerCompany">{{ $t('company') }}:</label>
                    <input type="text" class="form-control" v-model="newCustomer.company" v-bind:placeholder="$t('company')" id="inputAddCustomerCompany">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="inputAddCustomerVatNumber">{{ $t('vat_number') }}:</label>
                    <input type="text" class="form-control" v-model="newCustomer.vat" v-bind:placeholder="$t('vat_number')" id="inputAddCustomerVatNumber">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="inputAddCustomerEMail">{{ $t('email') }}:</label>
                    <input type="text" class="form-control" v-model="newCustomer.email" v-bind:placeholder="$t('email')" id="inputAddCustomerEMail">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="inputAddCustomerMobile">{{ $t('mobile') }}:</label>
                    <input type="text" class="form-control" v-model="newCustomer.mobile" v-bind:placeholder="$t('mobile')" id="inputAddCustomerMobile">
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-lg btn-primary"><i class="fa fa-save"></i> {{ $t('save') }}</button>
              <button type="button" class="btn btn-default" data-dismiss="modal">{{ $t('close') }}</button>
            </div>
          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->
    </form>
  </div>
</template>

<script>
    import store from '../../../store';
    export default {
      name: "pos-tab-customer",
      data () {
        return {
          search: [],
          searchQuery: null,
          newCustomer: {}
        }
      },
      props: {
          tillId: {
              type: String,
              required: false,
          },
          till: {
              type: Object,
              required: true,
          },
          loadTill: {
              type: Function,
              required: true,
          },
      },
      mounted : function () {

      },
      methods: {
        sendSearch : function () {
          let query = typeof this.searchQuery !== 'undefined' && this.searchQuery !== '' && this.searchQuery !== null ? this.searchQuery : null;
          if (query) {
              this.$http.get('factory/'+store.state.factoryConnected.id+'/customers?search=' + query + '&page=1').then((response) => {
                  if (response.ok === true) {
                      this.search = response.data.items;
                  }
              });
          }
        },
        selectCustomer : function (customerId) {
          this.$http.put('pos/till/'+this.tillId+'/customer/'+customerId).then((response) => {
            if (response.ok === true) {
              this.loadTill();
            }
          });
        },
        createClient : function() {
          this.$http.post('customer', this.newCustomer).then((response) => {
            if (response.ok === true) {
              this.selectCustomer(response.data.id);
              $("#createClientModalPos").modal('hide');
            }
          });
        }
      }
    }
</script>
