import * as fetchApi from '../fetchApi'
import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import Article from "../components/Article/Article";
import Articles from "../components/Articles/Articles";
import Customer from "../components/Customer/Customer";
import Customers from "../components/Customers/Customers";
import UpdateEvent from "../components/Event/UpdateEvent";
import CreateEvent from "../components/Event/CreateEvent";
import Events from "../components/Events/Events";
import Charge from "../components/Charge/Charge";
import Invoice from "../components/Invoice/Invoice";
import Invoices from "../components/Invoices/Invoices";
import Home from "../components/Home/Home";
import Fund from "../components/Fund/Fund";
import Charges from "../components/Charges/Charges";
import Support from "../components/Support/Support";
import SettingsInvoice from "../components/Settings/SettingsInvoice";
import SettingsPaymode from "../components/Settings/SettingsPaymode";
import SettingsTill from "../components/Settings/SettingsTill";
import SettingsModules from "../components/Settings/SettingsModules";
import SettingsTaxes from "../components/Settings/SettingsTaxes";
import SettingsCustomer from "../components/Settings/SettingsCustomer";
import Users from "../components/Users/Users";
import User from "../components/User/User";
import Pos from "../components/Pos";
import PosTills from "../components/PosTills";
import Subscription from "../components/Subscription/Subscription";
import Shops from "../components/Shops/Shops";
import Rapport from "../components/Rapport/Rapport";
import CreateCustomer from "../components/Customers/CreateCustomer";
import CreateInvoice from "../components/Invoices/CreateInvoice";
import CreateCharge from "../components/Charges/CreateCharge";
import CreateSale from "../components/Sales/CreateSale";
import Sale from "../components/Sales/Sale";
import SaleCustomer from "../components/Sales/SaleCustomer";
import SaleInvoice from "../components/Sales/SaleInvoice";
import Payments from "../components/Payments/Payments";
import Settings from "../components/Settings/Settings";
import Ovh from "../components/Externals/Ovh";
import Stripe from "../components/Externals/Stripe";
import Externals from "../components/Externals/Externals";
import Mollie from "../components/Externals/Mollie";
import CreateCustomerInvoice from "../components/Invoices/CreateCustomerInvoice.vue";
import CreateCustomerCharge from "../components/Charges/CreateCustomerCharge.vue";
import SettingsShops from "../components/Settings/SettingsShops.vue";
import Tickets from "../components/Tickets/Tickets.vue";
import Ticket from "../components/Tickets/Ticket.vue";

var router_mode = 'hash';

Vue.use(Router);

const router = new Router({
  mode: router_mode,
  routes: [
    {
      path: '/:factoryRef',
      component: Home,
      name: 'root',
      meta: {
        title: 'Home Page',
      }
    },
    {
      path: '/:factoryRef/ticket/:ticketId',
      component: Ticket,
      name: 'ticket',
    },
    {
      path: '/:factoryRef/tickets',
      component: Tickets,
      name: 'tickets',
    },
    {
      path: '/:factoryRef/documents',
      component: Invoices,
      name: 'documents'
    },
    {
      path: '/:factoryRef/invoices',
      component: Invoices,
      name: 'invoices',
      meta: {
        title: 'Invoices',
      }
    },
    {
      path: '/:factoryRef/document/:invoiceId',
      component: Invoice,
      name: 'document'
    },
    {
      path: '/:factoryRef/invoice/:invoiceId',
      component: Invoice,
      name: 'invoice'
    },
    {
      path: '/:factoryRef/graphs',
      component: Charge,
      name: 'graphs'
    },
    {
      path: '/:factoryRef/events',
      component: Events,
      name: 'events'
    },
    {
      path: '/:factoryRef/event',
      component: CreateEvent,
      name: 'event-new'
    },
    {
      path: '/:factoryRef/event/:eventId',
      component: UpdateEvent,
      name: 'event'
    },
    {
      path: '/:factoryRef/customers',
      component: Customers,
      name: 'customers'
    },
    {
      path: '/:factoryRef/customers/:searchCustomer',
      component: Customers,
      name: 'customerSearch'
    },
    {
      path: '/:factoryRef/customers',
      component: Customers,
      name: 'search'
    },
    {
      path: '/:factoryRef/customer/:customerId',
      component: Customer,
      name: 'customer'
    },
    {
      path: '/:factoryRef/customer/:customerId/sales',
      component: Customer,
      name: 'customer-sales'
    },
    {
      path: '/:factoryRef/customer/:customerId/charges',
      component: Customer,
      name: 'customer-charges'
    },
    {
      path: '/:factoryRef/customer/:customerId/company',
      component: Customer,
      name: 'customer-company'
    },
    {
      path: '/:factoryRef/customer/:customerId/contacts',
      component: Customer,
      name: 'customer-contacts'
    },
    {
      path: '/:factoryRef/customer/:customerId/addresses',
      component: Customer,
      name: 'customer-addresses'
    },
    {
      path: '/:factoryRef/customer/:customerId/invoices',
      component: Customer,
      name: 'customer-invoices'
    },
    {
      path: '/:factoryRef/customer/:customerId/loyalties',
      component: Customer,
      name: 'customer-loyalties'
    },
    {
      path: '/:factoryRef/customer/:customerId/events',
      component: Customer,
      name: 'customer-events'
    },
    {
      path: '/:factoryRef/customer/:customerId/documents',
      component: Customer,
      name: 'customer-documents'
    },
    {
      path: '/:factoryRef/articles',
      component: Articles,
      name: 'stocks'
    },
    {
      path: '/:factoryRef/article',
      component: Article,
      name: 'article-new'
    },
    {
      path: '/:factoryRef/article/:articleId',
      component: Article,
      name: 'article'
    },
    {
      path: '/:factoryRef/fund',
      component: Fund,
      name: 'fund'
    },
    {
      path: '/:factoryRef/charge/:chargeId',
      component: Charge,
      name: 'charge'
    },
    {
      path: '/:factoryRef/charges',
      component: Charges,
      name: 'charges'
    },
    {
      path: '/:factoryRef/supplier/:supplierId/charges',
      component: Charges,
      name: 'charges-supplier'
    },
    {
      path: '/:factoryRef/supports',
      component: Support,
      name: 'supports'
    },
    {
      path: '/:factoryRef/settings',
      component: Settings,
      name: 'settings'
    },
    {
      path: '/:factoryRef/settings/invoice',
      component: SettingsInvoice,
      name: 'settings-invoice'
    },
    {
      path: '/:factoryRef/settings/paymode',
      component: SettingsPaymode,
      name: 'settings-paymode'
    },
    {
      path: '/:factoryRef/settings/tills',
      component: SettingsTill,
      name: 'settings-till'
    },
    {
      path: '/:factoryRef/settings/shops',
      component: SettingsShops,
      name: 'settings-shops'
    },
    {
      path: '/:factoryRef/settings/modules',
      component: SettingsModules,
      name: 'settings-modules'
    },
    {
      path: '/:factoryRef/settings/taxes',
      component: SettingsTaxes,
      name: 'settings-taxes'
    },
    {
      path: '/:factoryRef/settings/customers',
      component: SettingsCustomer,
      name: 'settings-customer'
    },
    {
      path: '/:factoryRef/users',
      component: Users,
      name: 'users'
    },
    {
      path: '/:factoryRef/user/:userId',
      component: User,
      name: 'user'
    },
    {
      path: '/:factoryRef/pos',
      component: PosTills,
      name: 'pos-tills'
    },
    {
      path: '/:factoryRef/pos/till/:tillId',
      component: Pos,
      name: 'pos'
    },
    {
      path: '/:factoryRef/subscription',
      component: Subscription,
      name: 'subscription'
    },
    {
      path: '/:factoryRef/shops',
      component: Shops,
      name: 'shops'
    },
    {
      path: '/:factoryRef/rapport',
      component: Rapport,
      name: 'rapport'
    },
    {
      path: '/:factoryRef/create/customer',
      component: CreateCustomer,
      name: 'create-customer'
    },
    {
      path: '/:factoryRef/customer/:customerId/invoice',
      component: CreateCustomerInvoice,
      name: 'create-customer-invoice'
    },
    {
      path: '/:factoryRef/create/document',
      component: CreateInvoice,
      name: 'create-invoice'
    },
    {
      path: '/:factoryRef/create/charge',
      component: CreateCustomerCharge,
      name: 'create-charge'
    },
    {
      path: '/:factoryRef/customer/:customerId/charge',
      component: CreateCharge,
      name: 'create-customer-charge'
    },
    {
      path: '/:factoryRef/create/sale',
      component: CreateSale,
      name: 'create-sale'
    },
    {
      path: '/:factoryRef/sale/:saleId',
      component: Sale,
      name: 'sale'
    },
    {
      path: '/:factoryRef/customer/:customerId/sale/:saleId',
      component: SaleCustomer,
      name: 'sale-customer'
    },
    {
      path: '/:factoryRef/customer/:customerId/sale',
      component: SaleCustomer,
      name: 'sale-customer-new'
    },
    {
      path: '/:factoryRef/invoice/:invoiceId/sale/:saleId',
      component: SaleInvoice,
      name: 'sale-invoice'
    },
    {
      path: '/:factoryRef/invoice/:invoiceId/sale',
      component: SaleInvoice,
      name: 'sale-invoice-new'
    },
    {
      path: '/:factoryRef/:target/:targetId/payments',
      component: Payments,
      name: 'payments-target'
    },
    {
      path: '/:factoryRef/externals',
      component: Externals,
      name: 'externals'
    },
    {
      path: '/:factoryRef/externals/ovh',
      component: Ovh,
      name: 'externals-ovh'
    },
    {
      path: '/:factoryRef/externals/stripe',
      component: Stripe,
      name: 'externals-stripe'
    },
    {
      path: '/:factoryRef/externals/mollie',
      component: Mollie,
      name: 'externals-mangopay'
    }
  ]
});


// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});


router.beforeEach(async function (to, from, next) {
  if (store.state.useAuthentication === undefined || store.state.isAuthenticated === undefined) {
    const response = await fetchApi.get('check');
    if (response.status === 504) {
      return next()
    }
    const data = await response.json()
    store.commit('setIsAuthenticated', data.auth)
    store.commit('setIsTrusted', data.trusted)
    store.commit('setUserConnected', data.user)
    store.commit('setMenus', data.menus)
    store.commit('setFactoryConnected', data.factory)
  }
  if (!store.state.useAuthentication) {
    if (to.name === 'Login') { return next({name: 'Home'}) }
    return next()
  }
  const loginRequiredRoute = to.matched.some(route => route.meta.requiresAuth)
  // if user is not authenticated and route requires login -> redirect to login page
  if (!store.state.isAuthenticated && loginRequiredRoute) {
    return next({name: 'Login'})
  }
  // if user is authenticated and navigates to login page -> redirect to home page
  if (to.name === 'Login' && store.state.isAuthenticated) {
    return next({name: 'Home'})
  }
  return next()
})

export default router
