import Vue from 'vue'
import Vuex from 'vuex'
import * as fetchApi from '../fetchApi'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    error: '',
    notifications: [],
    selectedProcesses: [],
    search: '',
    menus: [],
    userConnected : {
      id: null,
      name: null,
    },
    factoryConnected : {
      id: null,
      name: null,
    },
    log: {
      process: null,
      visible: false,
      stream: 'out'
    },
    processDetails: {
      visible: false
    },
    isTrusted: undefined,
    isAuthenticated: undefined,
    useAuthentication: undefined
  },
  mutations: {
    setIsTrusted (state, isTrusted) {
      state.isTrusted = isTrusted
    },
    setIsAuthenticated (state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated
    },
    setUseAuthentication (state, useAuthentication) {
      state.useAuthentication = useAuthentication
    },
    setUserConnected (state, userConnected) {
      state.userConnected = userConnected
    },
    setFactoryConnected (state, factoryConnected) {
      state.factoryConnected = factoryConnected
    },
    setMenus (state, menus) {
      state.menus = menus
    },
    logout (state) {
      state.isAuthenticated = false
    },
    setError (state, error) {
      state.error = error
    },
  },
  actions: {
    async init ({ commit }) {
    },
    logout () {
      this.commit('logout');
      fetchApi.post('/logout');
    }
  },
  getters: {
    name (state) {
      return 'getters.name'
    }
  }
})
