<template>
  <div>
    <template>
      <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
    </template>
    <div class="row">

      <div class="col-md-12">
        <b-overlay :show="showOverlayTickets" rounded="sm">
          <div class="card">
            <table class="table table-condensed table-hover table-bordered">
              <thead class="align-center">
              <tr style="font-weight: bold;text-align: center;">
                <th>#</th>
                <th>{{ $t('priority') }}</th>
                <th>{{ $t('status') }}</th>
                <th>{{ $t('summary') }}</th>
                <th>{{ $t('date') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="tickets.length===0">
                <td colspan="15">{{ $t('any_ticket') }}...</td>
              </tr>
              <tr v-for="ticket in tickets">
                <td>
                  <router-link :to="{name: 'ticket', params: {ticketId: ticket.id}}" class="btn btn-xs btn-info"><i class="fa fa-edit"></i> {{ $t('open') }}</router-link>
                  <button type="button" class="btn btn-xs btn-danger" @click="deleteTicket(ticket)"><i class="fa fa-trash"></i></button>
                </td>
                <td style="text-align:center">{{ticket.priority}}</td>
                <td style="text-align:center">{{ticket.status}}</td>
                <td style="text-align:center">{{ticket.summary_short}}</td>
                <td style="text-align:center">{{ticket.dates.create}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </b-overlay>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          @change="loadTickets"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import {BOverlay, BPagination, BBreadcrumb} from 'bootstrap-vue'
  import MoneyFormat from 'vue-money-format'
  import store from '../../store'
  export default {
    components: {
        'b-overlay':BOverlay,
        'b-breadcrumb':BBreadcrumb,
        'b-pagination':BPagination,
        MoneyFormat
    },
    data () {
      return {
        tickets: [],
        currentPage: 1,
        nbPages: 1,
        perPage: 1,
        rows: 0,
        showOverlayTickets: false,
      }
    },
    computed: {
        breadcrumbItems: function () {
            return [
                {
                    text: 'Home',
                    to: {name: 'root'}
                },
                {
                    text: 'Tickets',
                    active: true
                },
            ];
        },
    },
    mounted () {
      this.loadTickets();
    },
    methods: {
      loadTickets : function() {
        this.showOverlayTickets = true;
        this.$http.get('factory/'+store.state.factoryConnected.id+'/tickets?page='+this.currentPage).then((response) => {
            this.showOverlayTickets = false;
            this.tickets = response.data.items;
            this.rows = response.data.pagination.result;
            this.perPage = response.data.pagination.by_page;
        }).catch((response) => {
            this.showOverlayTickets = false;
        });
      },
      deleteTicket : function(ticket) {
        this.showOverlayTickets = true;
        this.$http.delete('ticket/'+ticket.id).then((response) => {
          this.showOverlayTickets = false;
          this.loadTickets()
        }).catch((response) => {
          this.showOverlayTickets = false;
        });
      }
    }
  }
</script>
