<template>
    <div>
      <b-overlay :show="showOverlayAccount" rounded="sm">
        <div class="card">
          <div class="card-header">
            <h4 class="modal-title"><i class="fa fa-plus"></i> O.V.H Account</h4>
          </div>
          <div class="card-body">
            <div class="form-group" v-for="item in formItems">
              <label>{{item.title}}: </label>
              <input type="text" id="event-hour-start" class="form-control" v-bind:placeholder="item.title" v-model="item.value"/>
            </div>
          </div>
          <div class="card-footer">
            <button v-if="this.exists === true" type="button" class="btn btn-primary" v-on:click="save">
              <i class="fa fa-save"></i> {{ $t('save') }}
            </button>
            <button v-else type="button" class="btn btn-primary" v-on:click="save">
              <i class="fa fa-plus"></i> {{ $t('add') }}
            </button>
            <button v-if="this.exists === true" type="button" :class="btnPingClass" v-on:click="launchPing">
              <i class="fa fa-refresh"></i> {{ $t('ping') }}
            </button>
          </div>
        </div>
      </b-overlay>
      <b-overlay v-if="this.ping === true" :show="showOverlayCustomer" rounded="sm">
        <div class="card">
          <div class="card-header">
            <h4 class="modal-title"><i class="fa fa-user"></i> Customer</h4>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label>{{ $t('name') }}: </label>
              <input type="text" class="form-control" v-model="customer.name" disabled/>
            </div>
          </div>
        </div>
      </b-overlay>
      <b-overlay v-if="this.ping === true" :show="showOverlayInvoices" rounded="sm">
        <form v-on:submit.prevent="launchImportInvoices">
          <div class="card">
            <div class="card-header">
              <h4 class="modal-title"><i class="fa fa-plus"></i> Import Invoices</h4>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label>{{ $t('start') }}: </label>
                <input type="date" class="form-control" v-model="importInvoices.start" required/>
              </div>
              <div class="form-group">
                <label>{{ $t('end') }}: </label>
                <input type="date" class="form-control" v-model="importInvoices.end" required/>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" class="btn btn-primary">
                <i class="fa fa-save"></i> {{ $t('launch') }}
              </button>
            </div>
          </div>
        </form>
      </b-overlay>
    </div>
</template>

<script>
    import {BOverlay} from 'bootstrap-vue'
    import store from '../../store';
    export default {
        name: "fund",
        components: {
            'b-overlay':BOverlay
        },
        data () {
          return {
            exists : null,
            ping : null,
            formItems: [],
            showOverlayAccount: false,
            showOverlayInvoices: false,
            showOverlayCustomer: false,
            importInvoices: {
                start: null,
                end: null
            },
            customer: {
                id: null,
                name: null,
            }
          }
        },
        mounted () {
          this.loadForm();
        },
        computed : {
            btnPingClass: function () {
                if (this.ping === true) {
                    return 'btn btn-success';
                } else if (this.ping === false) {
                    return 'btn btn-warning';
                } else {
                    return 'btn btn-default';
                }
            }
        },
        methods: {
          loadForm : function () {
            this.showOverlayAccount = true;
            this.$http.get('webservices/ovh/account/factory/'+store.state.factoryConnected.id+'/form').then((response) => {
                this.showOverlayAccount = false;
                this.exists = response.data.exists;
                this.formItems = response.data.form;
            });
          },
          launchPing: function () {
              if (null !== this.exists) {
                  if (this.exists === true) {
                      this.showOverlayAccount = true;
                      this.$http.get('webservices/ovh/account/factory/'+store.state.factoryConnected.id+'/ping').then((response) => {
                          this.showOverlayAccount = false;
                          this.ping = response.data.success;
                          if (this.ping === true) {
                              this.loadCustomer();
                          }
                      });
                  }
              }
          },
          launchImportInvoices: function () {
              if (null !== this.exists) {
                  if (this.exists === true) {
                      this.showOverlayInvoices = true;
                      this.$http.post('webservices/ovh/account/factory/'+store.state.factoryConnected.id+'/invoices/sync', this.importInvoices).then((response) => {
                          this.showOverlayInvoices = false;
                          this.ping = response.data.success;
                      });
                  }
              }
          },
          loadCustomer: function () {
              if (null !== this.exists) {
                  if (this.exists === true) {
                      this.showOverlayCustomer = true;
                      this.$http.get('webservices/ovh/account/factory/'+store.state.factoryConnected.id+'/customer').then((response) => {
                          this.showOverlayCustomer = false;
                          this.customer = response.data;
                      });
                  }
              }
          },
          save: function () {
            if (null !== this.exists) {
                var object = new Object();
                for (let d in this.formItems) {
                    object[this.formItems[d].field] = this.formItems[d].value;
                }
                if (this.exists === true) {
                    this.showOverlayAccount = true;
                    this.$http.put('webservices/ovh/account/factory/'+store.state.factoryConnected.id+'/form', object).then((response) => {
                        this.showOverlayAccount = false;
                    });
                } else if (this.exists === false) {
                    this.showOverlayAccount = true;
                    this.$http.post('webservices/ovh/account/factory/'+store.state.factoryConnected.id+'/form', object).then((response) => {
                        this.showOverlayAccount = false;
                    });
                }
            }
          }
        }
    }
</script>
