<template>
  <div class="card">
    <table class="table table-condensed table-hover table-bordered">
      <thead class="align-center">
        <tr style="font-weight: bold;text-align: center;">
          <th>#</th>
          <th>{{$t('document')}}</th>
          <th>{{$t('title')}}</th>
          <th>{{$t('number')}}</th>
          <th>{{$t('date')}}</th>
          <th>{{$t('amount')}}</th>
          <th>{{$t('payed')}}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="this.documents.length===0"><td colspan="10">{{$t('no_document')}}</td></tr>
        <tr v-for="document in documents">
          <td>
            <button type="button" class="btn btn-xs btn-info disabled"><i class="fa fa-edit"></i></button>
          </td>
          <td style="text-align:center"><b>{{$t(document.type)}}</b></td>
          <td style="text-align:center"><b>{{document.name}}</b></td>
          <td style="text-align:center"><b>{{document.number}}</b></td>
          <td style="text-align:center">{{document.date}}</td>
          <td style="text-align:right">{{document.amount.amount_incl}}</td>
          <td style="text-align:center">{{document.amount.payed_pc}}</td>
          <td style="text-align:right">
            <a v-bind:href="document.url.print" target="_alt" class="btn btn-xs btn-default">
              <i class="fa fa-print"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
    export default {
      name: "customer-table-document",
      data () {
        return {
          documents: [],
            showOverlayDocuments: false
        }
      },
      computed: {
        currentIdCustomer : function() {
          return this.$route.params.customerId;
        },
      },
      mounted () {
        this.loadDocuments();
      },
      methods: {
          loadDocuments : function () {
          this.showOverlayDocuments = true;
          this.$http.get('customer/'+this.currentIdCustomer+'/documents').then((salesResponse) => {
            this.documents = salesResponse.data;
            this.showOverlayDocuments = false;
          });
        },
      }
    }
</script>

<style scoped>

</style>
