<template>
  <div>
    <div class="limiter">
      <div class="container-login100">
        <div class="wrap-login100">
          <form class="login100-form validate-form">
            <span class="login100-form-sub-title p-b-12">
              {{ userName }}
            </span>

            <span class="login100-form-title p-b-43">
              {{ $t('select_factory') }}
            </span>

              <b-list-group>
                <b-list-group-item v-for="factory in factories" v-bind:key="factory.id" href="#" v-on:click="changeFactory(factory.id, factory.reference)">
                  {{ factory.name }}
                </b-list-group-item>
              </b-list-group>

              <div class="text-center p-t-46 p-b-20">
                <span class="txt2">
                 {{ $t('or') }} <a href="javascript:void(0);" v-on:click="createFactory" class="txt1">{{ $t('create_new_business') }}</a>
                </span>
              </div>

              <b-list-group>
                <b-list-group-item variant="danger" href="#" v-on:click="logout"> <i class="fa fa-power-off"></i> {{ $t('logout') }}</b-list-group-item>
              </b-list-group>
          </form>
          <div class="login100-more"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import store from '../store';
    import {BListGroup, BListGroupItem} from 'bootstrap-vue'
    export default {
        name: "select-factory",
        components: {
            'b-list-group':BListGroup,
            'b-list-group-item':BListGroupItem
        },
        data () {
          return {
              email: null,
              factories: []
          }
        },
        computed: {
          app : function () {
            return this.$parent.app ? this.$parent.app : null;
          },
          userName: function () {
              if (!store.state.isAuthenticated) {
                  return null;
              }
              return store.state.userConnected.name;
          },
          isLogged: function () {
              return store.state.isAuthenticated;
          },
        },
        mounted () {
            this.loadFactories();
        },
        methods: {
            loadFactories: function () {
                this.$http.get('user/'+store.state.userConnected.id+'/factories').then((response) => {
                    this.factories = response.data;
                });
            },
            changeFactory: function (factoryId, factoryRef) {
                var router = this.$router;
                this.$http.put('user/'+store.state.userConnected.id+'/factory/'+factoryId+'/switch').then((response) => {
                    try {
                      if (this.$router) {
                        this.$router.push({name:'root', params: {factoryRef: factoryRef}});
                      } else if (router) {
                        router.push({name:'root', params: {factoryRef: factoryRef}});
                      } else {
                        window.location.replace = '/'+factoryRef;
                      }
                    } catch (e) {
                        window.location.replace = '/'+factoryRef;
                    }
                });
            },
            createFactory: function () {
              this.$http.post('factory', {'name': 'New company'}).then((response) => {
                this.loadFactories();
              }).catch((response)=>{
                alert('Error during company creation');
              });
            },
            logout : function () {
                this.$http.get('logout').then((response) => {
                    if (response.ok === true) {
                        localStorage.removeItem('x-user-token');
                    }
                    this.$parent.logged = false;
                    window.location.reload();
                }).catch((response)=>{

                })
            },
        }
    }
</script>

<style src="../assets/css/select-factory.css" scoped></style>
