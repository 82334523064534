<template>
  <div>
    <b-overlay :show="showOverlayCustomerCompany" rounded="sm">
      <form method="post" v-on:submit.prevent="save">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <b-form-checkbox switch :checked="customer.is_supplier" v-model="customer.is_supplier" :value="true">{{$t('supplier')}}</b-form-checkbox>
            </div>
            <div class="form-group">
              <label>{{$t('vat_number')}}:</label>
              <input type="text" class="form-control" v-model="customer.vat_number" v-bind:placeholder="$t('vat_number')">
            </div>
            <div class="form-group">
              <label>{{$t('company')}}:</label>
              <input type="text" class="form-control" v-model="customer.company" v-bind:placeholder="$t('company')">
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-success">
              <i class="fa fa-save"></i>
              {{$t('save')}}
            </button>
          </div>
        </div>
      </form>
    </b-overlay>
  </div>
</template>

<script>
    import {BOverlay, BFormCheckbox} from 'bootstrap-vue'
    import store from '../../../store'

    export default {
        name: "customer-table-company",
        components: {
            BOverlay,
            BFormCheckbox
        },
        data() {
            return {
                showOverlayCustomerCompany: false,
            }
        },
        props: {
            customer: {
                type: Object,
                required: false,
            },
        },
        mounted() {
        },
        methods: {
            save: function () {
                var data = {
                    is_supplier: this.customer.is_supplier,
                    vat_number: this.customer.vat_number,
                    company: this.customer.company,
                };
                this.showOverlayCustomerCompany = true;
                this.$http.put('customer/'+this.customer.id, data).then((response) => {
                    this.showOverlayCustomerCompany = false;
                    if (response.ok === true) {
                        this.$parent.customer = response.data;
                    }
                });
            },
            loadStatus : function() {
                this.$http.get('factory/'+store.state.factoryConnected.id+'/status?target=customer').then((response) => {
                    if (response.ok === true) {
                        this.status = response.data;
                    }
                });
            },
        }
    }
</script>

<style scoped>

</style>
