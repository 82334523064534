<template>
  <div>
    <b-overlay :show="showOverlayCustomerInformation" rounded="sm">
      <form method="post" v-on:submit.prevent="save">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label>{{$t('external_id')}}:</label>
              <input type="text" class="form-control" v-model="customer.external_id" style="border-color:orange;box-shadow: 0 0 5px rgba(207, 220, 0, 0.4);" v-bind:placeholder="$t('external_id')">
            </div>
            <div class="form-group">
              <label>{{$t('status')}}:</label>
              <select class="form-control selectpicker" data-live-search="true" v-model="customer.status"
                      :readonly="listStatus.length===0">
                <option value="">Choisir un status</option>
                <option v-bind:value="status" v-for="status in listStatus">{{status.name}}</option>
              </select>
            </div>
            <div class="form-group">
              <label>{{$t('gender')}}:</label>
              <select class="form-control selectpicker" data-live-search="true" v-model="customer.gender.id">
                <option value="">Choisir un genre</option>
                <option value="1">Homme</option>
                <option value="2">Femme</option>
                <option value="3">Entreprise</option>
              </select>
            </div>
            <div class="form-group">
              <label>{{$t('last_name')}}:</label>
              <input type="text" class="form-control" v-model="customer.last_name" v-bind:placeholder="$t('last_name')">
            </div>
            <div class="form-group">
              <label>{{$t('first_name')}}:</label>
              <input type="text" class="form-control" v-model="customer.first_name" v-bind:placeholder="$t('first_name')">
            </div>
            <div class="form-group">
              <label>{{$t('birthday_date')}}:</label>
              <input type="date" class="form-control datepicker" v-model="customer.dob"
                     v-bind:placeholder="$t('birthday_date')">
            </div>
            <div class="form-group">
              <label>{{$t('comment')}}:</label>
              <textarea class="form-control" v-model="customer.note" v-bind:placeholder="$t('comment')">Note</textarea>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-success" :disabled="showOverlayCustomerInformation===true">
              <i class="fa fa-save"></i>
              {{$t('save')}}
            </button>
            <button class="btn btn-info" v-on:click="setCustomerCounter" v-if="customer.is_counter">
              <i class="fa fa-check"></i> {{$t('customer_counter')}}
            </button>
            <button class="btn btn-default" v-on:click="setCustomerCounter" v-else>
              <i class="fa fa-plus"></i> {{$t('customer_counter')}}
            </button>
            <button class="btn btn-danger disabled" v-bind:title="$t('delete')"><i class="fa fa-trash"></i> {{$t('delete')}}</button>
          </div>
        </div>
      </form>
    </b-overlay>
  </div>
</template>

<script>
    import {BOverlay} from 'bootstrap-vue'
    import store from '../../../store'
    import DataLanguage from "../../Data/DataLanguage";

    export default {
        name: "customer-table-information",
        components: {
            'b-overlay': BOverlay
        },
        data() {
            return {
                listStatus: [],
                languages: DataLanguage.data().languages,
                showOverlayCustomerInformation: false,
            }
        },
        props: {
            customer: {
                type: Object,
                required: false,
            }
        },
        mounted() {
            this.loadStatus();
        },
        methods: {
            loadStatus : function() {
                this.$http.get('factory/'+store.state.factoryConnected.id+'/status?target=customer').then((response) => {
                    if (response.ok === true) {
                        this.listStatus = response.data;
                    }
                });
            },
            save: function () {
                var data = {
                    external_id: this.customer.external_id,
                    status: this.customer.status ? this.customer.status.id : null,
                    gender: this.customer.gender ? this.customer.gender.id : null,
                    last_name: this.customer.last_name,
                    first_name: this.customer.first_name,
                    dob: this.customer.dob,
                    note: this.customer.note,
                };
                this.showOverlayCustomerInformation = true;
                this.$http.put('customer/'+this.customer.id, data).then((response) => {
                    this.showOverlayCustomerInformation = false;
                    if (response.ok === true) {
                        this.$parent.customer = response.data;
                    }
                }).catch((response) => {
                    this.showOverlayCustomerInformation = false;
                });
            },
            setCustomerCounter : function() {
                if (this.customer.is_counter === true) {
                    this.$http.delete('customer/'+this.customer.id+'/counter').then((response) => {
                        if (response.ok === true) {
                            this.$parent.customer.is_counter = false;
                        }
                    });
                } else {
                    this.$http.put('customer/'+this.customer.id+'/counter').then((response) => {
                        if (response.ok === true) {
                            this.$parent.customer.is_counter = true;
                        }
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
