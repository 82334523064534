<template>
  <form ref="form" class="form" method="post">
    <b-modal
      ref="modal"
      id="modal-charge-file-edit"
      :title="$t('file')"
      @ok="submit">
    <div class="form-group">
      <label>Description</label>
      <textarea class="form-control" v-model="description"></textarea>
    </div>
    <div class="form-group">
      <label>File</label>
      <b-form-file
        v-model="file"
        :state="Boolean(file)"
        :multiple="false"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      ></b-form-file>
      <div class="mt-3">Selected file: {{ file ? file.name : '' }}</div>
    </div>
    </b-modal><!-- /.modal -->
  </form>
</template>
<script>
    import {BModal, BFormFile} from 'bootstrap-vue'
    import store from '../../../store'
    export default {
        name: 'charge-modal-file-edit',
        components: {
            BFormFile,
            BModal,
        },
        data () {
            return {
                main: null,
                file: null,
                description : null,
            }
        },
        mounted() {
        },
        computed: {
        },
        methods: {
          resetFile: function () {
              this.description = null;
              this.file = null;
          },
          showModal: function (main, charge, file) {
              this.main = main;
              this.charge = charge;
              if (null === file) {
                  this.resetFile();
              } else {
                  this.file = file;
              }
              this.$refs['modal'].show();
          },
          submit : function (bvModalEvt) {
              var formData = new FormData();

              formData.append('image', this.file);
              bvModalEvt.preventDefault();
            if (this.file && this.file.id) {
                this.$http.put('charge/' + this.amount.charge.id + '/amount/' + this.amount.id, amount).then((response) => {
                    if (response.ok === true) {
                        this.amount = response.data;
                        if (typeof this.main.loadAmounts === 'function') {
                            this.main.loadAmounts(this.amount.charge.id);
                        }
                        this.$refs['modal'].hide();
                    }
                });
            } else {
                this.$http.post('charge/' + this.charge.id + '/file', formData).then((response) => {
                    if (response.ok === true) {
                        this.amount = response.data;
                        if (typeof this.main.loadFiles === 'function') {
                            this.main.loadFiles(this.charge.id);
                        }
                        this.$refs['modal'].hide();
                    }
                });
            }
          },
        }
    }
</script>
