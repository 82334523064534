<template>
  <div>
    <template>
      <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
    </template>
    <div class="row">

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-2">
                <div class="dropdown">
                  <button type="button" class="btn btn-default" v-on:click="switchYear(year)">
                    <i class="fa fa-refresh"></i>
                    <span class="currentYear">{{year}}</span>
                  </button>
                  <button
                    type="button"
                    class="btn btn-default dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <a href="javascript:void(0);" v-for="year in years" v-on:click="switchYear(year.date)" class="dropdown-item chgYear">
                      {{year.date}} <span class="badge badge-default badge-pill">{{year.nb}}</span>
                    </a>
                  </ul>
                </div>
              </div>

              <div class="col-lg-7">
                <form class="form-horizontal" id="SearchInvoice" role="form">
                  <div class="input-group" id="adv-search">
                    <input type="text" class="form-control" name="q" value="" v-bind:placeholder="$t('keywords')" disabled/>
                    <div class="input-group-btn">
                      <button type="button" class="btn btn-primary">
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div class="col-lg-3" style="text-align:right">
                <router-link class="btn btn-info" :to="{name: 'create-charge'}">
                  <i class="fa fa-plus"></i>
                  {{$t('create_charge')}}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <b-overlay :show="showOverlayCharges" rounded="sm">
          <div class="card">
            <table class="table table-condensed table-hover table-bordered">
              <thead class="align-center">
              <tr style="font-weight: bold;text-align: center;">
                <th>#</th>
                <th>{{ $t('supplier') }}</th>
                <th>{{ $t('number:table') }}</th>
                <th>{{ $t('date') }}</th>
                <th>{{ $t('intra') }}</th>
                <th>{{ $t('amount') }}</th>
                <th>{{ $t('payed') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="charges.length===0">
                <td colspan="15">{{ $t('any_charge') }}...</td>
              </tr>
              <tr v-for="charge in charges">
                <td>
                  <router-link :to="{name: 'charge', params: {chargeId: charge.id}}" class="btn btn-xs btn-info"><i class="fa fa-edit"></i> {{ $t('open') }}</router-link>
                </td>
                <td>
                  <router-link :to="{name: 'customer', params: {customerId: charge.supplier.id}}">
                    {{charge.supplier.name}}
                  </router-link>
                </td>
                <td style="text-align:center">{{charge.number}}</td>
                <td style="text-align:center">{{charge.date}}</td>
                <td style="text-align:center"><i class="fa fa-check" v-if="charge.intra"></i></td>
                <td style="text-align:right">
                  <money-format v-if="charge.amount.amount_incl !== null && charge.amount.amount_incl !== '' && charge.amount.amount_incl !== 0" :value="charge.amount.amount_incl"
                                locale='en'
                                :currency-code="charge.currency.name"
                                :subunit-value=true
                                :hide-subunits=false>
                  </money-format>
                </td>
                <td style="text-align:center">{{charge.amount.payed_pc===0?null:charge.amount.payed_pc+' %'}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </b-overlay>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          @change="loadChargePage"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import {BOverlay, BPagination, BBreadcrumb} from 'bootstrap-vue'
  import MoneyFormat from 'vue-money-format'
  import store from '../../store'
  export default {
    components: {
        'b-overlay':BOverlay,
        'b-breadcrumb':BBreadcrumb,
        'b-pagination':BPagination,
        MoneyFormat
    },
    data () {
      return {
        charges: [],
        currentPage: 1,
        nbPages: 1,
        perPage: 1,
        rows: 0,
        years: [],
        year: null,
        showOverlayCharges: false,
      }
    },
    computed: {
        breadcrumbItems: function () {
            return [
                {
                    text: 'Home',
                    to: {name: 'root'}
                },
                {
                    text: 'Charges',
                    active: true
                },
            ];
        },
    },
    mounted () {
      this.loadYears();
    },
    methods: {
      loadYears : function() {
        if (this.$route.params.supplierId) {
            this.$http.get('factory/'+store.state.factoryConnected.id+'/charges/years?supplier='+this.$route.params.supplierId).then((response) => {
                this.years = response.data;
                if (this.years.length > 0) {
                    this.year = this.years[0].date;
                    this.loadCharges();
                }
            });
        } else {
            this.$http.get('factory/'+store.state.factoryConnected.id+'/charges/years').then((response) => {
                this.years = response.data;
                if (this.years.length > 0) {
                    this.year = this.years[0].date;
                    this.loadCharges();
                }
            });
        }
      },
      switchYear: function (year) {
          this.year = year;
          this.loadCharges();
      },
      loadChargePage: function () {
          this.showOverlayCharges = true;
          setTimeout(this.loadCharges.bind(this), 100);
      },
      loadCharges : function() {
        let urlCharges;
        if (this.$route.params.supplierId) {
          urlCharges = 'factory/'+store.state.factoryConnected.id+'/charges?page='+this.currentPage+'&supplier='+this.$route.params.supplierId;
        } else {
          urlCharges = 'factory/'+store.state.factoryConnected.id+'/charges?page='+this.currentPage+'&year='+this.year;
        }
        this.showOverlayCharges = true;
        this.$http.get(urlCharges).then((response) => {
            this.showOverlayCharges = false;
            this.charges = response.data.items;
            this.rows = response.data.pagination.result;
            this.perPage = response.data.pagination.by_page;
        }).catch((response) => {
            this.showOverlayCharges = false;
        });
      },
      loadChargesBySupplier : function(customerId) {
        return this.$router.push({name: 'charges-supplier', params: {supplierId: customerId}});
      },
    }
  }
</script>
