<template>
  <div>
    <form v-on:submit.prevent="submitPrice">
      <b-overlay :show="showOverlayPrice" rounded="sm">
        <div class="card">
          <div class="card-header">
            <h3 class="title" v-if="currentPrice.id">{{ $t('price')}} n°{{ currentPrice.id}}</h3>
            <h3 class="title" v-else>{{ $t('new_price')}}</h3>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label>{{ $t('amount')}}</label>
              <input type="text" class="form-control" v-model="currentPrice.amount"  v-bind:placeholder="$t('amount')">
            </div>
            <div class="form-group">
              <label>{{ $t('date_start')}}</label>
              <input type="date" class="form-control" v-model="currentPrice.start"  v-bind:placeholder="$t('date_start')">
            </div>
            <div class="form-group">
              <label>{{ $t('date_end')}}</label>
              <input type="date" class="form-control" v-model="currentPrice.end"  v-bind:placeholder="$t('date_end')">
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-primary" v-if="currentPrice.id"><i class="fa fa-save"></i> {{ $t('save')}}</button>
            <button type="submit" class="btn btn-primary" v-else><i class="fa fa-plus"></i> {{ $t('add')}}</button>
            <button type="button" class="btn btn-default" v-on:click="selectPrice({})"><i class="fa fa-eraser"></i></button>
          </div>
        </div>
      </b-overlay>
    </form>
    <b-overlay :show="showOverlayPrices" rounded="sm">
      <div class="card">
        <table class="table table-hover table-bordered">
          <thead>
            <tr>
              <td>#</td>
              <td>{{ $t('amount') }}</td>
              <td>{{ $t('start') }}</td>
              <td>{{ $t('end') }}</td>
              <td>{{ $t('creation') }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-if="prices.length === 0">
              <td colspan="10">{{ $t('no_stock') }}</td>
            </tr>
            <tr v-for="price in prices">
              <td>
                <button type="button" v-on:click="selectPrice(price)" class="btn btn-default btn-xs">
                  <i class="fa fa-edit"></i> {{$t('edit')}}
                </button>
                <button type="button" v-on:click="deletePrice(price)" class="btn btn-danger btn-xs">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
              <td>
                <money-format :value="price.amount"
                              locale='en'
                              :currency-code="price.currency.name"
                              :subunit-value=true
                              :hide-subunits=false>
                </money-format>
              </td>
              <td>{{ price.start }}</td>
              <td>{{ price.end }}</td>
              <td><timeago :datetime="price.dates.create">{{price.dates.create}}</timeago></td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-overlay>
  </div>
</template>

<script>
  import timeago from 'timeago.js';
  import {BOverlay} from 'bootstrap-vue'
  import MoneyFormat from 'vue-money-format'
  export default {
    name: 'article-price',
    components: {
        'b-overlay': BOverlay,
        'money-format': MoneyFormat,
    },
    data () {
      return {
        showOverlayPrice: false,
        showOverlayPrices: false,
        prices: [],
        interval: null,
        currentPrice: {
            id: null
        }
      }
    },
    props: {
        article: {
            type: String,
            required: true,
        },
    },
    mounted () {
      this.interval = setInterval(function() {
          if (this.article) {
              this.loadPrices();
              clearInterval(this.interval);
          }
      }.bind(this), 1000);
    },
    methods : {
      loadPrices : function () {
        this.$http.get('article/'+this.article+'/prices').then((response) => {
          if (response.ok === true) {
            this.prices = response.data;
          }
        });
      },
      deletePrice : function(price) {
        this.showOverlayPrices = true;
        this.$http.delete('article/'+this.article+'/price/'+price.id).then((response) => {
          this.showOverlayPrices = false;
          if (response.ok === true) {
              let index = this.prices.indexOf(price);
              this.prices.splice(index, 1);
          }
        }).catch((response)=>{
            this.showOverlayPrices = false;
        });
      },
      selectPrice: function (price) {
        this.currentPrice = price;
      },
      submitPrice: function() {
          if (this.currentPrice.id) {
              this.updatePrice();
          } else {
              this.addPrice();
          }
      },
      updatePrice : function () {
        this.showOverlayPrice = true;
        this.$http.put('article/'+this.article+'/price/'+this.currentPrice.id, this.currentPrice).then((response) => {
          if (response.ok === true) {
              this.currentPrice = response.data;
          }
          this.showOverlayPrice = false;
        }).catch((response)=>{
            this.showOverlayPrice = false;
        });
      },
      addPrice : function() {
          this.showOverlayPrice = false;
          this.$http.post('article/'+this.article+'/price', this.currentPrice).then((response) => {
              if (response.ok === true) {
                  this.prices.push(response.data);
              }
              this.showOverlayPrice = false;
          }).catch((response)=>{
              this.showOverlayPrice = false;
          });
      },
    }
  }
</script>
